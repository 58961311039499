import React, { useState, useEffect } from 'react';
import {
  Container, Row, Col, Dropdown, InputGroup, Carousel, Button, Card, Badge, Form
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './style.css';


const AdvertisingWhite = () => {
    return (
        <div className="white-background">
            <Row className="banner-adwords">
                <Col md={4} sm={2} className="is-anuncio is-banner-g">
                    <div class="bannerwrapWhite">
                        <div class="bannerwrap-inner">
                            
                        </div>
                        <span class="bannerwrap-pub">Publicidade</span>
                    </div>
                </Col>
                <Col md={4} sm={2} className="is-anuncio is-banner-g">
                    <div class="bannerwrapWhite">
                        <div class="bannerwrap-inner">
                            
                        </div>
                        <span class="bannerwrap-pub">Publicidade</span>
                    </div>
                </Col>
                <Col md={4} sm={2} className="is-anuncio is-banner-g">
                    <div class="bannerwrapWhite">
                        <div class="bannerwrap-inner">
                            
                        </div>
                        <span class="bannerwrap-pub">Publicidade</span>
                    </div>
                </Col>
            </Row>
        </div>
    )
}


export default AdvertisingWhite
import axios from 'axios';
import API from './config';

const Auction = {
  getAll: async () => {
    try {
      let response = await API().get(`/auctions`);
      return await response.data;
    } catch (e) {
      return { status: 'error', message: 'Something is wrong.' };
    }
  },
  getRandom: async () => {
    try {
      let response = await API().get(`/auctions/fetch/carroussel`);
      return await response.data;
    } catch (e) {
      return { status: 'error', message: 'Something is wrong.' };
    }
  },
  getBids: async (user) => {
    try {
      let response = await API().get(`/auctionBid/fetch/fetchAllFromUser/${user}`);
      return await response.data;
    } catch (e) {
      return { status: 'error', message: 'Something is wrong.' };
    }
  },
  getLast24hours: async () => {
    try {
      let response = await API().get(`/auctions/fetch/last24hours`);
      return await response.data;
    } catch (e) {
      return { status: 'error', message: 'Something is wrong.' };
    }
  },
  createBid: async (params) => {
    try {
      let response = await API().post(`/auctionBid`, params);
      return await response.data;
    } catch (e) {
      return { status: 'error', message: 'Something is wrong.' };
    }
  },
  create: async (params) => {
    try {
      let response = await API().post(`/auctions`, params);
      return await response.data;
    } catch (e) {
      return { status: 'error', message: 'Something is wrong.' };
    }
  },
  delete: async (id) => {
    try {
      let response = await API().delete(`/auctions/${id}`);
      return await response.data;
    } catch (e) {
      return { status: 'error', message: 'Something is wrong.' };
    }
  },
  getAllActive: async () => {
    try {
      let response = await API().get(`/auctions/fetch/getActives`);
      return await response.data;
    } catch (e) {
      return { status: 'error', message: 'Something is wrong.' };
    }
  },
  getFuture: async () => {
    try {
      let response = await API().get(`/auctions/fetch/nextAuctions`);
      return await response.data;
    } catch (e) {
      return { status: 'error', message: 'Something is wrong.' };
    }
  },
  getBidsFromAuction: async (id) => {
    try {
      let response = await API().get(`/auctionBid/fetch/getBidsFromProduct/${id}`);
      return await response.data;
    } catch (e) {
      return { status: 'error', message: 'Something is wrong.' };
    }
  },
  getAfterAuction: async () => {
    try {
      let response = await API().get(`/auctions/fetch/afterAuction`);
      return await response.data;
    } catch (e) {
      return { status: 'error', message: 'Something is wrong.' };
    }
  },
  getAuctionByDate: async (params) => {
    try {
      let response = await API().get(`/auctions/fetch/auctionByDate/${params}`);
      return await response.data;
    } catch (e) {
      return { status: 'error', message: 'Something is wrong.' };
    }
  },
  setVisit: async() => {
    try {
      let response = await axios.put(`https://bigu.digital/zeleiloes-plataforma-backend/public/api/auctions/visits/`);
      return await response.data;
    } catch (e) {
      return { status: 'error', message: 'Something is wrong.' };
    }
  },
  getAuctionByName: async (params) => {
    try {
      let response = await API().get(`auctions/fetch/fetchByActioneer/${params}`);
      return await response.data;
    } catch (e) {
      return { status: 'error', message: 'Something is wrong.' };
    }
  },
  getAuctionByState: async (params) => {
    try {
      let response = await API().get(`auctions/fetch/findByState/${params}`);
      return await response.data;
    } catch (e) {
      return { status: 'error', message: 'Something is wrong.' };
    }
  }
}

export default Auction;
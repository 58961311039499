import React, { useState, useEffect } from 'react';
import {
  Container, Row, Col,
} from 'react-bootstrap';

import { Header, Footer } from '../../../components';
import { Store, User } from '../../../api';
import themeContext from '../../../../src/utils/theme';
import LogoHeader from '../../../assets/logo1-horizontal-transparente.png';
import './SendoConfirmationEmail.css'
import { useAlert, positions } from 'react-alert';

const SendoConfirmationEmail = () => {
  const alert = useAlert();
  const [auctioneer, setAuctioneer] = useState({})

  const loadImage = () => {
    return auctioneer.img_url ?
      `https://bigu.digital/zeleiloes-plataforma-backend/public/storage/stores/${auctioneer.img_url}` :
      "https://bigu.digital/images/bigu-v2-white.png"
  }

  const requestNewEmailbyConfirmation = async (e) => {
    e.preventDefault()

    let response = await User.requestNewEmailConfirmation(document.getElementById('email').value)
    console.log(response)
    console.log(document.getElementById('email').value)
    if (response.status !== 'error') {
      alert.success('Sucesso! O Email de confirmação foi reenviado!', { position: positions.TOP_RIGHT })
    } else {
      alert.error('Erro! Verifique se o email informado está correto', { position: positions.TOP_RIGHT })
    }
  }

  return (
    <div>
      <Header />

      <Container style={{ marginTop: '30px' }}>
        <section style={{ width: '390px', margin: 'auto' }}>
          <h4 style={{ textAlign: 'center', fontWeight: 'bold' }}>Reenviar Email de Confirmação</h4>
          <div className="logcad-wrap">
            <form onSubmit={requestNewEmailbyConfirmation}>
              <Row>
                <Col xs={12} style={{ paddingTop: '10px', paddingBottom: '-10px' }}>
                  <div className="form-group has-feedback">
                    <label>E-mail:</label>
                    <input
                      required
                      id="email"
                      className="form-control"
                      placeholder="Digite seu email" />
                  </div>
                </Col>
              </Row>
              <div className="" style={{ display: 'flex', justifyContent: 'center', paddingTop: '5px' }}>
                <button
                  type="submit"
                  style={{ backgroundColor: themeContext.AZUL_DARK, border: 'none' }}
                  className="btn"
                >
                  <span style={{ color: themeContext.WHITE }}>
                    Enviar Nova Confirmação de Email
                  </span>
                </button>
              </div>
            </form>
          </div>
        </section>
      </Container>
    </div>
  )
}


export default SendoConfirmationEmail;
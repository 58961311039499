import React, { useState, useEffect } from 'react';
import { FaFilter } from 'react-icons/fa';
import { Product, Auction } from '../../api';
import { AuctioneerHeader, Header, HeaderWithSearch } from '../../components';
import './style.css';
// import categories from '../../utils/categories';

const Search = () => {
  const [contentType, setContentType] = useState(null);
  const [loadingData, setLoadingData] = useState(true);
  const [loadingPage, setLoadingPage] = useState(true);
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [type, setType] = useState("1");
  const [last, setLast] = useState(null);
  const [name, setName] = useState(null);
  const [category, setCategory] = useState(null);


  const loadAuction24hours = async () => {
    let response = await Auction.getLast24hours();
    if (response.status !== 'error') {
      setProducts(response)
      setLoadingData(false)
    }
  }

  const loadCategories = async () => {
    let response = await Product.getCategories()
    if (response.status !== 'error') {
      let aux = []
      response.forEach((item) => {
        aux.push({
          ...item,
          label: item.name
        })
      })
      console.log(response)
      setCategories(aux)
    }
  }

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.get('last24hours') === '24') {
      loadAuction24hours();
      loadCategories();
    } else {
      loadCategories();
      loadData();
    }
    if (window.location.hostname === 'teste.zeleiloes.com') {
      setContentType(1);
      setLoadingPage(false);
    } else {
      setContentType(1);
      // dispatch(setUserData(response.token));
      setLoadingPage(false);
    }
  }, [])

  const loadData = async (query) => {
    let response = await Product.search(query);
    console.log(response)
    if (response.status !== 'error') {
      setProducts(response)
      setLoadingData(false)
    }
  }

  const listenToQueryChange = () => {
    /*
     * Check the window URL and set the query search to be rendered
     */

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.get('last24hours') === '24') {
      loadAuction24hours();
      loadCategories();

    } else {
      //?name=Macbook&categoryId=1&typeId=2&storeId=1
      setName(urlParams.get('name') || "");
      setCategory(urlParams.get('categoryId') || "");
      setType(urlParams.get('typeId') || 1);
      setLast(urlParams.get('last24hours'));
      loadData(queryString)
    }
  };


  useEffect(() => {
    listenToQueryChange();
    window.addEventListener("querychange", () => listenToQueryChange(), false);
    return () => {
      window.removeEventListener("querychange", () => listenToQueryChange());
    };
  }, [])

  const renderImage = (item) => {
    if (item.product_media) {
      if (item.product_media[0]) {
        return `https://bigu.digital/zeleiloes-plataforma-backend/public/storage/products/${item.product_media[0].url}`
      }
    }

    return "https://socialistmodernism.com/wp-content/uploads/2017/07/placeholder-image.png";
  }

  return loadingPage ? null : (
    <>
      {
        contentType === 1 ? (
          <>
            <Header />
            <HeaderWithSearch />
          </>
        ) : <AuctioneerHeader />
      }

      <div className="container mt-5 full-height-container">
        
        {
          categories.map(function(item){
            if (category == item.id) {
              return <h3>Pesquisando por {item.name}</h3>
            }
          })
        }

        {
          type === "1" ? (<h3>Pesquisa Avançada - Leilões Ativos</h3>) : null
        }

        {
          type === "2" ? (<h3>Pesquisa Avançada - Últimas Públicações</h3>) : null
        }

        {
          type === "3" ? (<h3>Pesquisa Avançada - Leilões Pós Pregão</h3>) : null
        }

        {
          type === "4" ? (<h3>Pesquisa Avançada - Leilões Finalizados</h3>) : null
        }
        <div className="row mt-5">
          <div className="col-lg-3">
            <div className="border mb-3 px-2 pb-2">
              <div className="m-3">
                <FaFilter /> Filtros da pesquisa:
              </div>

              <input
                className="form-control"
                placeholder="Procure aqui"
                value={name}
                onChange={(e) => {
                  const queryString = window.location.search;
                  const urlParams = new URLSearchParams(queryString);
                  urlParams.set('name', e.target.value);
                  window.history.replaceState(null, null, `?${urlParams.toString()}`);
                  loadData(`?${urlParams.toString()}`)
                  setName(e.target.value)
                }}
              />

              {/* <div className="border-top p-3">
                Galerias:
                <select className="form-control"></select>
              </div>
              <div className="p-3">
                Estados:
                <select className="form-control"></select>
              </div> */}
            </div>
            <div className="border mb-3">
              <div className="m-3">
                <FaFilter /> Sub-categorias:
              </div>

              {
                categories.map((item, index) => (
                  <div key={index} className="border-top custom-checkbox p-3 pl-5">
                    <input
                      type="checkbox"
                      name="category"
                      className="custom-control-input"
                      id={item.label}
                      checked={category === item.id.toString()}
                      onChange={() => {
                        setCategory(item.id.toString())
                        const queryString = window.location.search;
                        const urlParams = new URLSearchParams(queryString);
                        urlParams.set('categoryId', item.id);
                        window.location.href = `/busca?${urlParams.toString()}`
                      }}
                    />
                    <label className="custom-control-label" for={item.label}>
                      {item.label}
                    </label>
                  </div>

                ))
              }
            </div>
          </div>

          <div className="col-lg-9">
            <div className="row d-flex">
              {
                loadingData && (
                  <div className="h-100 w-100 d-flex justify-content-center align-items-center">
                    <div className="spinner-border"></div>
                  </div>
                )
              }
              {
                !loadingData && products.map((item, index) => (
                  <a href={`/peca?id=${item.id}`} key={index} className="text-left list-group-item mt-2 w-100">
                    <div className="row align-items-center">
                      <div className="col-3">
                        <img
                          className="heightMax"
                          src={renderImage(item)}
                          alt=""
                          width='100%'
                        />
                      </div>
                      <div className="col-7">
                        <h5 className="title-product">{item.name}</h5>
                        <div className="row p-15">
                          {
                            item.product_types ? (
                              <span className="badge badge-pill badge-primary mt-1 mb-1 ml-0 types-products">{item.product_types.name}</span>
                            ) : null
                          }
                          {
                            item.product_categories ? (
                              <span className="badge badge-pill badge-info m-1 mb-1 ml-0 types-products">{item.product_categories.name}</span>
                            ) : null
                          }
                        </div>
                        <p className="description-product">{item.description}</p>
                      </div>
                      {/* <div className="col-2 d-flex align-items-center">
                        <a href={`/novo-lance?id=${item.id}`} className="btn btn-info">Fazer Lance</a>
                      </div> */}
                    </div>
                  </a>
                ))
              }

              {
                !loadingData && products.length === 0 && (
                  <div className="d-flex justify-content-center w-100" style={{ textAlign: 'center' }}>
                    <h3>Não foram encontrados resultados para sua busca.</h3>
                  </div>
                )
              }
            </div>
          </div>
        </div>

      </div>
    </>
  )
}

export default Search;
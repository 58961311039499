import React, { useState, useEffect } from 'react';
import { FaPen, FaShippingFast, FaTrash } from 'react-icons/fa';
import { Product, Store } from '../../api';
import { Header } from '../../components';
import {
  Modal
} from 'react-bootstrap';
import './style.css';
import InputMask from 'react-input-mask';
import theme from '../../utils/theme';
import { useAlert } from "react-alert";

const MyStore = () => {
  const alert = useAlert();
  const [editMode, setEditMode] = useState(false)
  const [tab, setTab] = useState(0)
  const [store, setStore] = useState({ data: {}, loading: true })
  const [products, setProducts] = useState({ data: [], loading: true })
  const [selected, setSelected] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);


  const loadData = async () => {
    let response = await Store.getData();
    console.log(response)
    if (response.status !== 'error') {
      setStore(Object.assign({}, { data: response[0], loading: false }))
    }
  }

  const loadProducts = async () => {
    let response = await Product.getAll();
    if (response.status !== 'error') {
      response = response.filter((a) => a.store_id === store.data.id)
      setProducts(Object.assign({}, { data: response, loading: false }))
    }
  }

  useEffect(() => {
    if (store.data.id) {
      loadProducts()
    }
  }, [store.data.id])

  useEffect(() => {
    loadData()

    document.getElementById("store-logo").addEventListener("input", uploadFile, false);

    // return () => {
    //   document.getElementById("store-logo").removeEventListener("input", uploadFile);
    // }
  }, [])

  const renderStoreImage = () => {
    if (store.data.img_url) {
      return `https://bigu.digital/zeleiloes-plataforma-backend/public/storage/stores/${store.data.img_url}`
    }

    return "https://socialistmodernism.com/wp-content/uploads/2017/07/placeholder-image.png";
  }

  const uploadFile = async (e) => {
    let formdata = new FormData();
    formdata.append("file", document.getElementById("store-logo").files[0], "logo.jpg");
    let response = await Store.uploadMedia(formdata)
    if (response.status === 'error') {
      alert.error('Erro')
    } else {
      loadData();
      alert.sucess('Arquivo enviado com sucesso!')
    }
  }

  const updateStore = async (event) => {
    event.preventDefault();

    let aux = Object.assign({}, store.data)

    delete aux.products
    Object.keys(aux).forEach(key => {
      if(!aux[key]){
        delete aux[key]
      }
    })

    let response = await Store.update(aux, store.data.id);
    if (response.status !== "error") {
      window.location.reload()
    } else {
      alert.error('Erro')
    }
  }

  const selectFile = (id) => {
    document.getElementById(id).click()
  }

  const handleEdit = (item) => {
    window.location.assign("/editprod/" + item.id)
  }

  const handleDelete = (item) => {
    setSelected(item);
    setShowModal(true);
  }

  const confirmDelete = async () => {
    let response = await Product.delete(selected.id);
    if (response.status !== 'error') {
      window.location.reload()
    } else {
      alert.error('Erro')
    }
    setShowModal(false);
  }

  return (
    <>
      <Header />

      <div className="container">
        <div className="col-12">
          <img className="my-banner border rounded" src={renderStoreImage()} />
        </div>

        <div className="row my-store-content">
          <div className="col-lg-3">
            <img className="my-logo border rounded" onClick={() => selectFile("store-logo")} src={renderStoreImage()} />
            <input hidden type="file" id="store-logo" />
          </div>
          <div className="col-lg-9 bg-light border rounded p-3">
            {
              editMode ? (
                <form onSubmit={updateStore} className="d-flex flex-column col-6 justify-content-between">
                  <input
                    required
                    className="form-control"
                    placeholder="Nome da loja"
                    onChange={(e) => setStore({ ...store, data: { ...store.data, company_name: e.target.value } })}
                    value={store.data.company_name}
                  />
                  <br />
                  <input
                    required
                    className="form-control"
                    placeholder="Nome fantasia"
                    onChange={(e) => setStore({ ...store, data: { ...store.data, fantasy_name: e.target.value } })}
                    value={store.data.fantasy_name} />
                  <br />
                  <InputMask
                    required
                    placeholder="CNPJ"
                    mask="99.999.999/9999-99"
                    className="contact form-control"
                    onChange={(e) => setStore({ ...store, data: { ...store.data, cnpj: e.target.value } })}
                    value={store.data.cnpj}
                  />
                  <br />

                  <div className="row justify-content-around">
                    <button type="submit" className="btn btn-primary col-5">Salvar</button>
                    <button onClick={() => setEditMode(false)} className="btn btn-outline-primary col-5">Cancelar</button>
                  </div>
                </form>
              ) : (
                <>
                  <h2>{store.data.company_name} <button onClick={() => setEditMode(true)} className="btn"><FaPen /></button></h2>
                  <h4>{store.data.fantasy_name} <button onClick={() => setEditMode(true)} className="btn"><FaPen /></button></h4>
                  <p>CNPJ: {store.data.cnpj} <button onClick={() => setEditMode(true)} className="btn"><FaPen /></button></p>
                </>
              )
            }
          </div>

          <div className="container mt-5">
            {/* <ul className="nav nav-tabs" style={{ cursor: 'pointer' }}>
              <li className="nav-item">
                <span className={`nav-link  ${tab === 0 ? 'active' : ''}`} onClick={() => setTab(0)}>Produtos</span>
              </li>
              <li className="nav-item">
                <span className={`nav-link  ${tab === 1 ? 'active' : ''}`} onClick={() => setTab(1)}>Leilões</span>
              </li>
            </ul> */}

            {
              tab === 0 && (
                <>
                  <div className="row justify-content-between m-3">
                    <h3>Produtos</h3>
                    <a href="/novo-produto"
                      className="btn product-btn"
                      style={{ backgroundColor: theme.AZUL_DARK, color: 'white' }}
                    >
                      Novo Produto
                    </a>
                  </div>

                  <Modal show={showModal} onHide={handleClose}>
                    <Modal.Header closeButton>
                      <Modal.Title>Tem certeza que deseja excluir o produto?</Modal.Title>
                    </Modal.Header>
                    {/* <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body> */}
                    <Modal.Footer>
                      <div className="btn btn-secondary" onClick={handleClose}>
                        Cancelar
                      </div>
                      <div className="btn btn-primary" onClick={confirmDelete}>
                        Excluir
                      </div>
                    </Modal.Footer>
                  </Modal>

                  <div className="row">
                    {
                      // store?.data?.products?.map((item, index) => (
                      products.data.map((item, index) => (
                        <div key={index} className="card mr-3 mb-3" style={{ width: "18rem" }}>
                          <button onClick={() => handleDelete(item)} className="btn btn-danger product-delete">
                            <FaTrash />
                          </button>
                          <button onClick={() => handleEdit(item)} className="btn btn-warning product-item">
                            <FaPen />
                          </button>
                          <img className="card-img-top"
                            src={item.product_media[0] ? `https://bigu.digital/zeleiloes-plataforma-backend/public/storage/products/${item.product_media[0].url}` : "https://socialistmodernism.com/wp-content/uploads/2017/07/placeholder-image.png"}
                            alt="Card image cap"
                          />
                          <div className="card-body">
                            <span className="badge badge-pill badge-primary mt-1 mb-1 ml-0">{item.product_types.name}</span>
                            <span className="badge badge-pill badge-info m-1 mb-1 ml-0">{item.product_categories.name}</span>
                            {
                              !item.auction ? null : item.auction.active === 1 ? (
                                <span className="badge badge-pill badge-danger mt-1 mb-1 ml-0">Ativo</span>
                              ) : null
                            }
                            {
                              item.free_shipping ? (
                                <span className="badge badge-pill badge-warning m-1 mb-1 ml-0">
                                  <FaShippingFast />
                                </span>
                              ) : null
                            }

                            <h5 className="card-title">{item.name}</h5>
                            <p className="card-text text-truncate">{item.description}</p>
                            {
                              item.auction ? (
                                <a href={`/peca?id=${item.id}`} className="btn btn-info" disabled>
                                  Ver Leilão
                                </a>
                              ) : item.sale ? item.sale.length > 0 ? (
                                <a href={`/peca?id=${item.id}`} className="btn btn-info" disabled>
                                  Ver Anúncio
                                </a>
                              ) : (
                                <>
                                  <a className="btn btn-primary" href={`/novo-leilao?prod=${item.id}`}>
                                    Criar Leilão
                                  </a>
                                  {/* <a className="btn btn-primary mt-2" href={`/novo-anuncio?prod=${item.id}`}>
                                    Criar Venda Direta
                                  </a> */}
                                </>
                              ) : (
                                <>
                                  <a className="btn btn-primary" href={`/novo-leilao?prod=${item.id}`}>
                                    Criar Leilão
                                  </a>
                                  {/* <a className="btn btn-primary mt-2" href={`/novo-anuncio?prod=${item.id}`}>
                                    Criar Venda Direta
                                  </a> */}
                                </>
                              )
                            }
                          </div>
                        </div>
                      ))
                    }
                  </div>
                </>
              )
            }

          </div>

        </div>
      </div >
    </>
  )
}

export default MyStore;
import * as types from './index';

export const setToken = token => (
    { token, type: types.SET_TOKEN }
);

export const setUserData = data => (
    { data, type: types.SET_USER_DATA }
);

export const setLanguage = language => (
    { language, type: types.SET_LANGUAGE }
);

import React from 'react';

import {
  FaMapMarkerAlt,
  FaShoppingCart,
  FaThLarge, FaUser
} from 'react-icons/fa';
import { useSelector, useDispatch } from 'react-redux';
import { setToken, setUserData } from '../../redux/actions/user';

const ProfileLeft = () => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user.data);

  const logout = () => {
    dispatch(setToken(null));
    dispatch(setUserData({}));
    setTimeout(() => {
      window.location.assign("home")
    }, 500);
  }

  return (
    <div className="col-lg-3">

      <div className="row">
        <div className="col-2">
          <div className="btn btn-info">
            <FaUser />
          </div>
        </div>
        <div className="col">
          <h5 className="m-0">Minha Conta</h5>
          {/* <strong className="font-12">Cartela: 12345</strong>
          <br /> */}
          <a className="btn btn-link p-0" onClick={logout}>Sair</a>
        </div>
      </div>

      <hr />

      <div className="col p-0">
        <strong>Nome:</strong>
        <p className="m-0">{user.first_name} {user.last_name}</p>
        <a className="btn btn-link p-0"><strong className="font-12">Alterar</strong></a>
        <br />
        <br />
        <strong>E-mail:</strong>
        <p className="m-0">{user.email}</p>
      </div>

    </div>
  )
}

export default ProfileLeft;
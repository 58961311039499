import React, { useState, useEffect } from 'react';
import { Footer, Header } from '../../components';
import {
  Modal
} from 'react-bootstrap';
import './style.css';
import theme from '../../utils/theme';
import { Auction, Store } from '../../api';
import './style.css';
import { FaShippingFast, FaTrash } from 'react-icons/fa';
import { useAlert, positions, types } from "react-alert";

const AuctionsScreen = () => {
  const alert = useAlert();
  const [auctions, setAuctions] = useState([]);
  const [selected, setSelected] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);

  const loadData = async () => {
    let response = await Auction.getAll();
    console.log(response)
    if (response.status !== 'error') {
      setAuctions(response);
    }
  }

  useEffect(() => {
    loadData();
  }, [])

  const handleDelete = (item) => {
    setSelected(item);
    setShowModal(true);
  }

  const confirmDelete = async () => {
    let response = await Auction.delete(selected.id);
    if (response.status !== 'error') {
      loadData();
    } else {
      alert.error('Erro')
    }
    setShowModal(false);
  }

  return (
    <>
      <Header />

      <div className="container pt-5 pb-5 full-height-container">
        <h3>Leilões ativos</h3>

        <Modal show={showModal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Tem certeza que deseja excluir o leilão?</Modal.Title>
          </Modal.Header>
          {/* <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body> */}
          <Modal.Footer>
            <div className="btn btn-secondary" onClick={handleClose}>
              Cancelar
            </div>
            <div className="btn btn-primary" onClick={confirmDelete}>
              Excluir
            </div>
          </Modal.Footer>
        </Modal>

        <div className="row">
          {
            auctions.map((item, index) => (
              <div key={index} className="card mr-3 mb-3" style={{ width: "18rem" }}>
                <button onClick={() => handleDelete(item)} className="btn btn-danger auction-delete">
                  <FaTrash />
                </button>
                <img className="card-img-top"
                  src={
                    item.products.product_media.length > 0
                    ? `https://bigu.digital/zeleiloes-plataforma-backend/public/storage/products/${item.products.product_media[0].url}`
                    : "https://socialistmodernism.com/wp-content/uploads/2017/07/placeholder-image.png"
                    }
                  alt={item.products.name}
                />
                <div className="card-body">
                  {
                    item.active ? (
                      <span className="badge badge-pill badge-primary mt-1 mb-1 ml-0">Ativo</span>
                    ) : null
                  }
                  <h5 className="card-title text-truncate">{item.auction_name}</h5>
                  <p className="card-subtitle">{item.products.name}</p>
                  <h5 className="card-text">R${(item.initial_price).toFixed(2).replace('.', ',')}</h5>
                  <p className="card-text text-truncate">
                    Lance mínimo: R${(item.min_bid).toFixed(2).replace('.', ',')}
                  </p>
                  {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
                </div>
              </div>
            ))
          }
        </div>

        {
          auctions.length === 0 ? (
            <div style={{ textAlign: 'center' }}>
              Não há leilões cadastrados
            </div>
          ) : null
        }
      </div>
    </>
  )
}

export default AuctionsScreen;
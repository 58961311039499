import React from 'react';
import { FutureAuctionsFooter, Header, HeaderWithSearch } from '../../components';
import Admin from '../../components/AdminPainel';
import {
    Container, Row, Col, Dropdown, InputGroup, Carousel, Button, Card, Badge, Form
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRss, faCog, faEnvelope, faMapMarkerAlt, faShareAlt, faShoppingCart } from "@fortawesome/free-solid-svg-icons";

  
import './styles.css';


const BuyandSell = () => {
    return (
        <div className="buyandsell">
            <Header />
            <HeaderWithSearch />
            <Container className="mt-5 full-height-container d-flex flex-column">
                <Row>
                    <Col xs={12} className="is-geraltitle">
                        <h2>Para arrematantes</h2>
                    </Col>

                    <Col xs={12}>
                    <div class="intern-title-buyandsell">
                        <h3><FontAwesomeIcon icon={faShoppingCart}/> Como Comprar</h3>
                    </div>
                    </Col>

                    <Col xs={12} className="mt-2">
                    <p>Para participar de um leilão, você deverá ter um cadastro válido e Ativo.</p>

                    <p>Caso ainda não tenha um cadastro, clique no link <a href="/autenticacao#cadastro" target="_blank">Cadastre-se</a> (canto superior direito) e preencha os dados solicitados.</p>

                    <p>Após o preenchimento, você receberá um <b>E-MAIL</b> com <b>LINK</b> para Ativação de seu cadastro.</p>

                    <p>Clique no link recebido para ativar seu cadastro. Pronto, você já está apto a efetuar lances.</p>

                    <p>Para efetuar um lance, faça o login e clique no lote de seu interesse.</p>

                    <p>Você será direcionado para a página da peça - clique no botão <b>"Fazer Lance"</b></p>

                    <p>Você poderá acompanhar sua participação no leilão clicando no link <a href="/perfil#meu-resumo">Minha conta</a>.</p>

                    <p><b>Lance Automático</b></p>

                    <p>Com o Lance Automático, o sistema fará lances mínimos, sempre que necessário, para que você continue como vencedor até que o valor máximo seja alcançado.</p>

                    <p>Para fazer um lance automático, preencha o valor máximo que deseja pagar pelo lote e <b>MARQUE o checkbox</b> "Lance Automático".</p>

                    </Col>

                    <Col xs={12}>
                    <p><b>Observações:</b></p>

                    <p>1 - Leilão não é comércio eletrônico. Todo leilão é certificado por um Leiloeiro(a) público oficial;</p>

                    <p>2 - Antes de registrar seu lance, leia os <b>"Termos e condições do leilão"</b> para verificar os procedimentos para retirada / envio e as condições de pagamento; Lembrando que <b>cada leilão possui regras específicas</b>.</p>

                    <p>3 - Você pode efetuar lances antes da data do pregão, contudo, o <b>lance vencedor será validado somente na data e horário informados no catálogo</b>;</p>

                    <p>4 - Lances efetuados após o encerramento do leilão serão aceitos ou não, a critério exclusivo do leiloeiro responsável;</p>

                    <p>5 - E-mails de avisos para “Lotes vigiados” e “Lances automáticos” não são executados durante o pregão;</p>

                    <p>6 - O comprador pagará ao leiloeiro uma comissão legal de 5% sobre o valor final da compra.</p>


                    <p><b>Leilão</b> - forma de alienação de bens.</p>

                    <p><b>Pregão</b> - forma de licitação pública, em data e horário definidos, onde é validado a escolha do melhor candidato pelo respectivo leiloeiro(a) responsável.</p>
                    
                    <p>&nbsp;</p>
                    </Col>

                    <Col xs={12}>
                        <div class="intern-title-buyandsell">
                            <h3><FontAwesomeIcon icon={faShoppingCart}/> Como Vender</h3>
                        </div>
                    </Col>

                    <Col xs={12} className="mt-2">
                        <p>Você também pode participar como vendedor, colocando suas peças para venda através de alguma galeria e/ou casa de leilão.</p>
                        <p>Através do link abaixo, poderá identificar as galerias mais próximas de seu endereço para possível avaliação e/ou venda de suas peças.</p>
                        <p><a href="#">www.zeleiloes.com.br/clientes</a></p>
                        <p>Lembrando que qualquer negociação entre as partes não possui ingerência ou responsabilidade da LeilõesBR. </p>
                        <p>O link com a listagem de clientes que utilizam nosso sistema é apenas informativo. </p>
                        <p>Qualquer consignação de peças <b>deverá estar devidamente respaldada através de Contrato de Comitente.</b></p>
                    </Col>
                </Row>
            </Container>
            <FutureAuctionsFooter/>
            <Admin/>
        </div>
    )
}

export default BuyandSell;
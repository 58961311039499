import React, { useEffect, useState } from 'react';
import { FaTags, FaTrash } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { ProfileLeft } from '../../components';
import { Modal } from 'react-bootstrap';
import { Product, Store } from '../../api';
import { useAlert, positions, types } from "react-alert";

const MyProducts = () => {
  const user = useSelector((state) => state.user.data);
  const alert = useAlert();

  const [products, setProducts] = useState([]);
  const [selected, setSelected] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);

  const loadData = async () => {
    let response = await Store.getData();
    if (response.status !== 'error') {
      setProducts(response[0].products);
    }
  }

  useEffect(() => {
    loadData();
  }, [])

  const handleDelete = (item) => {
    setSelected(item);
    setShowModal(true);
  }

  const confirmDelete = async () => {
    let response = await Product.delete(selected.id);
    if (response.status !== 'error') {
      loadData();
    } else {
      alert.error('Erro')
    }
    setShowModal(false);
  }

  return (
    <div className="row pl-3 pr-3 mt-5">
      <ProfileLeft />

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Tem certeza que deseja excluir o produto?</Modal.Title>
        </Modal.Header>
        {/* <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body> */}
        <Modal.Footer>
          <div className="btn btn-secondary" onClick={handleClose}>
            Cancelar
          </div>
          <div className="btn btn-primary" onClick={confirmDelete}>
            Excluir
          </div>
        </Modal.Footer>
      </Modal>

      {
        user.role > 0 ? (
          <>
            <div className="col-9">
              <div className="row align-items-center">
                <FaTags className="ml-3 mt-1" size={35} />
                <h3 className="col m-0">Minhas Peças</h3>
                <a href="/novo-produto" className="btn btn-info mr-3">
                  Nova Peça
                </a>
              </div>

              <hr />

              <div className="list-group">
                {
                  products.map((item, index) => (
                    <div onClick={() => window.location = `/peca?id=${item.id}`} key={index} className="btn text-left list-group-item mt-2">
                      <div className="row align-items-center">
                        <div className="col-5">
                          <img src={item.product_media[0] ?
                                `https://bigu.digital/zeleiloes-plataforma-backend/public/storage/products/${item.product_media[0].url}` :
                                "https://socialistmodernism.com/wp-content/uploads/2017/07/placeholder-image.png"} style={{ width: '100%' }} />
                        </div>
                        <div className="col-6">
                          <h5>{item.name}</h5>
                          <p>
                            {item.description}
                          </p>
                        </div>
                        <div className="col-1 d-flex align-items-center">
                          <button
                            onClick={(e) => {
                              e.stopPropagation()
                              handleDelete(item)
                            }}
                            className="btn btn-danger" >
                            <FaTrash />
                          </button>
                        </div>

                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
          </>
        ) : null
      }

    </div>
  )
}

export default MyProducts;
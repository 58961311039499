import React, { useEffect, useState } from 'react';

import InputMask from 'react-input-mask';

import {
  FaMapMarkerAlt,
  FaShoppingCart,
  FaThLarge, FaUser
} from 'react-icons/fa';
import { useSelector, useDispatch } from 'react-redux';
import { Store, User } from '../../api';
import { ProfileLeft } from '../../components';
import { setUserData } from '../../redux/actions/user';
import { useAlert, positions, types } from "react-alert";

const MyData = () => {
  const dispatch = useDispatch();
  const alert = useAlert();

  const user = useSelector((state) => state.user.data);

  /* Copia valores do user para o novo objeto chamado userForm */
  const [userForm, setUserForm] = useState(Object.assign({}, user));

  const [store, setStore] = useState(null)

  const loadStore = async () => {
    let response = await Store.getData();
    if (response.status !== 'error') {
      setStore(response)
    }
  }

  const reloadUserData = async () => {
    let response = await User.getData();
    if (response.status !== 'error') {
      dispatch(setUserData(response));
    }
  }

  /*Atualizar endereço - requisição */
  const loadInfo = async (event) => {
    event.preventDefault();
    let response = await User.update(userForm);
    if (response.status !== 'error') {
      alert.success("Informações atualizadas com sucesso!");
      reloadUserData();
      setTimeout(function(){
        window.location.reload();
     }, 1000);
    } else {
      alert.error("Não foi possível atualizar seus dados!", { position: positions.TOP_RIGHT });
    }
  }

  useEffect(() => {
    if (user.role > 0)
      loadStore()
  }, [])


  return (
    <div className="row pl-3 pr-3 mt-5">
      <ProfileLeft />

      <div className="col-9">
        <div className="row align-items-center">
          <FaUser className="ml-3 mt-1" size={35} />
          <h3 className="col m-0">Meus Dados</h3>
        </div>

        <hr />

        <div className="row align-items-center">
          <div className="btn btn-info ml-3 mt-1 pt-1">
            <FaUser />
          </div>
          <h5 className="col-8 m-0">Dados Cadastrais</h5>
        </div>

        <div className="col-12 mt-3 mb-5">
          <h6>CPF: {user.cpf}</h6>
          <h6>E-mail: {user.email}</h6>
          <h6>Nome: {user.first_name} {user.last_name}</h6>
        </div>

        {
          user.role > 0 ? (
            <>
              <hr />

              <div className="row align-items-center">
                <div className="btn btn-info ml-3 mt-1 pt-1">
                  <FaUser />
                </div>
                <h5 className="col-8 m-0">Dados de Leiloeiro</h5>
              </div>

              {
                !store ? (
                  <div className="col-12 mt-3 mb-5 p-0">
                    <a href="/novo-cadastro" className="btn btn-info">
                      Adicionar dados de leiloeiro
                    </a>
                  </div>
                ) : (
                  <div className="col-12 mt-3 mb-5">
                    <h6>CNPJ: {user.cpf}</h6>
                    <h6>E-mail: {user.email}</h6>
                    <h6>Nome: {user.first_name} {user.last_name}</h6>
                    <a href="/minha-loja" className="btn btn-info">
                      Acessar página completa
                    </a>
                  </div>
                )
              }
            </>
          ) : null
        }

        <hr />

        <div className="row align-items-center">
          <div className="btn btn-info ml-3 mt-1 pt-1">
            <FaMapMarkerAlt />
          </div>
          <h5 className="col-8 m-0">Endereço</h5>
        </div>

        <div className="col-12 py-3">
          <form onSubmit={loadInfo}>
            <ul>
              <li className="row input-label-container">
                <div class="col-xs-4 col-sm-2 label-container">
                  <label for="CEP">CEP:</label>
                </div>
                <div class="col-xs-8 col-sm-6 input-container input-box">
                  <div class="form-group has-feedback">
                    <InputMask mask="99999-999"
                    maskPlaceholder='-'
                    className="form-control"
                    value={userForm.cep}
                    onChange={e => setUserForm(Object.assign({}, {...userForm, cep: e.target.value}))}
                    required />
                  </div>
                </div>
              </li>
              <li class="row input-label-container">
                <div class="col-xs-4 col-sm-2 label-container">
                  <label for="RG">RG:</label>
                </div>
                <div class="col-xs-8 col-sm-6 input-container input-box">
                  <div class="form-group has-feedback">
                    <InputMask
                        required
                        placeholder="RG"
                        mask="99999.999-9"
                        className="contact form-control"
                        value={userForm.rg}
                        onChange={e => setUserForm(Object.assign({}, {...userForm, rg: e.target.value}))}
                        required
                      />
                  </div>
                </div>
              </li>
              <li class="row input-label-container">
                <div class="col-xs-4 col-sm-2 label-container">
                  <label for="End">Endereço:</label>
                </div>
                <div class="col-xs-8 col-sm-6 input-container input-box">
                  <div class="form-group has-feedback">
                  <input type="text"
                  className="form-control"
                  placeholder="Endereço"
                  value={userForm.adress}
                  onChange={e => setUserForm(Object.assign({}, {...userForm, adress: e.target.value}))}
                  required />
                  </div>
                </div>
              </li>
              <li class="row input-label-container">
                <div class="col-xs-4 col-sm-2 label-container">
                  <label for="Bairro">Bairro:</label>
                </div>
                <div class="col-xs-8 col-sm-6 input-container input-box">
                  <div class="form-group has-feedback">
                  <input type="text" 
                  className="form-control" 
                  id="validationDefault02" 
                  placeholder="Bairro" 
                  value={userForm.district} 
                  onChange={e => setUserForm(Object.assign({}, {...userForm, district: e.target.value}))}
                  required/>
                  </div>
                </div>
              </li>
              <li class="row input-label-container">
                <div class="col-xs-4 col-sm-2 label-container">
                  <label for="City">Cidade:</label>
                </div>
                <div class="col-xs-8 col-sm-6 input-container input-box">
                  <div class="form-group has-feedback">
                  <input type="text"
                  className="form-control"
                  id="validationDefault02"
                  placeholder="Cidade"
                  value={userForm.city} 
                  onChange={e => setUserForm(Object.assign({}, {...userForm, city: e.target.value}))}
                  required/>
                  </div>
                </div>
              </li>
              <li class="row input-label-container">
                <div class="col-xs-4 col-sm-2 label-container">
                  <label for="State">Estado:</label>
                </div>
                <div class="col-xs-8 col-sm-6 input-container input-box">
                  <select id="Estado"
                  className="inputB form-control"
                  name="UF" 
                  value={userForm.state}
                  onChange={e => setUserForm(Object.assign({}, {...userForm, state: e.target.value}))}
                  required>
                    <option value=""></option>
                    <option value="AC">Acre</option>
                    <option value="AL">Alagoas</option>
                    <option value="AM">Amazonas</option>
                    <option value="AP">Amapá</option>
                    <option value="BA">Bahia</option>
                    <option value="CE">Ceará</option>
                    <option value="DF">Distrito Federal</option>
                    <option value="ES">Espírito Santo</option>
                    <option value="GO">Goiás</option>
                    <option value="MA">Maranhão</option>
                    <option value="MG">Minas Gerais</option>
                    <option value="MS">Mato Grosso do Sul</option>
                    <option value="MT">Mato Grosso</option>
                    <option value="PA">Pará</option>
                    <option value="PB">Paraíba</option>
                    <option value="PE">Pernambuco</option>
                    <option value="PI">Piauí</option>
                    <option value="PR">Paraná</option>
                    <option value="RJ">Rio de Janeiro</option>
                    <option value="RN">Rio Grande do Norte</option>
                    <option value="RO">Rondônia</option>
                    <option value="RR">Roraima</option>
                    <option value="RS">Rio Grande do Sul</option>
                    <option value="SC">Santa Catarina</option>
                    <option value="SP">São Paulo</option>
                    <option value="SE">Sergipe</option>
                    <option value="TO">Tocantins</option>
                  </select>
                </div>
              </li>
              <li class="row input-label-container">
                <div class="col-xs-4 col-sm-2 label-container">
                  <label for="County">País:</label>
                </div>
                <div class="col-xs-8 col-sm-6 input-container input-box">
                  <div class="form-group has-feedback">
                  <input type="text"
                  className="form-control"
                  placeholder="País"
                  value={userForm.countries}
                  onChange={e => setUserForm(Object.assign({}, {...userForm, countries: e.target.value}))}/>
                  </div>
                </div>
              </li>
              <li class="row input-label-container">
                <div class="col-xs-4 col-sm-2 label-container">
                  <label for="Gender">Genero:</label>
                </div>
                <div class="col-xs-8 col-sm-6 input-container input-box">
                  <div class="form-group has-feedback">
                  <select name="sexo"
                  className="form-control"
                  value={userForm.sex}
                  onChange={e => setUserForm(Object.assign({}, {...userForm, sex: e.target.value}))} required>
                    <option value="" selected disabled>Escolhe um Genero</option>
                    <option value="M">Masculino</option>
                    <option value="F">Feminino</option>
                    <option value="J">Juridico</option>
                  </select>
                  </div>
                </div>
              </li>
              <li class="row input-label-container">
                <div class="col-xs-4 col-sm-2 label-container">
                  <label for="Cellphone">Celular:</label>
                </div>
                <div class="col-xs-8 col-sm-6 input-container input-box">
                  <div class="form-group has-feedback">
                  <InputMask mask="(99) 99999-9999"
                  maskPlaceholder='-'
                  className="form-control"
                  value={userForm.phone}
                  onChange={e => setUserForm(Object.assign({}, {...userForm, phone: e.target.value}))} 
                  required/>
                  </div>
                </div>
              </li>
              <li class="row input-label-container">
                <div class="col-xs-4 col-sm-2 label-container">
                  <label for="Not">Notificações:</label>
                </div>
                <div class="col-xs-8 col-sm-6 input-container input-box">
                  <div class="form-group has-feedback">
                  <select name="sexo"
                  className="form-control"
                  value={userForm.notification}
                  onChange={e => setUserForm(Object.assign({}, {...userForm, notification: e.target.value}))} 
                  required>
                    <option value="" selected disabled>Escolhe uma forma de notificação</option>
                    <option value="Whats">Whatsapp</option>
                    <option value="Email">Email</option>
                  </select>
                  </div>
                </div>
              </li>
              <li class="row input-label-container">
                <div class="col-xs-12 label-container">
                  <button type="submit" name="Botao" id="Botao" value="Atualizar" class="btn btn-info"> Atualizar </button>
                </div>
              </li>
            </ul>
          </form>
        </div>

      </div>
    </div >
  )
}

export default MyData;
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import { positions, Provider as AlertProvider, transitions } from "react-alert";
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import Top from './components/ButtontoTop/index';
import { Footer } from './components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faExclamationCircle, faTimes } from '@fortawesome/free-solid-svg-icons';

//import AlertTemplate from 'react-alert-template-basic';

dayjs.locale('pt-br');

function verificaraltura() {
  if (document.documentElement.scrollTop < 300) {
    document.getElementById("top").style.display = "none";
  } else {
    document.getElementById("top").style.display = "block";
  }
}

const AlertTemplate = ({ style, options, message, close }) => (
  <div className={`classe1 ${options.type === 'success' ? 'classeSucesso' : 'classeErro'}`}>
    {options.type === 'info' && <FontAwesomeIcon icon={faTimes} className="mx-2" />}
    {options.type === 'success' && <FontAwesomeIcon icon={faCheckCircle} className="mx-2" />}
    {options.type === 'error' && <FontAwesomeIcon icon={faExclamationCircle} className="mx-2" />}
    {message}
    <button className="btn mx-2" onClick={close}>X</button>

  </div>
)

const options = {
  timeout: 3000,
  position: positions.TOP_RIGHT,
  offset: '30px',
  transition: transitions.SCALE
};

ReactDOM.render(
  <React.StrictMode>    
    <AlertProvider template={AlertTemplate} {...options}>
      <div style={{ minHeight: '750px' }} onWheel={() => verificaraltura()}>
        <App />
      </div>
      <Top />
      <Footer />
    </AlertProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React from 'react';
import './style.css';

import Header from '../../components/Header';
import HeaderWithSearch from '../../components/Header/HeaderWithSearch';

const PrivacyPolicy = () => {
    return (
        <div>
            <Header/>
            <HeaderWithSearch/>

            <div class="conteudo-wrap py-4">
                <div class="conteudo-sizectrl">

                <div class="container">
                    <div class="is-geraltitle row">
                    <div class="col-sm-12 d-flex justify-content-center">
                        <h2>POLÍTICA DE PRIVACIDADE</h2>
                    </div>
                    </div>
                </div>

                <div class="container">
                    <div class="row">
                        <div class="col-xs-12">
                        <p class="para-title font-weight-bold px-3 mx-3">1. APRESENTANDO NOSSA POLÍTICA DE PRIVACIDADE</p>
                        <p>A presente Política de Privacidade vem estabelecer o modo em que serão tratados os dados pessoais dos Usuários do site Zé Leilões. Tais dados são coletados através do site www.zeleiloes.com  e demais extensões, incluindo-se as redes sociais da empresa, e-mails, entre outros. </p>
                        <p>A Política tem como escopo explicar e enumerar quais dados serão coletados, as razões e com quem será compartilhado. Não obstante, os usuários também devem cumprir as regras descritas no presente documento. O compartilhamento dos dados ocorre em razão da natureza da atividade da Zé leilões, não existindo qualquer outra razão que justifique o compartilhamento.</p>
                        <p>O nosso objetivo é ser entendível e transparente no tratamento dos dados dos seus usuários, por isso adotamos medidas cautelosas e seguras.</p>
                        <p>Deste modo, pede-se aos Usuários que leiam atentamente as cláusulas abaixo antes de efetivar a contratação de serviços.</p>
                        <p class="para-title font-weight-bold px-3 mx-3">2. DEFINIÇÕES</p>
                        <p>Caso algum termo deste documento não possuir definição, ela poderá ser encontrada no nosso <b>Termo de Condições de Uso.</b></p>
                        <p><b>A seguir as definições para melhor compreensão da presente Política de Privacidade:</b></p>
                        <p><b>Usuário:</b> todas as pessoas, sejam elas jurídicas ou pessoas físicas que utilizarão ou visitarão o site da Zé leilões. No caso de pessoa física, que sejam maiores de 18 (dezoito) anos ou emancipadas e totalmente capazes de praticar os atos da vida civil ou os absolutamente ou relativamente incapazes devidamente representados ou assistidos. No caso de pessoa jurídica, na pessoa do seu representante, pessoa que deverá deter os poderes para o exercício de representação.</p>
                        <p><b>Dados Pessoais:</b> significa quaisquer informações fornecidas e/ou coletadas pela Zé leilões, por qualquer meio, ainda que públicos, que: (a) identifiquem, ou que, quando usadas em combinação com outras informações tratadas pela Zé leilões reconheçam um indivíduo, em razão da pessoalidade; ou (b) por meio das quais a identificação ou informações de contato de uma pessoa física possam ser derivadas. Os Dados Pessoais podem estar em qualquer mídia ou formato, inclusive registros eletrônicos ou computadorizados, bem como em arquivos baseados em papel. Os Dados Pessoais, no entanto, não incluem telefone comercial, número de celular comercial, endereço comercial, e-mail comercial.</p>
                        <p><b>Controlador de dados:</b> é a empresa que controla os dados do usuário do site, no caso a Zé leilões, sendo ele o responsável pelo tratamento das informações declaradas pelos usuários a partir desta Política de Privacidade, com abrangência somente em território nacional.</p>
                        <p><b>Finalidade:</b> o objetivo, o propósito que a Zé leilões deseja alcançar a partir de cada ato de tratamento das informações pessoais.</p>
                        <p><b>Necessidade:</b> justificativa pelo qual é estritamente necessário coletar dados pessoais, para atingir a finalidade, evitando-se a coleta excessiva.</p>
                        <p><b>Consentimento:</b> autorização expressa e inequívoca dada pelo Usuário titular do dado pessoal para que a Zé leilões trate seus dados pessoais para uma finalidade previamente descrita, na qual a base legal necessária para o ato demande a autorização expressa do titular.</p>
                        <p class="para-title font-weight-bold px-3 mx-3">3. INFORMAÇÕES COLETADAS</p>
                        <p>A Zé leilões requisita e coleta informações pessoais sobre os usuários quando eles utilizam o site pela primeira vez. Esse ato é essencial para que a plataforma consiga realizar as primeiras etapas do objetivo do usuário e também para o cumprimento das obrigações legais.</p>
                        <p>A Zé leilões coleta apenas os dados essenciais para o funcionamento do serviço ofertado, de modo que, todo e qualquer dado extra, incluindo os dados sensíveis de acordo com a Lei Geral de Proteção de Dados, são ofertados de modo facultativo pelo usuário. Recomenda-se que sejam inseridos no site da Zé leilões somente os dados essenciais para a utilização dos serviços.</p>
                        <p>Nota-se que em todos formulários de cadastro haverá um check-box obrigatório para o aceite destes termos da Política de Privacidade. Em caso de recusa de qualquer item do presente documento, o Usuário ficará impedido de concluir o cadastro e, consequentemente, de utilizar a plataforma.</p>
                        <p>Fica expresso que o site da Zé leilões não é adequado para menores de 18 anos, nem se destina a coletar informações pessoais diretamente de pessoas menores de 18 anos. Se Zé leilões suspeitar que as informações pessoais da criança/adolescente foram tratadas e fornecidas inapropriadamente, elas serão levadas ao conhecimento das autoridades policiais ou judiciais competentes.</p>
                        <p><b>3.1 Informações de conta:</b> no momento em que usuário realiza o cadastro no site da Zé leilões, ele fornece seus dados pessoais completos e exatos. Dentro o rol dos dados, pode ser requisitado nome do utilizador para operar no site e informações de contato como telefone, celular e e-mail, documentos pessoais e produtos de interesse. </p>
                        <p>A Zé leilões pode confirmar os dados pessoais notificados e também tem o direito de consultar entidades públicas, empresas profissionais ou bancos de dados acerca destes dados, conforme já expresso nos Termos e Condições de Uso. As informações obtidas no site serão mantidas em sigilo.</p>
                        <p><b>3.2 Informações do Perfil e Anúncio:</b> ainda, para utilizar alguns recursos do site, o usuário deverá fornecer outros dados como número de telefone</p>
                        <p className="px-4 mx-4"><b>1. Informações para Verificação de Identidade:</b> Para ajudar a criar e manter um ambiente confiável, a Zé leilões poderá coletar informações para verificação de identidade, documentos pessoais como RG ou CNH ou também por outras informações de autenticação. Desde já, a Zé leilões expressa poderá coletar os seguintes dados para o cadastro dos usuários: apelido ou pseudônimo, nome, identificação, informações de contato, como número de telefone, endereço, e-mail etc., informações do dispositivo através do qual você acessa e endereço IP, lista de contatos, links entre contas e usuários, redes sociais, informações sobre localização e certas informações sobre a atividade, as preferências dos usuários e visitantes no nosso site e os aplicativos dos nossos serviços e direitos de propriedade intelectual.</p>
                        <p><b>3.4 Comunicações com a Zé leilões e com outros usuários:</b> Quando o Usuário se comunica com a Zé leilões ou utiliza o site para se comunicar com outros membros, são coletadas informações sobre essa comunicação e qualquer informação que o Usuário escolha fornecer.</p>
                        <p><b>3.5 Informações facultativas:</b> o Usuário tem ônus de fornecer dados pessoais no intuito de melhorar a experiência no site. Tais dados incluem gênero, cidade, idiomas, descrição pessoal, experiências. Além disso, a Zé leilões poderá realizar pesquisas de satisfação e de identificação de perfil do público, sendo estas pesquisas de cunho facultativo, onde poderá haver a coleta de dados considerados sensíveis. Nota-se que a participação e envio destas informações não são obrigatórias a nenhum usuário. Algumas dessas informações fazem parte da página do seu perfil público e serão publicamente visíveis.</p>
                        <p className="para-title font-weight-bold px-3 mx-3">4. MODO DE UTILIZAÇÃO DAS INFORMAÇÕES COLETADAS</p>
                        <p>A Zé leilões coleta e utiliza as informações para os seguintes fins:</p>
                        <p className="px-3 mx-3">
                            A) Cumprir com uma legislação que exija tal divulgação; <br/>
                            B) Permitir o acesso a plataforma da Zé leilões e a realização do leilão online;<br/>
                            C) Enviar mensagens, atualizações, alertas de segurança e notificações da conta;<br/>
                            D) Investigar e se defender contra quaisquer reivindicações ou alegações de terceiros;<br/>
                            E) Investigar, impedir ou tomar medidas relacionadas a atividades ilegais suspeitas ou reais ou para cooperar com órgãos públicos ou para proteger a segurança nacional;<br/>
                            F) Realizar verificações em bancos de dados e outras fontes de informação;<br/>
                            G) Manter seguro os serviços prestados;<br/>
                            H) Aplicar as condições de pagamento e outras políticas de pagamento;<br/>
                            I) Exercer ou proteger os direitos, a propriedade e a segurança da Zé leilões;<br/>
                            J) Proteger os direitos e a segurança pessoal de seus funcionários, usuários ou do público;<br/>
                            K) Para cumprir os termos de uso;<br/>
                            L) Convidar os Usuários para eventos e oportunidades relevantes;<br/>
                            M) Fornecer, personalizar, avaliar e melhorar a publicidade e marketing. <br/>
                        </p>
                        <p className="para-title font-weight-bold px-3 mx-3">5. COMPARTILHAMENTO E DIVULGAÇÃO</p>
                        <p>
                            Na hipótese de compartilhamento de dados do Usuário nas redes sociais do Controlador de Dados, ou seja, da Zé Leilões, esta só ocorrerá mediante prévia autorização do Usuário. <br/><br/>
                            O Usuário concorda em compartilhar certas informações, incluindo informações pessoais gerais, como o link de redes sociais, a localização do local da loja do Leiloeiro e demais dados que pertinentes para o cumprimento dos termos e condições do serviço.<br/><br/>
                            Quando o Usuário arremata um objeto, algumas informações são compartilhadas com o leiloeiro, necessárias para a negociação entre as partes para pagamento e retirada/envio, como perfil, nome completo, número de telefone e endereço do espaço.<br/><br/>
                            As plataformas de mídia social, com as quais as informações pessoais podem ser compartilhadas, não são controladas ou supervisionadas pela Zé leilões. Portanto, qualquer dúvida sobre como o provedor de serviços da plataforma de mídia social lida com suas informações pessoais deve entrar em contato diretamente com o gestor da rede social ou site vinculado.<br/>
                        </p>

                        <p className="para-title font-weight-bold px-3 mx-3">6. BASES LEGAIS PARA O PROCESSAMENTO</p>
                        <p>
                            A Zé leilões processa dados pessoais apenas quando autorizados legalmente ou com o consentimento expresso e explícito do usuário, conforme declarado nesta política. <br/><br/>
                            A Zé leilões tem a capacidade de coletar, gerar, receber, classificar, usar, acessar, copiar, transmitir, distribuir, processar, arquivar, armazenar, eliminar, avaliar ou controlar informações, modificar, comunicar, transferir, disseminar ou extrair usuários relacionados aos dados. A base jurídica inclui seu consentimento (coletado de forma clara no formulário de consentimento), contratos e procedimentos preliminares de contrato (devem ser processados ​​ao assinar o contrato com o usuário) e direitos e interesses legais, desde que esse processamento não viole seus direitos e liberdades. <br/><br/>
                            Não obstante, a Zé leilões poderá divulgar as informações dos Usuários, incluindo as de cunho pessoal, ao judiciário ou autoridades policiais ou governamentais, ou terceiros autorizados, se a medida for necessária ou permitida para atender a lei ou caso essa divulgação seja razoavelmente necessária, para o estrito cumprimento legal, para responder investigações judiciais de qualquer natureza, para aplicar e administrar os Termos de Serviço e para proteger os direitos, a propriedade ou a segurança pessoal da Zé leilões, de seus funcionários, membros ou membros do público. <br/><br/>
                            Caso seja necessário, a Zé leilões poderá notificar seus usuários para que estes forneçam os dados necessários. <br/>
                        </p>

                        <p className="para-title font-weight-bold px-3 mx-3">7. DIVULGAÇÃO DE INFORMAÇÕES</p>
                        <p>Na hipótese de a empresa Zé leilões realizar qualquer fusão, aquisição, reestruturação, venda de ativos, falência ou caso de insolvência, é possível que sejam vendidos, transferidos ou compartilhados alguns ou todos os ativos da companhia, incluindo suas informações relacionadas ou contempladas por tais transações. Neste caso, o Usuário será notificado antes que suas informações pessoais sejam transferidas e estejam sujeitas a uma política de privacidade diferente.</p>
                        
                        <p className="para-title font-weight-bold px-3 mx-3">8. SEGURANÇA</p>
                        <p>
                            Todas os Dados Pessoais serão guardados na base de dados da Zé leilões ou em base de dados mantidas “na nuvem” (hospedada nos servidores XXXXXXXX), pelos fornecedores de serviços contratados pela Zé leilões, os quais estão devidamente de acordo com a legislação de dados vigente.<br/><br/>
                            A Zé leilões e seus fornecedores usam uma variedade de procedimentos de segurança para proteger a confidencialidade, segurança e integridade de seus dados pessoais, evitando assim qualquer dano causado pelo processamento desses dados. Além disso, a Zé leilões garante que só profissionais capacitados terão acesso aos dados pessoais dos Usuários, se comprometendo a fornecer o mais alto nível de treinamento e conhecimento aos referidos colaboradores.<br/><br/>
                            Ainda que a Zé leilões use medidas de segurança e monitore seus sistemas para verificar vulnerabilidades e ataques para proteger seus dados pessoais contra divulgação, abuso ou adulteração, os Usuários entendem e concordam que não há garantia de que não há garantias de que as informações não poderão ser acessadas, divulgadas, alteradas ou destruídas por violação de qualquer uma das proteções físicas, técnicas ou administrativas.<br/>
                        </p>

                        <p className="para-title font-weight-bold px-3 mx-3">9. DIREITO DE ACESSO E CONTROLE DOS DADOS</p>
                        <p>
                            A Zé leilões, em cumprimento a Lei Geral de Proteção de Dados (Lei nº 13.709/2018), permitirá que o Usuário exclua os seus dados pessoais da plataforma, caso não haja continuidade do uso do site; altere ou corrija os dados pessoais que forem mutáveis, como endereço, telefone, etc.; coloque objeções, limites ou restrições ao uso de dados e, por fim, o Usuário tem direito de solicitar uma cópia dos dados pessoais em formato eletrônico.<br/><br/>
                            Em todas as hipóteses, será necessário que o Usuário encaminhe um e-mail para o setor de Data Protection Officer, que poderá ser contatado no endereço contato@zeleiloes.com, que analisará o pedido e tomará as medidas necessárias de acordo com as leis aplicáveis.<br/><br/>
                            O referido contato também poderá ser utilizado para esclarecimento de demais questões acerca do tratamento de dados.<br/>
                        </p>
                        <p className="para-title font-weight-bold px-3 mx-3">10. REVISÕES À POLÍTICA DE PRIVACIDADE</p>
                        <p>
                            A Zé Leilões se reserva ao direito de modificar esta Política de Privacidade a qualquer momento, de acordo com sua necessidade. Na hipótese de alterações, será publicada a Política de Privacidade revisada no site da Zé leilões e sinalizada com a identificação de que o documento foi revisado.<br/><br/>
                            Também será enviada ao usuário, por e-mail, uma notificação da alteração pelo menos 30 (trinta) dias antes da data de vigência da alteração. Caso o Usuário não concorde com a Política de Privacidade revisada, poderá cancelar a Conta.<br/><br/>
                            Na hipótese do usuário não cancelar a Conta antes da data de vigência da Política de Privacidade revisada, fica expressa a sua concordância e aceitação aos novos termos.<br/>
                        </p>
                        <p className="para-title font-weight-bold px-3 mx-3">11. FALE CONOSCO</p>
                        <p>Em caso de dúvidas ou reclamações sobre esta Política de Privacidade ou sobre as práticas de manipulação de informações da Zé leilões, envie-nos um e-mail para contato@zeleiloes.com</p>
                        </div>
                    </div>
                </div>  
            </div>
        </div>
    </div>
    )
}

export default PrivacyPolicy;

import React, { useState, useEffect } from 'react';
import {
  Container, Row, Col, Dropdown, InputGroup, Carousel, Button, Card, Badge
} from 'react-bootstrap';

import { Link } from 'react-router-dom';
import { faRss, faCog, faHome, faMousePointer } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './style.css';
import { FilterTabs, FutureAuctionsFooter, Header, HeaderWithSearch } from '../../components';

import Admin from '../../components/AdminPainel';

import Slide1 from '../../assets/logo2-horizontal.png';

import themeContext from '../../../src/utils/theme';
import {Store, Auction, Product } from '../../api';
import dayjs from 'dayjs';

const ASSETS_URL = 'https://bigu.digital/zeleiloes-plataforma-backend/public/storage/products/'

const Home = () => {

  const [auctioneers, setAuctioneers] = useState([]);
  const [carouselProducts, setCarouselProducts] = useState([]);
  const [products, setProducts] = useState([]);
  const [carouselAuctions, setCarouselAuctions] = useState([]);
  const [auctions, setAuctions] = useState([]);
  const [afterAuctions, setAfterAuctions] = useState([]);
  const [showAuctions, setShowAuctions] = useState();
  const [findbydate, setFindByDate] = useState([]);
  const [findbyname, setFindByName] = useState([]);
  const [showAuctionsByName, setShowAuctionsByName] = useState();
  const [findbystate, setFindByState] = useState([]);
  const [showAuctionsByState, setShowAuctionsByState] = useState();

  /* Buscar na URL a data do filtro */
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const findBy =  urlParams.get('data');

  /* Buscar na URL o nome do leiloeiro no filtro */
  const urlParamsLeiloeiro = new URLSearchParams(queryString);
  let findByLeiloeiro = urlParamsLeiloeiro.get('name');

  /* Buscar na URL o estado do leiloes do filtro */
  const urlParamsStates = new URLSearchParams(queryString);
  let findByStates = urlParamsStates.get('state');

  const loadAfterAuctions = async () => {
    let response = await Auction.getAfterAuction();
    if (response.status !== 'error') {
      let group = [];
      let i = -1;
      response.forEach((item, index) => {
        if(index < 100) {
          if (index % 4  === 0) {
            i++;
            group.push([])
          }
          group[i].push(item)
        } else {
          return;
        }
      })
      setAfterAuctions(group)
    }
  }

  const loadAuctioneers = async () => {
    let response = await Store.getRandom(8);
    if (response.status !== 'error') {
      let group = [];
      let i = -1;
      response.forEach((item, index) => {
        if (index < 8) {
          if (index % 4 === 0) {
            i++;
            group.push([])
          }
          group[i].push(item)
        } else {
          return;
        }
      })
      setAuctioneers(group);
    }
  }

  const loadAuctions = async () => {
    let response = await Auction.getRandom();
    // let response = await Store.getRandom(4);
    if (response.status !== 'error') {
      let group = [];
      let i = -1;
      response.forEach((item, index) => {
        if (index < 4) {
          if (index % 2 === 0) {
            i++;
            group.push([])
          }
          group[i].push(item)
        } else {
          return;
        }
      })
      // setAuctions(response);
      setCarouselAuctions(group);
    }
  }

  const loadFutureAuctions = async () => {
    let response = await Auction.getAllActive();
    if (response.status !== 'error') {
      setAuctions(response);
    }
  }

  const loadAuctionsByDate = async (value) => {
    if(value !== undefined) {
      let response = await Auction.getAuctionByDate(value);
      if (response.status !== 'error') {
        setFindByDate(response);
      }
    }
  }

  const loadProducts = async () => {
    let response = await Product.getAll();
    if (response.status !== 'error') {
      setProducts(response)
    }
  }

  const loadAuctionsByName = async (value) => {
    if(value !== undefined) {
      let response = await Auction.getAuctionByName(value);
      if (response.status !== 'error') {
        setFindByName(response)
      }
    }
  }

  const loadAuctionsByState = async (value) => {
    if(value !== undefined) {
      let response = await Auction.getAuctionByState(value);
      if (response.status !== 'error') {
        setFindByState(response)
      }
    }
  }
  
  useEffect(() => {
    loadAuctioneers();
    loadAuctions();
    loadFutureAuctions();
    loadAfterAuctions();
    loadProducts();
  }, [])

  useEffect(() => {
    if(findBy){
      setShowAuctions(true);
      loadAuctionsByDate(findBy)
    } else {
      setShowAuctions(false);
    }
  }, [])
  
  useEffect(() => {
    if(findByLeiloeiro) {
      loadAuctionsByName(findByLeiloeiro)
      setShowAuctionsByName(true)
    } else {
      setShowAuctionsByName(false)
    }
  }, [])

  useEffect(() => {
    if(findByStates) {
      loadAuctionsByState(findByStates)
      setShowAuctionsByState(true)
    } else {
      setShowAuctionsByState(false)
    }
  }, [])

  const goToSearch = () => {
    window.location.assign(`/busca?last24hours=24`)
  }

  return (
    <div>
      <Header />
      <HeaderWithSearch />
      <Container fluid className="my-5 p-0">
          <Carousel controls={false} indicators={true} interval={3000}>
            <Carousel.Item>
              <div className="d-flex justify-content-center">
                <img
                  className="w-100"
                  src={Slide1}
                  alt="First slide"
                />
              </div>
              <div class="banner-text has-bannershadow is-newbanner">
                  <div class="container">
                    <div class="row">
                      <p class="dia-leilao col-xs-12 white-txt has-shadow">Leilão 21502 | 30/08/2021</p>
                      <p class="titulo-leilao col-xs-12 white-txt has-shadow">LEILÃO DE ARTE E ANTIGUIDADES</p>
                      <p class="galeria-leilao col-xs-12 white-txt has-shadow"><FontAwesomeIcon icon={faHome}/> Ricardo Von Brusky</p>
                    </div>
                  </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="d-flex justify-content-center">
                <img
                  className="w-100"
                  src={Slide1}
                  alt="First slide"
                />
              </div>
              <div class="banner-text has-bannershadow is-newbanner">
                  <div class="container">
                    <div class="row">
                      <p class="dia-leilao col-xs-12 white-txt has-shadow">Leilão 21502 | 30/08/2021</p>
                      <p class="titulo-leilao col-xs-12 white-txt has-shadow">LEILÃO DE ARTE E ANTIGUIDADES</p>
                      <p class="galeria-leilao col-xs-12 white-txt has-shadow"><FontAwesomeIcon icon={faHome}/> Ricardo Von Brusky</p>
                    </div>
                  </div>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="d-flex justify-content-center">
                <img
                  className="w-100"
                  src={Slide1}
                  alt="First slide"
                />
              </div>
              <div class="banner-text has-bannershadow is-newbanner">
                  <div class="container">
                    <div class="row">
                      <p class="dia-leilao col-xs-12 white-txt has-shadow">Leilão 21502 | 30/08/2021</p>
                      <p class="titulo-leilao col-xs-12 white-txt has-shadow">LEILÃO DE ARTE E ANTIGUIDADES</p>
                      <p class="galeria-leilao col-xs-12 white-txt has-shadow"><FontAwesomeIcon icon={faHome}/> Ricardo Von Brusky</p>
                    </div>
                  </div>
              </div>
            </Carousel.Item>
          </Carousel>
      </Container>
      <Container fluid="md" className="my-3 d-flex flex-column">
        {
          carouselAuctions.length > 0 ? (
            <Row>
              <Col>
                <h3>
                  Leilões em destaque
                </h3>
                <Carousel controls={true} indicators={false} interval={3000}>
                  {
                    carouselAuctions.map((item, index) => (
                      <Carousel.Item key={index} className="home-carousel-item">
                        <Row>
                          {
                            item.map((a, b) => (
                              <Col key={b}>
                                <div className="card mr-3 mb-3">
                                  <a href={`/peca?id=${a.products.id}`}>
                                    {
                                      a.products.product_media.length > 2 ? (
                                        <div className="carousel-image-multiple">
                                          <div style={{ backgroundImage: `url('${ASSETS_URL + a.products.product_media[0].url}')` }}></div>
                                          <div style={{ backgroundImage: `url('${ASSETS_URL + a.products.product_media[1].url}')` }}></div>
                                          <div style={{ backgroundImage: `url('${ASSETS_URL + a.products.product_media[2].url}')` }}></div>
                                        </div>
                                      ) : (
                                        <img
                                          className="card-img-top"
                                          // className="d-block w-100 home-carousel-img"
                                          src={a.products.product_media.length > 0 ? ASSETS_URL + a.products.product_media[0].url : "https://socialistmodernism.com/wp-content/uploads/2017/07/placeholder-image.png"}
                                          alt={a.products.name}
                                        />
                                      )
                                    }

                                  </a>
                                  <div className="card-body">
                                    <a className="carousel-caption-text" href={`/peca?id=${a.products.id}`}>
                                      <h5 className="card-title">
                                        {a.auction_name}
                                      </h5>
                                    </a>
                                    <a className="carousel-caption-text" href={`/peca?id=${a.products.id}`}>
                                      <p className="card-text text-truncate carousel-text-gray">{a.auctioneer_name}</p>
                                    </a>
                                    <a className="carousel-caption-text" href={`/peca?id=${a.products.id}`}>
                                      <p className="card-text text-truncate">{dayjs(a.auction_date).format('DD MMMM YYYY, HH:mm')}</p>
                                    </a>

                                  </div>
                                </div>
                              </Col>
                            ))
                          }
                        </Row>
                      </Carousel.Item>
                    ))
                  }
                </Carousel>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col className="d-flex justify-content-center">
                <h1>No momento não há leilões para hoje</h1>
              </Col>
            </Row>
          )
        }
        
        <Button
          className="mt-5 mb-5 col-5 align-self-center"
          style={{ backgroundColor: themeContext.AZUL_DARK, border: 'none' }}
          size="lg"
          onClick={goToSearch}
        >
          Últimas Publicações - [Novo]
        </Button>
      </Container >

        {
          showAuctions === true ? (
            <Container fluid="md" className="my-3 d-flex flex-column">
              <Row>
                <Col className="is-newsectiontitle">
                  <h3>leilões <span className="font-weight-bold" style={{color: 'rgb(29, 77, 116)'}}>do dia {dayjs(findBy, 'YYYY-MM-DD').format('DD-MM-YYYY')}</span> </h3>
                </Col>
              </Row>

              <Row className="home-grid d-flex justify-content-center ">
                {
                  findbydate.length > 0 ? (
                    findbydate.map((item, index) => (
                      <Col key={index} className="mt-5" className="home-card">
                        <Card>
                          <Link to={`/peca?id=${item.products.id}`}>
                            <Card.Img
                              variant="top"
                              src={item.products.product_media[0] ?
                                `https://bigu.digital/zeleiloes-plataforma-backend/public/storage/products/${item.products.product_media[0].url}` :
                                "https://socialistmodernism.com/wp-content/uploads/2017/07/placeholder-image.png"}
                              style={{ width: '100%' }} />
                          </Link>
                          <Card.Body className="home-card-body">
                            <Card.Title className="leilao-title"><Link to={`/peca?id=${item.products.id}`}>{item.auction_name}</Link></Card.Title>
                            <Card.Subtitle className="mb-2 text-muted leilao-galeria">{/*<Link to={`/leiloeiro/${item.stores.id}`}>*/}{item.auctioneer_name}{/*</Link>*/}</Card.Subtitle>
                            <Card.Text>
                              {dayjs(item.auction_date).format('DD/MM/YYYY')}
                              <br />
                              {
                                item.active === 1 ? (
                                  <Badge variant="danger">EM ANDAMENTO <FontAwesomeIcon icon={faRss} /></Badge>
                                ) : null
                              }
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </Col>
                    ))
                  ) : (
                    <h1>Não há leilões</h1>
                  )
                }
              </Row>
            </Container>
          ) : null
        }

        {
          showAuctionsByName === true ? (
            <Container fluid="md" className="my-3 d-flex flex-column">
              <Row>
                <Col className="is-newsectiontitle">
                  <h3>leilões <span className="font-weight-bold" style={{color: 'rgb(29, 77, 116)'}}>do {findByLeiloeiro}</span> </h3>
                </Col>
              </Row>

              <Row className="home-grid d-flex justify-content-center ">
                {
                  findbyname.length > 0 ? (
                    findbyname.map((item, index) => (
                      <Col key={index} className="mt-5" className="home-card">
                        <Card>
                          <Link to={`/peca?id=${item.products.id}`}>
                            <Card.Img
                              variant="top"
                              src={item.products.product_media[0] ?
                                `https://bigu.digital/zeleiloes-plataforma-backend/public/storage/products/${item.products.product_media[0].url}` :
                                "https://socialistmodernism.com/wp-content/uploads/2017/07/placeholder-image.png"}
                              style={{ width: '100%' }} />
                          </Link>
                          <Card.Body className="home-card-body">
                            <Card.Title className="leilao-title"><Link to={`/peca?id=${item.products.id}`}>{item.auction_name}</Link></Card.Title>
                            <Card.Subtitle className="mb-2 text-muted leilao-galeria">{/*<Link to={`/leiloeiro/${item.stores.id}`}>*/}{item.auctioneer_name}{/*</Link>*/}</Card.Subtitle>
                            <Card.Text>
                              {dayjs(item.auction_date).format('DD/MM/YYYY')}
                              <br />
                              {
                                item.active === 1 ? (
                                  <Badge variant="danger">EM ANDAMENTO <FontAwesomeIcon icon={faRss} /></Badge>
                                ) : <Badge variant="warning">JÁ ENCERROU <FontAwesomeIcon icon={faRss} /></Badge>
                              }
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </Col>
                    ))
                  ) : (
                    <h1>Não há leilões</h1>
                  )
                }
              </Row>
            </Container>
          ) : null
        }

        {
          showAuctionsByState === true ? (
            <Container fluid="md" className="my-3 d-flex flex-column">
              {
                findbystate.map((item) => {
                  if (item.state === "Bahia") {
                    return <Row><Col className="is-newsectiontitle"><h3>leilões <span className="font-weight-bold" style={{color: 'rgb(29, 77, 116)'}}>da {item.state}</span> </h3></Col></Row>
                  } else if (item.state === "Alagoas" || "Goías" || "Minas Gerais" || "Pernambuco" || "Rondônia" || "Roraima" || "Santa Catarina" || "São Paulo" || "Sergipe") {
                    return <Row><Col className="is-newsectiontitle"> <h3>leilões <span className="font-weight-bold" style={{color: 'rgb(29, 77, 116)'}}>de {item.state}</span></h3></Col></Row>
                  } else {
                    return <Row><Col className="is-newsectiontitle"><h3>leilões <span className="font-weight-bold" style={{color: 'rgb(29, 77, 116)'}}>do {item.state}</span> </h3></Col></Row>
                  }
                })
              }

              {/* 
              {
                findbystate.map((item) => {
                  if(item.state === "Alagoas" || "Goías" || "Minas Gerais" || "Pernambuco" || "Rondônia" || "Roraima" || "Santa Catarina" || "São Paulo" || "Sergipe") {
                    return <Row><Col className="is-newsectiontitle"> <h3>leilões <span className="font-weight-bold" style={{color: 'rgb(29, 77, 116)'}}>de {item.state}</span></h3></Col></Row>
                  } else {
                    return <Row><Col className="is-newsectiontitle"><h3>leilões <span className="font-weight-bold" style={{color: 'rgb(29, 77, 116)'}}>do {item.state}</span> </h3></Col></Row>
                  }
                })
              }
              */}
              

              <Row className="home-grid d-flex justify-content-center ">
                {
                  findbystate.length > 0 ? (
                    findbystate.map((item, index) => (
                      <Col key={index} className="mt-5" className="home-card">
                        <Card>
                          <Link to={`/peca?id=${item.products.id}`}>
                            <Card.Img
                              variant="top"
                              src={item.products.product_media[0] ?
                                `https://bigu.digital/zeleiloes-plataforma-backend/public/storage/products/${item.products.product_media[0].url}` :
                                "https://socialistmodernism.com/wp-content/uploads/2017/07/placeholder-image.png"}
                              style={{ width: '100%' }} />
                          </Link>
                          <Card.Body className="home-card-body">
                            <Card.Title className="leilao-title"><Link to={`/peca?id=${item.products.id}`}>{item.auction_name}</Link></Card.Title>
                            <Card.Subtitle className="mb-2 text-muted leilao-galeria">{/*<Link to={`/leiloeiro/${item.stores.id}`}>*/}{item.auctioneer_name}{/*</Link>*/}</Card.Subtitle>
                            <Card.Text>
                              {dayjs(item.auction_date).format('DD/MM/YYYY')}
                              <br />
                              {
                                item.active === 1 ? (
                                  <Badge variant="danger">EM ANDAMENTO <FontAwesomeIcon icon={faRss} /></Badge>
                                ) : <Badge variant="warning">JÁ ENCERROU <FontAwesomeIcon icon={faRss} /></Badge>
                              }
                            </Card.Text>
                          </Card.Body>
                        </Card>
                      </Col>
                    ))
                  ) : (
                    <h1>Não há leilões</h1>
                  )
                }
              </Row>
            </Container>
          ) : null
        }
        
      <Container className="mt-5 pb-5">
        <Row>
          <Col>
            <h5>Próximos Leilões</h5>
            <p className="font-weight-bold"><span className="text-danger">{auctions.length} leilões em andamento</span></p>
          </Col>
          {/* <Col xs={5}>
            <div className="d-flex justify-content-end">
              <span className="col-md-3 offset-md-3">Ver todos os leilões</span>
              <Form.Check
                className="align-self-center"
                type="switch"
                id="custom-switch"
              />
              <span className="col-md-3">Ver novos leilões</span>
            </div>
          </Col> */}
          <Col xs={2}>
            <Dropdown as={InputGroup.Append} className="justify-content-end">
              <Dropdown.Toggle style={{ backgroundColor: themeContext.AZUL_DARK, border: 'none' }} id="dropdown-basic">
                <FontAwesomeIcon icon={faCog} style={{ color: themeContext.WHITE }} /> <span style={{ color: themeContext.WHITE }}>Filtros</span>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <FilterTabs />
              </Dropdown.Menu>
            </Dropdown>
          </Col>
        </Row>

        <Row>
          <Col className="is-newsectiontitle">
            <h3>leilões <span className="font-weight-bold" style={{color: 'rgb(29, 77, 116)'}}>ao vivo</span> <FontAwesomeIcon icon={faRss} className="mx-1"/> </h3>
          </Col>
        </Row>

        <Row className="home-grid">
          {
            auctions.map((item, index) => (
              <Col key={index} className="mt-5" className="home-card">
                <Card>
                  <Link to={`/peca?id=${item.products.id}`}>
                    <Card.Img
                      variant="top"
                      src={item.products.product_media[0] ?
                        `https://bigu.digital/zeleiloes-plataforma-backend/public/storage/products/${item.products.product_media[0].url}` :
                        "https://socialistmodernism.com/wp-content/uploads/2017/07/placeholder-image.png"}
                      style={{ width: '100%' }} />
                  </Link>
                  <Card.Body className="home-card-body">
                    <Card.Title className="leilao-title"><Link to={`/peca?id=${item.products.id}`}>{item.auction_name}</Link></Card.Title>
                    <Card.Subtitle className="mb-2 text-muted leilao-galeria">{/*<Link to={`/leiloeiro/${item.stores.id}`}>*/}{item.auctioneer_name}{/*</Link>*/}</Card.Subtitle>
                    <Card.Text>
                      {dayjs(item.auction_date).format('DD/MM/YYYY')}
                      <br />
                      {
                        item.active === 1 ? (
                          <Badge variant="danger">EM ANDAMENTO <FontAwesomeIcon icon={faRss} /></Badge>
                        ) : null
                      }
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))
          }
        </Row>
      </Container>

      {/* <Container className="mt-5">
        <Row className="home-btn mb-3 p-3">
          <div
            className="mt-5 btn-primary w-100 text-center p-2"
            style={{ backgroundColor: themeContext.AZUL_DARK, border: 'none', borderRadius: 5 }}
            size="lg" block
          >
            <h5>Novos Leiloeiros</h5>
          </div>
        </Row>

        <div className="row mb-2 d-flex">
          <div className="col-9">
            <h5>Leiloeiros em alta</h5>
          </div>
          <div className="col-3 text-right">
            <a className="btn btn-primary btn-custom" href="/leiloeiros">Ver Mais</a>
          </div>
        </div>

        <Carousel controls={false} indicators={false} interval={3000}>
          {
            auctioneers.map((item, index) => (
              <Carousel.Item key={index}>
                <div className="row justify-content-between">
                  <div className="col-4">
                    <img className="d-block w-100 home-store-logo border" src={"https://s3-sa-east-1.amazonaws.com/projetos-artes/fullsize%2F2016%2F10%2F26%2F01%2FLogo-197089_69798_012402415_1030811860.jpg"} />
                  </div>
                  <div className="col-4">
                    <img className="d-block w-100 home-store-logo border" src={"https://www.alshop.com.br/portal/wp-content/uploads/2019/01/Logo_Alshop15.jpg"} />
                  </div>
                  <div className="col-4">
                    <img className="d-block w-100 home-store-logo border" src={"http://3.bp.blogspot.com/-BKXhy1tV39Q/T8gi124BkpI/AAAAAAAAAlo/DRotA5m9OEo/s1600/Logo+Ecoshoes+-+01.jpg"} />
                  </div>
                </div>
              </Carousel.Item>
            ))
          }
        </Carousel>
      </Container> */}

      <div>
        <section className="is-anuncio-wrap">
          <Container>
            <Row>
              <Col xs={12}>
                <div class="is-newsectiontitle is-vendapos">
                  <h3>leilões <span>venda pós pregão</span></h3>
                </div>
              </Col>
            </Row>
          </Container>
          <div className="is-anuncio-wrap is-vendadireta-search is-vendadireta-search-home">
            <Container>
              <Row>
                <Carousel controls={true} indicators={false} interval={3000}>
                  {
                    afterAuctions.map((item, index) => (
                      <Carousel.Item key={index} className="home-carousel-item px-2">
                        <Row>
                          {
                            item.map((a, b) => (
                              <Col key={b} style={{minWidth: '289px'}}>
                                <Card className="borda-redonda">
                                  <a href={`/peca?id=${a.products.id}`}>
                                    {
                                      a.products.product_media.length > 2 ? (
                                        <div className="carousel-image-multiple">
                                          <div style={{ backgroundImage: `url('${ASSETS_URL + a.products.product_media[0].url}')` }}></div>
                                          <div style={{ backgroundImage: `url('${ASSETS_URL + a.products.product_media[1].url}')` }}></div>
                                          <div style={{ backgroundImage: `url('${ASSETS_URL + a.products.product_media[2].url}')` }}></div>
                                        </div>
                                      ) : (
                                        <img
                                          className="card-img-top"
                                          // className="d-block w-100 home-carousel-img"
                                          src={a.products.product_media.length > 0 ? ASSETS_URL + a.products.product_media[0].url : "https://socialistmodernism.com/wp-content/uploads/2017/07/placeholder-image.png"}
                                          alt={a.products.name}
                                        />
                                      )
                                    }
                                  </a>

                                  <Card.Body className="home-card-body">
                                    <a className="carousel-caption-text" href={`/peca?id=${a.products.id}`}>
                                      <h5 className="card-title">
                                        {a.auction_name}
                                      </h5>
                                    </a>
                                    <a className="carousel-caption-text" href={`/peca?id=${a.products.id}`}>
                                      <p className="card-text text-truncate carousel-text-gray">{a.auctioneer_name}</p>
                                    </a>
                                    <a className="carousel-caption-text" href={`/peca?id=${a.products.id}`}>
                                      <p className="card-text text-truncate">{dayjs(a.auction_date).format('DD MMMM YYYY, HH:mm')}</p>
                                    </a>
                                  </Card.Body>
                                </Card>
                              </Col>
                            ))
                          }
                        </Row>
                      </Carousel.Item>
                    ))
                  }
                </Carousel>
              </Row>
            </Container>
          </div>
          <div class="is-vendadireta-btn-wrap">
            <Container>
              <Row>
                <Col sm={12} className="d-flex justify-content-center is-vendadireta-btn">
                <span><a className="not-hover" href="#"><FontAwesomeIcon icon={faMousePointer} className="icon-hover" /> mais peças em <span>venda pós pregão</span></a></span>
                </Col>
              </Row>
            </Container>
          </div>
        </section>
      </div>
      <FutureAuctionsFooter />
      <Admin />
    </div>
  )
}

export default Home;

import React, { useState, useEffect } from 'react';
import {
    Container, Col, Media, Dropdown, InputGroup, FormControl,
    Navbar, Nav, NavDropdown, Row, Button
  } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './style.css';

import {
    FaUserCircle, FaSignOutAlt, FaHome,
    FaComment, FaQuestionCircle, FaEnvelope, FaCalendar, FaGavel, FaRss, FaUserLock, FaTag
  } from 'react-icons/fa';

import AuctioneerHeader from '../../components/AuctioneerHeader';
import Advertising from '../../components/Advertising';
import { faBook, faCalendarAlt, faChevronLeft, faChevronRight, faList } from '@fortawesome/free-solid-svg-icons';

const ListCatalog = () => {
    return (
        <>
            <AuctioneerHeader/>
            <Container id="content">
                <Row>
                    <Col md={12}>
                        <div class="inner-content-title">
                            <h2>Leilões em Andamento</h2>
                        </div>
                        <div className="inner-content-container">
                            <Row>
                                <Col md={3} className="leilao-box-wrap">
                                    <div className="catalog-box">
                                        <Col md={12}>
                                            <div class="cntblck">
                                                <div class="section-title p-0">
                                                    <h2>
                                                        <span> 
                                                            <FontAwesomeIcon icon={faCalendarAlt}/> 01/09/2021
                                                        </span>
                                                    </h2>
                                                </div>
                                                <div class="cat-title">
                                                    <h2>
                                                        <span class="title-numleilao">Leilão 22330</span>
                                                    </h2>
                                                </div>
                                            </div>  
                                        </Col>

                                        <Col md={12} className="image-container">
                                            <div className="capa-tab  active">
                                                <a href="#" title="Leilão PlaceHolder">
                                                    <img src="https://via.placeholder.com/300" border="0" alt=""/>
                                                    <div class="info-hover">
                                                    <div class="info-hover-wrap">
                                                        <div class="info-hover-wrap-inner">
                                                        <span>
                                                            Leilão Bata-me o Martelo-Vinis raros para colecionadores ...<br/><br/>
                                                            Ver mais informações
                                                        </span>
                                                        </div>
                                                    </div>
                                                    </div>
                                                </a>
                                            </div> 
                                        </Col>
                                        <Col className="mobile-hide" style={{marginTop: '15px'}}>
                                            <div class="leilao-nav ">
                                                <p>
                                                    <span>
                                                    <a href="#" title="Catálogo disponível" data-tippy="Catálogo disponível" class="leilao-button">
                                                        <FontAwesomeIcon icon={faList} size="2x"/>
                                                    </a>
                                                    <a href="#" data-tippy=" Veja o convite" class="leilao-button">
                                                        <FontAwesomeIcon icon={faBook} size="2x"/>
                                                    </a>
                                                    </span>
                                                </p>
                                            </div>
                                        </Col>
                                    </div>
                                </Col>
                                <Col md={3} className="leilao-box-wrap">
                                    <div className="catalog-box">
                                        <Col md={12}>
                                            <div class="cntblck">
                                                <div class="section-title p-0">
                                                    <h2>
                                                        <span> 
                                                            <FontAwesomeIcon icon={faCalendarAlt}/> 01/09/2021
                                                        </span>
                                                    </h2>
                                                </div>
                                                <div class="cat-title">
                                                    <h2>
                                                        <span class="title-numleilao">Leilão 22330</span>
                                                    </h2>
                                                </div>
                                            </div>  
                                        </Col>

                                        <Col md={12} className="image-container">
                                            <div className="capa-tab  active">
                                                <a href="#" title="Leilão PlaceHolder">
                                                    <img src="https://via.placeholder.com/300" border="0" alt=""/>
                                                    <div class="info-hover">
                                                    <div class="info-hover-wrap">
                                                        <div class="info-hover-wrap-inner">
                                                        <span>
                                                            Leilão Bata-me o Martelo-Vinis raros para colecionadores ...<br/><br/>
                                                            Ver mais informações
                                                        </span>
                                                        </div>
                                                    </div>
                                                    </div>
                                                </a>
                                            </div> 
                                        </Col>
                                        <Col className="mobile-hide" style={{marginTop: '15px'}}>
                                            <div class="leilao-nav ">
                                                <p>
                                                    <span>
                                                    <a href="#" data-tippy="Catálogo disponível" class="leilao-button">
                                                        <FontAwesomeIcon icon={faList} size="2x"/>
                                                    </a>
                                                    <a href="#" data-tippy=" Veja o convite" class="leilao-button">
                                                        <FontAwesomeIcon icon={faBook} size="2x"/>
                                                    </a>
                                                    </span>
                                                </p>
                                            </div>
                                        </Col>
                                    </div>
                                </Col>
                                <Col md={3} className="leilao-box-wrap">
                                    <div className="catalog-box">
                                        <Col md={12}>
                                            <div class="cntblck">
                                                <div class="section-title p-0">
                                                    <h2>
                                                        <span> 
                                                            <FontAwesomeIcon icon={faCalendarAlt}/> 01/09/2021
                                                        </span>
                                                    </h2>
                                                </div>
                                                <div class="cat-title">
                                                    <h2>
                                                        <span class="title-numleilao">Leilão 22330</span>
                                                    </h2>
                                                </div>
                                            </div>  
                                        </Col>

                                        <Col md={12} className="image-container">
                                            <div className="capa-tab  active">
                                                <a href="#" title="Leilão PlaceHolder">
                                                    <img src="https://via.placeholder.com/300" border="0" alt=""/>
                                                    <div class="info-hover">
                                                    <div class="info-hover-wrap">
                                                        <div class="info-hover-wrap-inner">
                                                        <span>
                                                            Leilão Bata-me o Martelo-Vinis raros para colecionadores ...<br/><br/>
                                                            Ver mais informações
                                                        </span>
                                                        </div>
                                                    </div>
                                                    </div>
                                                </a>
                                            </div> 
                                        </Col>
                                        <Col className="mobile-hide" style={{marginTop: '15px'}}>
                                            <div class="leilao-nav ">
                                                <p>
                                                    <span>
                                                    <a href="#" data-tippy="Catálogo disponível" class="leilao-button">
                                                        <FontAwesomeIcon icon={faList} size="2x"/>
                                                    </a>
                                                    <a href="#" data-tippy=" Veja o convite" class="leilao-button">
                                                        <FontAwesomeIcon icon={faBook} size="2x"/>
                                                    </a>
                                                    </span>
                                                </p>
                                            </div>
                                        </Col>
                                    </div>
                                </Col>
                                <Col md={3} className="leilao-box-wrap">
                                    <div className="catalog-box">
                                        <Col md={12}>
                                            <div class="cntblck">
                                                <div class="section-title p-0">
                                                    <h2>
                                                        <span> 
                                                            <FontAwesomeIcon icon={faCalendarAlt}/> 01/09/2021
                                                        </span>
                                                    </h2>
                                                </div>
                                                <div class="cat-title">
                                                    <h2>
                                                        <span class="title-numleilao">Leilão 22330</span>
                                                    </h2>
                                                </div>
                                            </div>  
                                        </Col>

                                        <Col md={12} className="image-container">
                                            <div className="capa-tab  active">
                                                <a href="#" title="Leilão PlaceHolder">
                                                    <img src="https://via.placeholder.com/300" border="0" alt=""/>
                                                    <div class="info-hover">
                                                    <div class="info-hover-wrap">
                                                        <div class="info-hover-wrap-inner">
                                                        <span>
                                                            Leilão Bata-me o Martelo-Vinis raros para colecionadores ...<br/><br/>
                                                            Ver mais informações
                                                        </span>
                                                        </div>
                                                    </div>
                                                    </div>
                                                </a>
                                            </div> 
                                        </Col>
                                        <Col className="mobile-hide" style={{marginTop: '15px'}}>
                                            <div class="leilao-nav ">
                                                <p>
                                                    <span>
                                                    <a href="#" data-tippy="Catálogo disponível" class="leilao-button">
                                                        <FontAwesomeIcon icon={faList} size="2x"/>
                                                    </a>
                                                    <a href="#" data-tippy=" Veja o convite" class="leilao-button">
                                                        <FontAwesomeIcon icon={faBook} size="2x"/>
                                                    </a>
                                                    </span>
                                                </p>
                                            </div>
                                        </Col>
                                    </div>
                                </Col>
                                <Col md={3} className="leilao-box-wrap">
                                    <div className="catalog-box">
                                        <Col md={12}>
                                            <div class="cntblck">
                                                <div class="section-title p-0">
                                                    <h2>
                                                        <span> 
                                                            <FontAwesomeIcon icon={faCalendarAlt}/> 01/09/2021
                                                        </span>
                                                    </h2>
                                                </div>
                                                <div class="cat-title">
                                                    <h2>
                                                        <span class="title-numleilao">Leilão 22330</span>
                                                    </h2>
                                                </div>
                                            </div>  
                                        </Col>

                                        <Col md={12} className="image-container">
                                            <div className="capa-tab  active">
                                                <a href="#" title="Leilão PlaceHolder">
                                                    <img src="https://via.placeholder.com/300" border="0" alt=""/>
                                                    <div class="info-hover">
                                                    <div class="info-hover-wrap">
                                                        <div class="info-hover-wrap-inner">
                                                        <span>
                                                            Leilão Bata-me o Martelo-Vinis raros para colecionadores ...<br/><br/>
                                                            Ver mais informações
                                                        </span>
                                                        </div>
                                                    </div>
                                                    </div>
                                                </a>
                                            </div> 
                                        </Col>
                                        <Col className="mobile-hide" style={{marginTop: '15px'}}>
                                            <div class="leilao-nav ">
                                                <p>
                                                    <span>
                                                    <a href="#" data-tippy="Catálogo disponível" class="leilao-button">
                                                        <FontAwesomeIcon icon={faList} size="2x"/>
                                                    </a>
                                                    <a href="#" data-tippy=" Veja o convite" class="leilao-button">
                                                        <FontAwesomeIcon icon={faBook} size="2x"/>
                                                    </a>
                                                    </span>
                                                </p>
                                            </div>
                                        </Col>
                                    </div>
                                </Col>
                                <Col md={3} className="leilao-box-wrap">
                                    <div className="catalog-box">
                                        <Col md={12}>
                                            <div class="cntblck">
                                                <div class="section-title p-0">
                                                    <h2>
                                                        <span> 
                                                            <FontAwesomeIcon icon={faCalendarAlt}/> 01/09/2021
                                                        </span>
                                                    </h2>
                                                </div>
                                                <div class="cat-title">
                                                    <h2>
                                                        <span class="title-numleilao">Leilão 22330</span>
                                                    </h2>
                                                </div>
                                            </div>  
                                        </Col>

                                        <Col md={12} className="image-container">
                                            <div className="capa-tab  active">
                                                <a href="#" title="Leilão PlaceHolder">
                                                    <img src="https://via.placeholder.com/300" border="0" alt=""/>
                                                    <div class="info-hover">
                                                    <div class="info-hover-wrap">
                                                        <div class="info-hover-wrap-inner">
                                                        <span>
                                                            Leilão Bata-me o Martelo-Vinis raros para colecionadores ...<br/><br/>
                                                            Ver mais informações
                                                        </span>
                                                        </div>
                                                    </div>
                                                    </div>
                                                </a>
                                            </div> 
                                        </Col>
                                        <Col className="mobile-hide" style={{marginTop: '15px'}}>
                                            <div class="leilao-nav ">
                                                <p>
                                                    <span>
                                                    <a href="#" data-tippy="Catálogo disponível" class="leilao-button">
                                                        <FontAwesomeIcon icon={faList} size="2x"/>
                                                    </a>
                                                    <a href="#" data-tippy=" Veja o convite" class="leilao-button">
                                                        <FontAwesomeIcon icon={faBook} size="2x"/>
                                                    </a>
                                                    </span>
                                                </p>
                                            </div>
                                        </Col>
                                    </div>
                                </Col>
                                <Col md={3} className="leilao-box-wrap">
                                    <div className="catalog-box">
                                        <Col md={12}>
                                            <div class="cntblck">
                                                <div class="section-title p-0">
                                                    <h2>
                                                        <span> 
                                                            <FontAwesomeIcon icon={faCalendarAlt}/> 01/09/2021
                                                        </span>
                                                    </h2>
                                                </div>
                                                <div class="cat-title">
                                                    <h2>
                                                        <span class="title-numleilao">Leilão 22330</span>
                                                    </h2>
                                                </div>
                                            </div>  
                                        </Col>

                                        <Col md={12} className="image-container">
                                            <div className="capa-tab  active">
                                                <a href="#" title="Leilão PlaceHolder">
                                                    <img src="https://via.placeholder.com/300" border="0" alt=""/>
                                                    <div class="info-hover">
                                                    <div class="info-hover-wrap">
                                                        <div class="info-hover-wrap-inner">
                                                        <span>
                                                            Leilão Bata-me o Martelo-Vinis raros para colecionadores ...<br/><br/>
                                                            Ver mais informações
                                                        </span>
                                                        </div>
                                                    </div>
                                                    </div>
                                                </a>
                                            </div> 
                                        </Col>
                                        <Col className="mobile-hide" style={{marginTop: '15px'}}>
                                            <div class="leilao-nav ">
                                                <p>
                                                    <span>
                                                    <a href="#" data-tippy="Catálogo disponível" class="leilao-button">
                                                        <FontAwesomeIcon icon={faList} size="2x"/>
                                                    </a>
                                                    <a href="#" data-tippy=" Veja o convite" class="leilao-button">
                                                        <FontAwesomeIcon icon={faBook} size="2x"/>
                                                    </a>
                                                    </span>
                                                </p>
                                            </div>
                                        </Col>
                                    </div>
                                </Col>
                                <Col md={3} className="leilao-box-wrap">
                                    <div className="catalog-box">
                                        <Col md={12}>
                                            <div class="cntblck">
                                                <div class="section-title p-0">
                                                    <h2>
                                                        <span> 
                                                            <FontAwesomeIcon icon={faCalendarAlt}/> 01/09/2021
                                                        </span>
                                                    </h2>
                                                </div>
                                                <div class="cat-title">
                                                    <h2>
                                                        <span class="title-numleilao">Leilão 22330</span>
                                                    </h2>
                                                </div>
                                            </div>  
                                        </Col>

                                        <Col md={12} className="image-container">
                                            <div className="capa-tab  active">
                                                <a href="#" title="Leilão PlaceHolder">
                                                    <img src="https://via.placeholder.com/300" border="0" alt=""/>
                                                    <div class="info-hover">
                                                    <div class="info-hover-wrap">
                                                        <div class="info-hover-wrap-inner">
                                                        <span>
                                                            Leilão Bata-me o Martelo-Vinis raros para colecionadores ...<br/><br/>
                                                            Ver mais informações
                                                        </span>
                                                        </div>
                                                    </div>
                                                    </div>
                                                </a>
                                            </div> 
                                        </Col>
                                        <Col className="mobile-hide" style={{marginTop: '15px'}}>
                                            <div class="leilao-nav ">
                                                <p>
                                                    <span>
                                                    <a href="#" data-tippy="Catálogo disponível" class="leilao-button">
                                                        <FontAwesomeIcon icon={faList} size="2x"/>
                                                    </a>
                                                    <a href="#" data-tippy=" Veja o convite" class="leilao-button">
                                                        <FontAwesomeIcon icon={faBook} size="2x"/>
                                                    </a>
                                                    </span>
                                                </p>
                                            </div>
                                        </Col>
                                    </div>
                                </Col>
                            </Row>
                        </div>

                        <div class="inner-content-title">
                            <h2>Leilões em captação</h2>
                        </div>

                        <div className="inner-content-container">
                            <Row>
                            <Col md={3} className="leilao-box-wrap">
                                    <div className="catalog-box">
                                        <Col md={12}>
                                            <div class="cntblck">
                                                <div class="section-title p-0">
                                                    <h2>
                                                        <span> 
                                                            <FontAwesomeIcon icon={faCalendarAlt}/> 01/09/2021
                                                        </span>
                                                    </h2>
                                                </div>
                                                <div class="cat-title">
                                                    <h2>
                                                        <span class="title-numleilao">Leilão 22330</span>
                                                    </h2>
                                                </div>
                                            </div>  
                                        </Col>

                                        <Col md={12} className="image-container">
                                            <div className="capa-tab  active">
                                                <a href="#" title="Leilão PlaceHolder">
                                                    <img src="https://www.anamelloleiloeira.com.br/imagens/foto.gif" border="0" alt=""/>
                                                    <div class="info-hover">
                                                    <div class="info-hover-wrap">
                                                        <div class="info-hover-wrap-inner">
                                                        <span>
                                                            Leilão Bata-me o Martelo-Vinis raros para colecionadores ...<br/><br/>
                                                            Ver mais informações
                                                        </span>
                                                        </div>
                                                    </div>
                                                    </div>
                                                </a>
                                            </div> 
                                        </Col>
                                        <Col className="mobile-hide" style={{marginTop: '15px'}}>
                                            <div class="leilao-nav ">
                                                <p>
                                                    <span>
                                                    <a href="#" data-tippy="Catálogo disponível" class="leilao-button">
                                                        <FontAwesomeIcon icon={faList} size="2x"/>
                                                    </a>
                                                    </span>
                                                </p>
                                            </div>
                                        </Col>
                                    </div>
                                </Col>
                            </Row>
                        </div>

                        <div class="inner-content-title">
                            <h2>Leilões finalizados</h2>
                        </div>

                        <div class="inner-content-container finalized">
                            <Row>
                                <Col md={12}>
                                    <div class="paginacao">
                                    <p>Página:</p>
                                    <ul>
                                        <li>
                                            <span>
                                            <FontAwesomeIcon icon={faChevronLeft} />
                                            </span>
                                        </li>
                                        <li class="fixedpag"><span>Primeira</span></li>
                                        <li class="dotdot"><span>...</span></li>
                                                <li class="ativo"><span>1</span></li>
                                                <li><a href="/listacatalogo.asp?pag=2">2</a></li>
                                                <li><a href="/listacatalogo.asp?pag=3">3</a></li>
                                                <li class="last-li"><a href="/listacatalogo.asp?pag=4">4</a></li>
                                        <li class="dotdot"><span>...</span></li>
                                    
                                        <li class="fixedpag"><a href="/listacatalogo.asp?pag=32">Última</a></li>
                                        <li class="last-li">
                                            <a href="/listacatalogo.asp?pag=2">
                                                <FontAwesomeIcon icon={faChevronRight} />
                                            </a>
                                        </li>
                                    </ul>
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={3} className="leilao-box-wrap finalizado-box-wrap">
                                    <div className="catalog-box">
                                        <Col md={12}>
                                            <div class="cntblck">
                                                <div class="section-title p-0">
                                                    <h2>
                                                        <span> 
                                                            <FontAwesomeIcon icon={faCalendarAlt}/> 28/08/2021
                                                        </span>
                                                    </h2>
                                                </div>
                                                <div class="cat-title">
                                                    <h2>
                                                        <span class="title-numleilao">Leilão 22330</span>
                                                        74º LEILÃO PONTO DO COLECIONADOR
                                                    </h2>
                                                </div>
                                            </div>  
                                        </Col>
                                    </div>
                                </Col>
                                <Col md={3} className="leilao-box-wrap finalizado-box-wrap">
                                    <div className="catalog-box">
                                        <Col md={12}>
                                            <div class="cntblck">
                                                <div class="section-title p-0">
                                                    <h2>
                                                        <span> 
                                                            <FontAwesomeIcon icon={faCalendarAlt}/> 28/08/2021
                                                        </span>
                                                    </h2>
                                                </div>
                                                <div class="cat-title">
                                                    <h2>
                                                        <span class="title-numleilao">Leilão 22330</span>
                                                        74º LEILÃO PONTO DO COLECIONADOR
                                                    </h2>
                                                </div>
                                            </div>  
                                        </Col>
                                    </div>
                                </Col>
                                <Col md={3} className="leilao-box-wrap finalizado-box-wrap">
                                    <div className="catalog-box">
                                        <Col md={12}>
                                            <div class="cntblck">
                                                <div class="section-title p-0">
                                                    <h2>
                                                        <span> 
                                                            <FontAwesomeIcon icon={faCalendarAlt}/> 28/08/2021
                                                        </span>
                                                    </h2>
                                                </div>
                                                <div class="cat-title">
                                                    <h2>
                                                        <span class="title-numleilao">Leilão 22330</span>
                                                        74º LEILÃO PONTO DO COLECIONADOR
                                                    </h2>
                                                </div>
                                            </div>  
                                        </Col>
                                    </div>
                                </Col>
                                <Col md={3} className="leilao-box-wrap finalizado-box-wrap">
                                    <div className="catalog-box">
                                        <Col md={12}>
                                            <div class="cntblck">
                                                <div class="section-title p-0">
                                                    <h2>
                                                        <span> 
                                                            <FontAwesomeIcon icon={faCalendarAlt}/> 28/08/2021
                                                        </span>
                                                    </h2>
                                                </div>
                                                <div class="cat-title">
                                                    <h2>
                                                        <span class="title-numleilao">Leilão 22330</span>
                                                        74º LEILÃO PONTO DO COLECIONADOR
                                                    </h2>
                                                </div>
                                            </div>  
                                        </Col>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Advertising/>
        </>
    )
}


export default ListCatalog;
import React, { useState, useEffect } from 'react';
import { Col, Button, Row } from 'react-bootstrap';
import InputMask from 'react-input-mask';
import { mask, unMask } from 'remask';
import { useAlert, positions, types } from "react-alert";
import { useDispatch } from 'react-redux';

import { Link } from 'react-router-dom';
import { Header, Footer } from '../../components';
import { User } from '../../api';
import { setToken } from '../../redux/actions/user';
import themeContext from '../../../src/utils/theme';

import './style.css';



const Auth = () => {

  /* Validacao CPF E CNPJ */

  function validacaoCPF () {

    let enableerror = document.getElementById("cpferror");
    let enablesuccess = document.getElementById("cpfsuccess");

    enablesuccess.innerText = "";
    enableerror.innerText = "";

    var cpf = document.getElementById("CPFVALUE").value.trim();
    console.log(cpf) 

    cpf = cpf.replace(/\./g, '');
    cpf = cpf.replace('-', '');
    cpf = cpf.split('');
    
    var v1 = 0;
    var v2 = 0;
    var aux = false;
    
    for (var i = 1; cpf.length > i; i++) {
        if (cpf[i - 1] != cpf[i]) {
            aux = true;   
        }
    } 
    
    if (aux == false) {
        enableerror.innerText = "CPF INVÁLIDO, TENTE NOVAMENTE"
        enableerror.style.display = "block";
    }  else {
        enableerror.style.display = "none";
        enablesuccess.innerText = "CPF VÁLIDO"
        enablesuccess.style.display = "block";
    }
    
    for (var i = 0, p = 10; (cpf.length - 2) > i; i++, p--) {
        v1 += cpf[i] * p; 
    } 
    
    v1 = ((v1 * 10) % 11);
    
    if (v1 == 10) {
        v1 = 0; 
    }
    
    if (v1 != cpf[9]) {
      enableerror.innerText = "CPF INVALIDO, TENTE NOVAMENTE"
      enableerror.style.display = "block";
    } 
    
    for (var i = 0, p = 11; (cpf.length - 1) > i; i++, p--) {
        v2 += cpf[i] * p; 
    } 
    
    v2 = ((v2 * 10) % 11);
    
    if (v2 == 10) {
        v2 = 0; 
    }
    
    if (v2 != cpf[10]) {
      enableerror.innerText = "CPF INVALIDO, TENTE NOVAMENTE"
      enableerror.style.display = "block";
    }
  }

  function validacaoCNPJ () {

    let enableerror = document.getElementById("cnpjerror");
    let enablesuccess = document.getElementById("cnpjsuccess");

    enablesuccess.innerText = "";
    enableerror.innerText = "";
    enableerror.style.display = "none";
    enablesuccess.style.display = "none";

    var cnpj = document.getElementById("CNPJVALUE").value.trim();

    cnpj = cnpj.replace(/\./g, '');
    cnpj = cnpj.replace('-', '');
    cnpj = cnpj.replace('/', ''); 
    cnpj = cnpj.split(''); 
    
    var v1 = 0;
    var v2 = 0;
    var aux = false;
    
    for (var i = 1; cnpj.length > i; i++) { 
        if (cnpj[i - 1] != cnpj[i]) {  
            aux = true;   
        } 
    } 
    
    if (aux == false) {
        console.log("entrou aqui 1")
        enableerror.innerText = "CNPJ INVÁLIDO, TENTE NOVAMENTE"
        enableerror.style.display = "block";
    }
    
    for (var i = 0, p1 = 5, p2 = 13; (cnpj.length - 2) > i; i++, p1--, p2--) {
        if (p1 >= 2) {  
            v1 += cnpj[i] * p1;  
        } else {  
            v1 += cnpj[i] * p2;  
        } 
    } 
    
    v1 = (v1 % 11);
    
    if (v1 < 2) { 
        v1 = 0; 
    } else { 
        v1 = (11 - v1); 
    } 
    
    if (v1 != cnpj[12]) {
      enableerror.innerText = "CNPJ INVALIDO, TENTE NOVAMENTE"
      enableerror.style.display = "block";
    } else {
      enablesuccess.innerText = "CNPJ VÁLIDO"
      enablesuccess.style.display = "block"
      enableerror.style.display = "none";
    }
    
    for (var i = 0, p1 = 6, p2 = 14; (cnpj.length - 1) > i; i++, p1--, p2--) { 
        if (p1 >= 2) {  
            v2 += cnpj[i] * p1;  
        } else {   
            v2 += cnpj[i] * p2; 
        } 
    }
    
    v2 = (v2 % 11); 
    
    if (v2 < 2) {  
        v2 = 0;
    } else { 
        v2 = (11 - v2); 
    } 
    
    if (v2 != cnpj[13]) {
        enableerror.innerText = "CNPJ INVALIDO, TENTE NOVAMENTE"
        enableerror.style.display = "block";
    } else {
      enablesuccess.innerText = "CNPJ VÁLIDO"
      enablesuccess.style.display = "block"  
      enableerror.style.display = "none";
    }
  }

  //Axios method //

  const [valueCep, setValueCep] = useState({})
  

  const AxiosCep = async(valueCep) => {
  
    let error = document.getElementById("alerterrorcep");
    let success = document.getElementById("alertsuccesscep")
    let cep = valueCep?.replace(/[^0-9]/g, '');

    success.style.display = "none";
    error.style.display = "none";

    /* Verificar tamanho */

    if (cep.length !== 8) {
      alert.error("Tamanho do cep é invalido")
      success.style.display = "none";
    } else {
      let response = await User.cep(valueCep);
      if (response.erro === true) {
        error.style.display = "block";
      } else {
        error.style.display = "none";
        success.style.display = "block";
        setValueCep (response);
      }
    }
  }

  const validarSenha = () => {
    let password = document.getElementById("Password").value;
    let confirm_password = document.getElementById("ConfirmPassword").value
    let enableerror = document.getElementById("alerterror");
    let enablesuccess = document.getElementById("alertsuccess");

    enablesuccess.innerText = "";
    enableerror.innerText = "";

    console.log(password + confirm_password.length)

    if(password != confirm_password && confirm_password.length > 0) {
      enableerror.innerText = "Senhas diferentes, por favor corrija"
      enableerror.style.display = "block";
      return false
    } else if (password != confirm_password && confirm_password.length === 0) {
      enableerror.innerText = "Insira a confirmação da sua senha"
      enableerror.style.display = "block";
    } else {
      enablesuccess.innerText = "Senhas iguais"
      enablesuccess.style.display = "block";
      enableerror.style.display = "none";
      return true
    }
  }

  const [rgvalue, setRgValue] = useState("");
  const onChange = ev => {
    setRgValue(mask(unMask(ev.target.value), ['9999.999-9', '99999.999-9']));
  }

  function ChoiceType () {
    var choice, cpfChoice, cnpjChoice, value;

    value = document.getElementById("validationCustom04").value;
    cpfChoice = document.getElementById("changetypecolCPF");
    cnpjChoice = document.getElementById("changetypecolCNPJ");

    if (value === 'cpf') {
      cnpjChoice.style.display =' none';
      cpfChoice.style.display = 'block';
    } else {
      cnpjChoice.style.display =' block';
      cpfChoice.style.display = 'none';
    }
  }


  const alert = useAlert();
  const dispatch = useDispatch();

  // Handle the form that will be rendered
  // 0 - Login
  // 1 - Register
  const [pageType, setPageType] = useState(0);

  const listenToHashChange = () => {
    /*
     * Check the window URL and set the form to be rendered according to the page type
     */
    const currentWindow = window.location.hash;
    setPageType(currentWindow === '#cadastro' ? 1 : 0)
  };

  useEffect(() => {
    listenToHashChange();
    window.addEventListener("hashchange", () => listenToHashChange(), false);
    return () => {
      window.removeEventListener("hashchange", () => listenToHashChange());
    };
  }, [])

  const login = async (event) => {
    event.preventDefault();

    let obj = {
      email: event.target[0].value,
      password: event.target[1].value,
      password_confirmation: event.target[1].value,
    }

    let response = await User.signIn(obj);
    if (response.token) {
      alert.success("Sucesso! Login Efetuado! ", { position: positions.TOP_RIGHT })
      dispatch(setToken(response.token));
      if (window.location.pathname === '/admin')
        window.location.assign("products")
      else
        window.location.assign("home")
    } else if(response.message === 'Your email address is not verified') {
      alert.error("Erro! Verifique seu email antes de tentar novamente", { position: positions.TOP_RIGHT });
    } else {
      alert.error("Erro! Digite novamente as credenciais")
    }
  }



  const register = async (event) => {
    event.preventDefault();

    const spinner = document.getElementById("spinner");
    spinner.removeAttribute('hidden');

    var cpfverified, cnpjverified

    var option = document.getElementById("validationCustom04").value
    var choiceCPF = document.getElementById("CPFVALUE").value;
    var choiceCNPJ = document.getElementById("CNPJVALUE").value

    if (option !== 'cpf') {
      cnpjverified = choiceCNPJ;
      cpfverified = null;
    } else {
      cpfverified = choiceCPF;
      cnpjverified = null;
    }

    let obj = {
      first_name: event.target[0].value,
      last_name: event.target[1].value,
      email: event.target[2].value,
      phone: event.target[3].value,
      password: event.target[4].value,
      password_confirmation: event.target[5].value,
      cpf: cpfverified,
      cnpj: cnpjverified,
      rg: event.target[9].value,
      birthdate: event.target[10].value,
      sex: event.target[11].value,
      cep: event.target[12].value,
      adress: event.target[13].value,
      district: event.target[14].value,
      city: event.target[15].value,
      state: event.target[16].value,
      countries: event.target[17].value,
      notification: event.target[18].value,
      email_accept: event.target[19].value,
      // role: event.target[10].value,
      device_id: null,
      device_os: navigator.platform,
    }

    let response = await User.signUp(obj);
    if (response.token) {
      alert.success("Sucesso! Verifique seu email para confirmar seu cadastro");
      setTimeout(function() {
        window.location.assign("autenticacao#login");
        spinner.setAttribute('hidden', '');
      }, 3000)
      // dispatch(setToken(response.token));
      // window.location.assign("home");

      // if (event.target[10].value === 1) {
      //   window.location.assign("novo-cadastro")
      // } else {
      //   window.location.assign("home")
      // }
    } else {
      // alert.error("Erro! Tente novamente.", { position: positions.TOP_RIGHT });
      alert.error(`Erro! ${getErrorMessage(response.message.errors)}`, { position: positions.TOP_RIGHT });
      spinner.setAttribute('hidden', '');
    }
  }

  const getErrorMessage = (obj) => {
    switch (obj[0]) {
      case 'The email has already been taken.':
        return "Este email já está cadastrado."
        break;
      case 'The password confirmation does not match.':
        return "A confirmação de senha está diferente"
        break;

      default:
        return ""
        break;
    }
  }

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if(urlParams.get('verify')){
      alert.success("Sucesso! Verifique seu email para confirmar a alteração");
    }
    if(urlParams.get('verified')) {
      alert.success("Seu novo email foi atualizado");
    }
  }, [window.location.search])

  return (
    <>
      <Header />

      {/* Inicio Escopo */}
      <div className="content-wrap full-height-container">
        <Col>
          <div className="logcad-wrap">
            <Col className="article-title">
              <h3 style={{ fontSize: '20px', fontWeight: '400', textAlign: 'center' }}>
                {pageType === 0 ? "Realize seu login abaixo" : "Realize seu cadastro abaixo"}
              </h3>
            </Col>

            {
              pageType === 0 ? (
                <form className="login-form" onSubmit={login}>
                  <Col>
                    <div className="form-group has-feedback">
                      <label>E-mail:</label>
                      <input
                        required
                        className="contact form-control"
                        placeholder="Digite seu email" />
                    </div>
                  </Col>

                  <Col>
                    <div className="form-group has-feedback">
                      <label>Senha:</label>
                      <input
                        required
                        type="password"
                        maxLength="15"
                        className="contact form-control"
                        placeholder="Digite sua senha">
                      </input>
                    </div>
                  </Col>

                  <div className="col">
                    <Link to="/recuperar-senha" style={{ color: themeContext.AZUL_DARK }}>Esqueci minha senha</Link>
                  </div>

                  <div className="col">
                    <Link to="/email-de-confirmacao" style={{ color: themeContext.AZUL_DARK }}>Reenviar email de confirmação</Link>
                  </div>

                  <div className="col mt-2">
                    <Button
                      style={{
                        backgroundColor: themeContext.AZUL_DARK,
                        border: 'none',
                        width: '144px',
                        marginTop: '20px'
                      }}
                      size="md sm"
                      block
                      type="submit"
                    >
                      Acessar
                    </Button>
                  </div>
                </form>
              ) : (
                <form className="register-form" onSubmit={register}>
                  <Row>
                    <div className="col-md-6">
                      <div className="form-group has-feedback">
                        <label>Nome:</label>
                        <input
                          required
                          className="contact form-control"
                          placeholder="Digite seu nome" />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group has-feedback">
                        <label>Sobrenome:</label>
                        <input
                          required
                          className="contact form-control"
                          placeholder="Digite seu sobrenome" />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group has-feedback">
                        <label>E-mail:</label>
                        <input
                          required
                          type="email"
                          className="contact form-control"
                          placeholder="Digite seu email" />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group has-feedback">
                        <label>Telefone:</label>
                        <InputMask
                          required
                          placeholder="Telefone"
                          mask="(99) 99999-9999"
                          name="phone"
                          className="contact form-control"
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group has-feedback">
                        <label>Senha:</label>
                        <input
                          id="Password"
                          required
                          type="password"
                          maxLength="15"
                          className="contact form-control"
                          placeholder="Digite sua senha">
                        </input>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group has-feedback">
                        <label>Confirme sua senha:</label>
                        <input
                          id="ConfirmPassword"
                          required
                          type="password"
                          maxLength="15"
                          className="contact form-control"
                          placeholder="Digite novamente sua senha"
                          onBlur={validarSenha}>
                        </input>
                        <h4 id="alerterror" className="alerterrorh4 my-1"></h4>
                        <h4 id="alertsuccess" className="alertsuccessh4 my-1"></h4>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group has-feedback">
                        <label>Cadastrar Como?</label>
                        <select 
                        class="custom-select" 
                        id="validationCustom04" 
                        onChange={ChoiceType}
                        required>
                          <option selected disabled>Selecione CPF ou CNPJ</option>
                          <option id="cpf" value="cpf">CPF</option>
                          <option id="cnpj" value="cnpj">CNPJ</option>
                        </select>
                      </div>
                    </div>

                    <div id="changetypecolCPF" className="col-md-6">
                      <div className="form-group has-feedback">
                        <label>CPF:</label>
                        <InputMask
                          id="CPFVALUE"
                          placeholder="CPF"
                          mask="999.999.999-99"
                          className="contact form-control"
                          onBlur={validacaoCPF}
                        />
                      </div>
                      <h4 id="cpferror" className="alerterrorh4 my-1"></h4>
                      <h4 id="cpfsuccess" className="alertsuccessh4 my-1"></h4>
                    </div>

                    <div id="changetypecolCNPJ" className="col-md-6">
                      <div className="form-group has-feedback">
                        <label>CNPJ:</label>
                        <InputMask
                          id="CNPJVALUE"
                          placeholder="CNPJ"
                          mask="99.999.999/9999-99"
                          className="contact form-control"
                          onBlur={validacaoCNPJ}
                        />
                      </div>
                      <h4 id="cnpjerror" className="alerterrorh4 my-1"></h4>
                      <h4 id="cnpjsuccess" className="alertsuccessh4 my-1"></h4>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group has-feedback">
                        <label>RG:</label>
                        <input
                        className="contact form-control"
                        type="text"
                        onChange={onChange}
                        value={rgvalue}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="form-group has-feedback">
                        <label>Data de nascimento:</label>
                        <input
                          required
                          placeholder="Data de nascimento"
                          type="date"
                          className="contact form-control"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group has-feedback">
                        <label>Sexo:</label>
                        <select
                          required
                          className="contact form-control"
                        >
                          <option value="">Selecione uma opção</option>
                          <option>Masculino</option>
                          <option>Feminino</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group has-feedback">
                        <label for="CEP">CEP:</label>
                        <InputMask 
                        name="cep"
                        className="form-control" 
                        type="text"
                        id="cep"
                        mask="99999-999"
                        onBlur={e => AxiosCep(e.target.value)}
                        minLength="8"
                        required
                        />
                        <div id="alerterrorcep" className="alerterrorcep">CEP informado está incorreto, por favor, digite novamente</div>
                        <div id="alertsuccesscep" className="alertsuccesscep">Seu CEP está correto</div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group has-feedback">
                        <label for="End">Endereço:</label>
                        <input 
                        name="rua" 
                        type="text"  
                        id="rua" 
                        size="60" 
                        className="form-control" 
                        value={valueCep.logradouro}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group has-feedback">
                        <label for="Bairro">Bairro:</label>
                        <input 
                        name="bairro"
                        type="text"
                        id="bairro"
                        size="40"
                        className="form-control"
                        value={valueCep.bairro}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group has-feedback">
                        <label for="City">Cidade:</label>
                        <input 
                        name="cidade"
                        type="text"
                        id="cidade"
                        size="40"
                        className="form-control"
                        value={valueCep.localidade}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group has-feedback">
                        <label for="Estado">Estado:</label>
                        <input
                        name="uf"
                        type="text"
                        id="uf"
                        size="2"
                        className="form-control"
                        value={valueCep.uf}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group has-feedback">
                        <label for="Pais">País:</label>
                        <input type="text" className="form-control" id="validationDefault02" placeholder="País" required />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group has-feedback">
                        <label for="notificacao">Notificações:</label>
                        <select name="sexo" id="Genero" className="form-control" required="">
                          <option value="" selected disabled>Escolhe uma forma de notificação</option>
                          <option value="Whats">Whatsapp</option>
                          <option value="Email">Email</option>
                          <option value="both">Email e Whatsapp</option>
                          <option value="not">Não quero receber notificações</option>
                        </select>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group has-feedback">
                        <label for="notificacao">Deseja receber emails de marketing?:</label>
                        <select className="form-control" required="">
                          <option value="" selected disabled>Selecione uma oção</option>
                          <option value="1">Sim</option>
                          <option value="0">Não</option>
                        </select>
                      </div>
                    </div>
                  </Row>

                  {/* <hr />

                    <Col>
                      <Col>
                        <div className="form-group has-feedback">
                          <label>Leiloeiro?</label>
                          <select
                            required
                            className="contact form-control"
                          >
                            <option value={1}>Sim</option>
                            <option value={0}>Não</option>
                          </select>
                        </div>
                      </Col>

                    </Col> */}


                  <div className="d-flex col-12 justify-content-center">
                    <a className="col-2 text-center" href="/autenticacao#login">Já tenho cadastro</a>
                  </div>

                  <div className="d-flex col-12 mt-2 justify-content-center">
                    <Button
                      className="col-2"
                      style={{
                        backgroundColor: themeContext.AZUL_DARK,
                        border: 'none',
                      }}
                      size="md sm"
                      block
                      type="submit"
                    >
                      Cadastrar
                    </Button>
                  </div>

                  <div className="d-flex col-12 mt-2 justify-content-center">
                    <Button
                      className="col-2"
                      style={{
                        backgroundColor: 'white',
                        color: themeContext.AZUL_DARK,
                        borderColor: themeContext.AZUL_DARK,
                      }}
                      size="md sm"
                      block
                      onClick={() => window.location.assign('/autenticacao#login')}
                    >
                      Voltar
                    </Button>
                  </div>
                </form>
              )
            }
          </div>
        </Col>
        <div hidden id="spinner"></div>
      </div>
      {/* Fim Escopo */}

    </>
  )
}


export default Auth;
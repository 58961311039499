import { faArrowAltCircleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import { FaWhatsapp, FaFacebook, FaTwitter, FaInstagram, FaPinterest, FaBlog, FaLock } from 'react-icons/fa';
import { Store } from '../../api';
import {useLocation} from 'react-router-dom';
import {Button} from 'react-bootstrap';

import LogoHeader from '../../assets/logo1-horizontal-transparente.png';

import './style.css';


const Footer = () => {

  

  const [auctioneer, setAuctioneer] = useState({})

  const loadData = async () => {
    // Load auctioneer from URL
    let id = window.location.pathname.split('/')
    if(id[2]) {
      let response = await Store.getById(id[2]);
      if (response.status !== 'error') {
        setAuctioneer(response)
      }  
    }
  }

  useEffect(() => {
    loadData()
  }, [])

  const loadImage = () => {
    return auctioneer.img_url ?
      `https://bigu.digital/zeleiloes-plataforma-backend/public/storage/stores/${auctioneer.img_url}` :
      "https://bigu.digital/images/bigu-v2-white.png"
  }

  return (
    <footer class="footer">
      <div className="container">
        <div className="row">
          <div className="col-lg-3">
            <div className="is-footerlogo">
              <a href="#" title="Leilões Br">
                <img
                  src={window.location.hostname === 'teste.zeleiloes.com' ? LogoHeader : loadImage()}
                  alt="Leilões BR" />
              </a>
            </div>
          </div>

          <div className="col-lg-9">
            <div className="row">
              <div className="col-xs-12 col-sm-4 col-lg-6 is-footerendereco">
                <h4 style={{ color: 'white' }}>Endereço:</h4>
                <p className="p-footer">
                  Santos Dumont, Conjunto 6, Nº 1070
                    </p>
              </div>

              {/* <div className="col-xs-12 col-sm-4 col-lg-4 is-footercontato">
                <h4 style={{ color: 'white' }}>Contato:</h4>
                <p className="p-footer">
                    <br />
                    </p>
              </div> */}

              <div className="col-xs-12 col-sm-4 col-lg-6 is-footer-social">
                <h4 style={{ paddingLeft: '25px', color: 'white' }}>Midias Sociais</h4>
                <ul>
                  <li><FaWhatsapp size={20} /></li>
                  <li><FaFacebook size={20} /></li>
                  <li><FaTwitter size={20} /></li>
                  <li><FaInstagram size={20} /></li>
                  <li><FaPinterest size={20} /></li>
                  <li><FaBlog size={20} /></li>
                </ul>
              </div>
            </div>

            <div className="row mt-5">
              <div className="col-xs-12 col-sm-4 col-lg-6 is-footerendereco">
                <h4 style={{ color: 'white' }}>Quem somos:</h4>
                <p className="p-footer"><a href="#" className="change-text">Nossos Leiloeiros</a></p>
                <p className="p-footer"><a href="/sobre" className="change-text">Sobre o Zé Leilões</a></p>
                <p className="p-footer"><a href="/contato" className="change-text">Contato</a></p>
              </div>
              {/**
              <div className="col-xs-12 col-sm-4 col-lg-4 is-footercontato">
                 
                <h4 style={{ color: 'white' }}>Leiloeiros e Galerias:</h4>
                <p className="p-footer"><a href="#" className="change-text">Vantagens e Recursos</a></p>
              <p className="p-footer"><a href="#" className="change-text">Venda na LeilõesBR </a></p> 
              </div>
              */}

              <div className="col-xs-12 col-sm-4 col-lg-6 is-footer-social">
                <h4 style={{ color: 'white' }}><FaLock size={25} style={{ paddingRight: '10px', paddingBottom: '5px' }} />Dados Seguros</h4>
                <p style={{ fontSize: '14px' }}>Todos os dados trafegam por uma conexão SSL segura! Sua privacidade é <b>Garantida</b></p>
                <img src="https://sectigo.com/images/seals/sectigo_trust_seal_sm_2x.png" alt="certificado digital"></img>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-xs-12">
            <p class="copyright">Copyright © Zé Leilões | Todos os direitos reservados 2021. <a href="/termosecondicoes">Termos e condições de uso</a> | <a href="/politicadeprivacidade">Política de privacidade</a></p>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
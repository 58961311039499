
import React, { useEffect, useState } from 'react';
import {
  Container, Row, Col, Table
} from 'react-bootstrap';

import { Link } from 'react-router-dom';
import { faRss, faCog } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './style.css';
import { Header } from '../../../components';
import themeContext from '../../../utils/theme';
import { User } from '../../../api';
import { FaPen, FaTrash } from 'react-icons/fa';
import { useAlert } from "react-alert";
import { Modal } from 'react-bootstrap';
import dayjs from 'dayjs';

var advancedFormat = require('dayjs/plugin/advancedFormat')
dayjs.extend(advancedFormat)

const AdminUsers = () => {
  const alert = useAlert();

  const [users, setUsers] = useState([]);
  const [userToEdit, setUserToEdit] = useState(null);

  const loadData = async () => {
    let response = await User.getAllUsers();
    if (response.status !== 'error') {
      setUsers(response);
    }
  }

  useEffect(() => {
    loadData();
  }, [])

  const deleteUser = async (id) => {
    let response = await User.delete(id)
    if (response.status !== 'error') {
      alert.success('Usuário excluído com sucesso!')
      loadData()
    } else {
      alert.error('Não foi possível excluir o usuário!')
    }
  }

  const getRoleName = (id) => {
    switch (id) {
      case 1:
        return "Leiloeiro"
        break;
      case 2:
        return "Admin"
        break;

      default:
        return "Usuário"
        break;
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    let response = await User.update(userToEdit);
    if (response.status !== 'error') {
      alert.success('Sucesso! O usuário foi atualizado')
      setUserToEdit(null)
      loadData()
    } else {
      alert.error('Erro! Não foi possível atualizar os dados do usuário')
    }
  }

  return (
    <div>
      <Header />

      <Modal show={userToEdit ? true : false} onHide={() => setUserToEdit(null)}>
        {
          userToEdit && (
            <>
              <Modal.Header closeButton>
                <Modal.Title>Editar {userToEdit.name}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <form onSubmit={handleSubmit} id="edit-form">
                  <input
                    className="form-control mb-2"
                    placeholder="Nome"
                    value={userToEdit.first_name}
                    onChange={(e) => setUserToEdit(Object.assign({}, { ...userToEdit, first_name: e.target.value }))}
                    required
                  />
                  <input
                    className="form-control mb-2"
                    placeholder="Sobrenome"
                    value={userToEdit.last_name}
                    onChange={(e) => setUserToEdit(Object.assign({}, { ...userToEdit, last_name: e.target.value }))}
                    required
                  />
                  <input
                    className="form-control mb-2"
                    placeholder="Email"
                    value={userToEdit.email}
                    onChange={(e) => setUserToEdit(Object.assign({}, { ...userToEdit, email: e.target.value }))}
                    required
                  />
                  <input
                    className="form-control mb-2"
                    placeholder="CPF"
                    value={userToEdit.cpf}
                    onChange={(e) => setUserToEdit(Object.assign({}, { ...userToEdit, cpf: e.target.value }))}
                    required
                  />
                  <select
                    className="form-control mb-2"
                    onChange={(e) => setUserToEdit(Object.assign({}, { ...userToEdit, role: parseInt(e.target.value) }))}
                    required
                  >
                    <option value={0} selected={userToEdit.role === 0}>Usuário</option>
                    <option value={1} selected={userToEdit.role === 1}>Leiloeiro</option>
                    <option value={2} selected={userToEdit.role === 2}>Admin</option>
                  </select>
                  {/* <label for="verified">Verificado:</label>
                  <input
                    id="verified"
                    className='ml-2'
                    type="checkbox"
                    onChange={(e) => {
                      alert.success(dayjs().format('X'))
                      if(!userToEdit.email_verified_at) {
                        setUserToEdit(Object.assign({}, { ...userToEdit, email_verified_at: dayjs().format('X') }))
                      } else {
                        setUserToEdit(Object.assign({}, { ...userToEdit, email_verified_at: null }))
                      }
                    }}
                    value={userToEdit.email_verified_at ? true : false}
                  /> */}
                </form>
              </Modal.Body>
              <Modal.Footer>
                <div className="btn btn-secondary" onClick={() => setUserToEdit(null)}>
                  Cancelar
                </div>
                <div className="btn btn-primary" onClick={() => document.getElementById('edit-form').requestSubmit()}>
                  Salvar
                </div>

              </Modal.Footer>
            </>
          )
        }
      </Modal>

      <Container fluid="md" className="mt-5 full-height-container">
        <h4>Usuários</h4>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Nome</th>
              <th>Email</th>
              <th>CPF</th>
              <th>Tipo</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {
              users.map((item, index) => (
                <tr key={index}>
                  <td>{item.id}</td>
                  <td>{item.first_name} {item.last_name}</td>
                  <td>{item.email}</td>
                  <td>{item.cpf}</td>
                  <td>{getRoleName(item.role)}</td>
                  <td className="d-flex justify-content-around border-0">
                    {
                      item.role < 2 && (
                        <>
                          <buton className="btn btn-danger" onClick={() => deleteUser(item.id)}>
                            <FaTrash />
                          </buton>
                          <buton className="btn btn-warning" onClick={() => setUserToEdit(item)}>
                            <FaPen />
                          </buton>
                        </>
                      )
                    }
                  </td>
                </tr>
              ))
            }
          </tbody>
        </Table>
        {
          users.length === 0 ? (
            <div style={{ textAlign: 'center' }}>
              Não há usuários cadastrados
            </div>
          ) : null
        }

      </Container>
    </div >
  )
}

export default AdminUsers;
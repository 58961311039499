import React, { useEffect, useState } from 'react';
import { Header } from '../../../components';
import './style.css';
import { Product, Store } from '../../../api';
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import { useSelector } from 'react-redux';

const numberMask = createNumberMask({
  prefix: 'R$',
  thousandsSeparator: false,
  thousandsSeparatorSymbol: '',
  allowDecimal: true,
  decimalSymbol: ',',
})

const NewSale = () => {
  const user = useSelector((state) => state.user.data);

  const [product, setProduct] = useState({});
  const [store, setStore] = useState({});

  const loadProductData = async () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const prodId = urlParams.get('prod');
    if (prodId) {
      let response = await Product.getById(prodId);
      if(response.status !== 'error') {
        setProduct(response)
      }
    } else {
      window.location = '/leiloes'
    }
  }

  const loadStoreData = async () => {
    let response = await Store.getData();
    if (response.status !== 'error') {
      setStore(response[0])
    }
  }

  const submitAuction = async (event) => {
    event.preventDefault();

    // let initial_price = parseFloat(event.target[3].value.replace(',', '.').replace('R$', ''))

    let obj = {
      user_id: user.id,
      store_id: store.id,
      product_id: product.id,
      bid_id: 0,
      payment_method: event.target[2].value,
      payment_status: 1,
      free_shipping: event.target[3].checked || 0,
    }
    // let response = await Sale.create(obj);
    // if(response.status !== 'error'){
    //   alert('Anúncio cadastrado!')
    //   window.location.href = '/anuncios'
    // } else {
    //   alert('Erro')
    // }
  }

  useEffect(() => {
    loadProductData();
    loadStoreData();
  }, [])

  return (
    <>
      <Header />

      <div className="container pt-5 pb-5 full-height-container">
        <h3>Criar Anuncio</h3>

        <div className="row d-flex justify-content-center">
          <div className="col-6">
            <form onSubmit={submitAuction}>
              <div className="form-group">
                <label>Item</label>
                <input className="form-control" value={product.name} disabled />
              </div>
              <div className="form-group">
                <label>Nome do leiloeiro</label>
                <input className="form-control" value={user.first_name} disabled />
              </div>
              {/* <div className="form-group">
                <label>Preço inicial</label>
                <MaskedInput
                  required
                  mask={numberMask}
                  className="contact form-control"
                  placeholder="Insira o valor aqui"
                />
              </div> */}
              <div className="form-group">
                <label>Método de pagamento</label>
                <select required className="form-control" placeholder="Método de pagamento">
                  <option value={1}>Dinheiro</option>
                  <option value={2}>Cartão de Crédito</option>
                  <option value={3}>Cartão de Débito</option>
                  <option value={4}>Boleto</option>
                  <option value={5}>Paypal</option>
                  <option value={6}>MercadoPago</option>
                </select>
              </div>
              <div className="custom-control custom-checkbox mb-3">
                <input type="checkbox" className="custom-control-input" id="freeShipping" />
                <label className="custom-control-label" for="freeShipping">Entrega grátis</label>
              </div>
              <button type="submit" className="btn btn-primary">Salvar</button>
            </form>
          </div>
        </div>

      </div>
    </>
  )
}

export default NewSale;
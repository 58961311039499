import './App.css';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import Root from './router';

import { store, persistor } from './redux/store';
import LOGO from './assets/logo2-horizontal.png';


function App() {
  return window.location.href === 'https://crei01.zeleiloes.com/' ? (
    <div style={{
      position: 'fixed',
      zIndex: 10,
      backgroundColor: 'white',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: 25,
      fontWeight: '600'
    }}>
      <img src={LOGO} style={{ width: '50%' }} />
      Estamos chegando...
    </div>
  ) : (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Root />
      </PersistGate>
    </Provider>
  );
}

export default App;

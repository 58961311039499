import React, { useState, useEffect } from 'react';
import { Header } from '../../components';
import {
  Modal
} from 'react-bootstrap';
import './style.css';
import {Sale, Store } from '../../api';
import './style.css';
import { FaTrash } from 'react-icons/fa';
import { useAlert } from "react-alert";

const SalesScreen = () => {
  const alert = useAlert();
  const [sales, setSales] = useState([]);
  const [selected, setSelected] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);

  const loadData = async (id) => {
    let response = await Sale.getAllFromStore(id);
    if (response.status !== 'error') {
      setSales(response);
    }
  }

  const loadStoreData = async () => {
    let response = await Store.getData();
    if (response.status !== 'error') {
      try{
        loadData(response[0].id)
      } catch(e) {}
    }
  }

  useEffect(() => {
    loadStoreData();
  }, [])

  const handleDelete = (item) => {
    setSelected(item);
    setShowModal(true);
  }

  const confirmDelete = async () => {
    let response = await Sale.delete(selected.id);
    if (response.status !== 'error') {
      loadData();
    } else {
      alert.error('Erro')
    }
    setShowModal(false);
  }

  return (
    <>
      <Header />

      <div className="container pt-5 pb-5 full-height-container">
        <h3>Anúncios ativos</h3>

        <Modal show={showModal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Tem certeza que deseja excluir o anúncio?</Modal.Title>
          </Modal.Header>
          {/* <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body> */}
          <Modal.Footer>
            <div className="btn btn-secondary" onClick={handleClose}>
              Cancelar
            </div>
            <div className="btn btn-primary" onClick={confirmDelete}>
              Excluir
            </div>
          </Modal.Footer>
        </Modal>

        <div className="row">
          {
            sales.map((item, index) => (
              <div key={index} className="card mr-3 mb-3" style={{ width: "18rem" }}>
                <button onClick={() => handleDelete(item)} className="btn btn-danger auction-delete">
                  <FaTrash />
                </button>
                <img className="card-img-top"
                  src={item.products.product_media.length > 0
                  ? `https://bigu.digital/zeleiloes-plataforma-backend/public/storage/products/${item.products.product_media[0].url}`
                  : "https://socialistmodernism.com/wp-content/uploads/2017/07/placeholder-image.png"}
                  alt={item.products.name}
                />
                <div className="card-body">
                  <h5 className="card-title text-truncate">{item.products.name}</h5>
                  <p className="card-subtitle">{item.products.description}</p>
                </div>
              </div>
            ))
          }
        </div>

        {
          sales.length === 0 ? (
            <div style={{ textAlign: 'center' }}>
              Não há anúncios cadastrados
            </div>
          ) : null
        }
      </div>
    </>
  )
}

export default SalesScreen;
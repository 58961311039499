import dayjs from 'dayjs';
import React, { useState, useEffect } from 'react';
import { FaShoppingCart } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { Sale } from '../../api';
import { ProfileLeft } from '../../components';

const MyShop = () => {
  const user = useSelector((state) => state.user.data);

  const [list, setList] = useState([])

  const loadData = async () => {
    let response = await Sale.getAll(user.id)
    if (response.status !== 'error') {
      console.log(response)
      setList(response)
    }
  }

  useEffect(() => {
    if (user.id) {
      loadData()
    }
  }, [user.id])

  return (
    <div className="row pl-3 pr-3 mt-5">
      <ProfileLeft />

      <div className="col-9">
        <div className="row align-items-center">
          <FaShoppingCart className="ml-3 mt-1" size={35} />
          <h3 className="col m-0">Minhas Compras</h3>
        </div>

        <hr />
        <div className="list-group">
          {
            list.map((item, index) => (
              <div key={index} className="btn text-left list-group-item mt-2">
                <div className="row border rounded align-items-center">
                  <div className="col-7"> 
                    <img 
                    src={item.products.product_media[0] ?`https://bigu.digital/zeleiloes-plataforma-backend/public/storage/products/${item.products.product_media[0].url}` :
                    "https://socialistmodernism.com/wp-content/uploads/2017/07/placeholder-image.png"} style={{ width: '100%' }}  />
                  </div>
                  <div className="col-5">
                    <h5>{item.products.name}</h5>
                    {dayjs(item.created).format('DD/MM/YYYY HH:mm')}
                    {/* <p>
                        {item.products.description}
                      </p> */}
                  </div>
                </div>
              </div>
            ))
          }
        </div>
        

        {/* <div className="row align-items-center">
          <div className="btn btn-info ml-3 mt-1 pt-1">
            <FaUser />
          </div>
          <h5 className="col-8 m-0">Dados Cadastrais</h5>
        </div>

        <div className="col-12 mt-3 mb-5">
          <h6>CPF/CNPJ: {user.cpf}</h6>
          <h6>E-mail: {user.email}</h6>
          <h6>Nome: {user.first_name} {user.last_name}</h6>
        </div> */}

      </div>
    </div>
  )
}

export default MyShop;
import * as types from '../actions';

const initialState = {
  data: {},
  token: null,
  language: null
};

const UserReducers = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_TOKEN:
      return { ...state, token: action.token };
    case types.SET_USER_DATA:
      return { ...state, data: action.data };
    case types.SET_LANGUAGE:
      return { ...state, language: action.language };
    default:
      return state;
  }
};

export default UserReducers;

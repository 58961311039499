import dayjs from 'dayjs';
import React, { useState, useEffect } from 'react';
import { FaGavel } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { Auction } from '../../api';
import { ProfileLeft } from '../../components';

const MyBids = () => {
  const user = useSelector((state) => state.user.data);
  const [bids, setBids] = useState([])

  const loadData = async () => {
    let response = await Auction.getBids(user.id);
    if (response.status !== 'error') {
      setBids(response);
    }
  }

  useEffect(() => {
    loadData()
  }, [])

  return (
    <div className="row pl-3 pr-3 mt-5">
      <ProfileLeft />

      <div className="col-9">
        <div className="row align-items-center">
          <FaGavel className="ml-3 mt-1" size={35} />
          <h3 className="col m-0">Meus Lances</h3>
        </div>

        <hr />

        <div className="list-group">
          {
            bids.map((item, index) => (
              <div key={index} className="list-group-item">
                <div className="row">
                  <div className="col-10">
                    <h5>R$ {parseFloat(item.bid).toFixed(2).replace('.', ',')}</h5>
                    <p>
                      {item.products.name}
                    </p>
                  </div>
                  <div className="col-2">
                    <p>
                      {dayjs(item.bid_date).format('DD/MM/YYYY')}
                    </p>
                  </div>
                </div>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  )
}

export default MyBids;
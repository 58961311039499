import React, { useEffect } from 'react';

import LogoHeader from '../../assets/logo1-horizontal-transparente.png';
import LogoSmall from '../../assets/logo-transparente.png';

import {
  FaUserCircle, FaSignOutAlt, FaHome,
  FaComment, FaQuestionCircle, FaEnvelope, FaCalendar, FaGavel, FaRss, FaUserLock, FaTag
} from 'react-icons/fa';

import {
  Container, Col, Media, Dropdown, InputGroup, FormControl,
  Navbar, Nav, NavDropdown
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from 'react-redux';
import { User } from '../../api';

import {
  // eslint-disable-next-line no-unused-vars
  Link
} from 'react-router-dom';

import './style.css';
import themeContext from '../../../src/utils/theme';
import { setToken, setUserData } from '../../redux/actions/user';

const Header = () => {
  const dispatch = useDispatch();

  const isLoggedIn = useSelector((state) => state.user.token);
  const user = useSelector((state) => state.user.data);

  const logout = () => {
    dispatch(setToken(null));
    dispatch(setUserData({}));
    setTimeout(() => {
      window.location.assign("home")
    }, 500);
  }

  const loadData = async () => {

    /*
     * Assure that the current user data will always be updated globally
     */

    if (user.id) {
      let response = await User.getData();
      if (response.status !== 'error') {
        dispatch(setUserData(response));
        if (response.role > 0 && window.location.pathname !== '/novo-cadastro') {
          if (response.has_store === 0)
            window.location.assign("novo-cadastro")
        }
      }
    }
  }

  useEffect(() => {
    loadData();
  }, [])


  return (
    <Navbar className="navbar-expand-small"
      collapseOnSelect
      style={{ backgroundColor: window.location.hostname === 'teste.zeleiloes.com' ? themeContext.AZUL_DARK : 'rgb(253, 89, 31)' }}
      expand="lg">
      <Container className="navbar-large">
        <Navbar.Brand>
          <Media>
            {
              window.location.hostname === 'teste.zeleiloes.com' ? (
                <a href="/" className="ml-n4 mr-3">
                  <img
                    src={LogoSmall}
                    className="logo-small"
                    alt="Leilões BR"
                  />
                </a>
              ) : null
            }
          </Media>
        </Navbar.Brand>

        <Col xs={9}>
          <nav id="menu">
            <ul>
              <li>
                <a href="/">
                  <FaHome className="mr-2 mb-1" size={11} />
                  Home
                </a>
              </li>
              <li>
                <a href="/FAQ">
                  <FaQuestionCircle className="mr-2 mb-1" size={11} />
                  FAQ
                </a>
              </li>
              <li hidden>
                <a href="#">
                  Ajuda
                </a>
              </li>
              <li>
                <a href="/contato">
                  <FaEnvelope className="mr-2 mb-1" size={11} />
                  Contato
                </a>
              </li>
              <li>
                <a href="/">
                  <FaGavel className="mr-2 mb-1" size={11} />
                  Novos Leilões
                </a>
              </li>
              <li>
                <a href="https://blog.zeleiloes.com/">
                  <FaRss className="mr-2 mb-1" size={11} />
                  Blog
                </a>
              </li>
              {
                user.role === 2 ? (
                  <li>
                    <a href="/admin">
                      <FaUserLock className="mr-2 mb-1" size={11} />
                      Admin
                    </a>
                  </li>
                ) : null
              }
              {
                user.role > 0 ? (
                  <li>
                    <a href="/minha-loja">
                      <FaTag className="mr-2 mb-1" size={11} />
                      Minha loja
                    </a>
                  </li>
                ) : null
              }
            </ul>
          </nav>
        </Col>

        {
          isLoggedIn ? (
            <>
              <Col xs={3}>
                <span>
                  <span style={{ margin: '0 5px' }}>
                    <Link to="/perfil" style={{ color: '#cccc34' }}>
                      Minha conta
                    </Link>
                  </span>
                  <FaUserCircle size={21} style={{ color: '#cccc34' }} />
                  <span onClick={logout} style={{ paddingLeft: '20px' }}>
                    <Link href="#" style={{ color: '#cccc34' }}>
                      <span style={{ margin: '0', paddingRight: '7px' }}>
                        Sair
                      </span>
                      <FaSignOutAlt />
                    </Link>
                  </span>
                </span>
              </Col>
            </>
          ) : (
            <p className="admin-options">
              <a
                href="/autenticacao#login"
                style={{ color: themeContext.YELLOW_DARK }}
              >
                Faça login
              </a>
              <span style={{ color: themeContext.WHITE }}>
                {' '}ou{' '}
              </span>
              <a href="/autenticacao#cadastro" style={{ color: themeContext.YELLOW_DARK }}
              >
                Cadastre-se
              </a>
            </p>
          )
        }
      </Container >

      <Navbar.Collapse className="responsive-navbar">
        <Nav>
          <Navbar.Brand>
            <Media>
              <img
                src={window.location.hostname === 'teste.zeleiloes.com' ? LogoHeader : 'https://bigu.digital/images/bigu-v2-white.png'}
                alt="Leilões BR"
              />
            </Media>
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link href="home">Home</Nav.Link>
              {
                isLoggedIn ? (
                  <>
                    <Nav.Link href="/perfil">Minha Conta</Nav.Link>
                    <Nav.Link href="#" onClick={logout}>Logout</Nav.Link>

                  </>
                ) : (
                  <>
                    <Nav.Link href="/autenticacao#login">Login</Nav.Link>
                    <Nav.Link href="/autenticacao#cadastro">Cadastre-se</Nav.Link>
                  </>
                )
              }
              <NavDropdown.Divider />
              <Nav.Link href="#">Enviar leilão</Nav.Link>
              <Nav.Link href="#">Acompanhar leilão</Nav.Link>
              <NavDropdown.Divider />
              <Nav.Link href="/FAQ">FAQ</Nav.Link>
              <Nav.Link href="#">Fale conosco</Nav.Link>
            </Nav>
          </Navbar.Collapse>

        </Nav>
      </Navbar.Collapse >

    </Navbar >
  )
}

export default Header;
import React, { useState, useEffect } from "react";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import { store } from "../redux/store";
import { useDispatch } from 'react-redux';
import { setUserData } from '../redux/actions/user';

import Home from "../screens/home";
import Sobre from "../screens/sobre";
import Vantagens from "../screens/vantagens";
import Contact from "../screens/contact";
import LoadingScreen from "../screens/loadingScreen";
import ProductDetails from "../screens/productDetails/index";
import ProductPurchase from "../screens/productPurchase";
import Auth from "../screens/auth";
import CreateAccount2 from "../screens/auth/CreateAccount/CreateAccount2";
import Profile from "../screens/profile";
import RecoveryPassword from "../screens/auth/RecoveryPassword/RecoveryPassword";
import Auctioneer from "../screens/auctioneer";
import AuctionsScreen from "../screens/auctions";
import NewAuction from "../screens/auctions/new";
import ProductsScreen from "../screens/products";
import NewProduct from "../screens/products/new";
import EditProduct from "../screens/products/edit";
import AuctioneersScreen from "../screens/actioneers";
import Search from "../screens/search";
import CreateBid from "../screens/createBid";
import Bid from '../screens/bid';
import MyStore from "../screens/myStore";
import Admin from "../screens/admin";
import AdminAuctioneers from "../screens/admin/auctioneers";
import AdminProducts from "../screens/admin/products";
import AdminUsers from "../screens/admin/users";
import SalesScreen from "../screens/sales";
import NewSale from "../screens/sales/new";
import BuyandSell from "../screens/buyandsell";
import ListCatalog from '../screens/catalog';
import Invitation from '../screens/invitationCard';
import Catalog from "../screens/catalog/listCatalog";
import TermsConditions from "../screens/termsConditions";
import PrivacyPolicy from "../screens/privacypolicy";
import SendoConfirmationEmail from "../screens/auth/SendConfirmationEmail/SendConfirmationEmail";

const ROUTES_ADMIN = [
  { path: "/admin", component: <Admin /> },
  { path: "/admin/usuarios", component: <AdminUsers /> },
  { path: "/admin/produtos", component: <AdminProducts /> },
  { path: "/admin/leiloeiros", component: <AdminAuctioneers /> },
]

// ROUTES TO BE USED FOR WHITELABEL, WHEN USER IS LOGGED IN
const ROUTES_CUSTOM_LOGGED = [
  { path: "/home", component: <Home /> },
  { path: "/FAQ", component: <Sobre /> },
  { path: "/contato", component: <Contact /> },
  { path: "/vantagens", component: <Vantagens /> },
  { path: "/produtos", component: <ProductsScreen /> },
  { path: "/comocomprar", component: <BuyandSell /> },
  { path: "/novo-produto", component: <NewProduct /> },
  { path: "/editprod/:id", component: <EditProduct /> },
  { path: "/leiloes", component: <AuctionsScreen /> },
  { path: "/novo-leilao", component: <NewAuction /> },
  { path: "/anuncios", component: <SalesScreen /> },
  { path: "/novo-anuncio", component: <NewSale /> },
  { path: "/perfil", component: <Profile /> },
  { path: "/busca", component: <Search /> },
  // { path: "/novo-lance", component: <CreateBid /> },
  { path: "/minha-loja", component: <MyStore /> },
  { path: "/peca", component: <ProductDetails /> },
  { path: "/compra", component: <ProductPurchase /> },
  { path: "/aovivo", component: <Bid /> },
  { path: "/termosecondicoes", component: <TermsConditions/>},
  { path: "/politicadeprivacidade", component: <PrivacyPolicy/>},
  { path: "/findByAuction", component: <Home/>},
  { path: "/fetchByActioneer", component: <Home/>},
  { path: "/findByState", component: <Home/>}
]

// ROUTES TO BE USED FOR WHITELABEL, WHEN USER IS NOT LOGGED IN
const ROUTES_CUSTOM = [
  { path: "/", component: <Auctioneer /> },
  { path: "/autenticacao", component: <Auth /> },
  { path: "/recuperar-senha", component: <RecoveryPassword /> },
  { path: "/auth", component: <Auth /> },
  { path: "/busca", component: <Search /> },
  { path: "/peca", component: <ProductDetails /> },
  { path: "/FAQ", component: <Sobre /> },
  { path: "/vantagens", component: <Vantagens /> },
  { path: "/contato", component: <Contact /> },
  { path: "/comocomprar", component: <BuyandSell /> },
  { path: "/termosecondicoes", component: <TermsConditions/>},
  { path: "/politicadeprivacidade", component: <PrivacyPolicy/>},
  { path: "/findByAuction", component: <Home/>},
  { path: "/fetchByActioneer", component: <Home/>},
  { path: "/findByState", component: <Home/>}
]

// ROUTES TO BE USED FOR THE DEFAULT WEBSITE (ZE LEILOES), WHEN USER IS LOGGED IN
const ROUTES_DEFAULT_LOGGED = [
  { path: "/perfil", component: <Profile /> },
  { path: "/peca", component: <ProductDetails /> },
  { path: "/produtos", component: <ProductsScreen /> },
  { path: "/vantagens", component: <Vantagens /> },
  { path: "/novo-produto", component: <NewProduct /> },
  { path: "/editprod/:id", component: <EditProduct /> },
  { path: "/leiloes", component: <AuctionsScreen /> },
  { path: "/novo-leilao", component: <NewAuction /> },
  { path: "/anuncios", component: <SalesScreen /> },
  { path: "/novo-anuncio", component: <NewSale /> },
  { path: "/novo-cadastro", component: <CreateAccount2 /> },
  // { path: "/novo-lance", component: <CreateBid /> },
  { path: "/minha-loja", component: <MyStore /> },
  { path: "/FAQ", component: <Sobre /> },
  { path: "/contato", component: <Contact /> },
  { path: "/comocomprar", component: <BuyandSell /> },
  { path: "/compra", component: <ProductPurchase /> },
  { path: "/aovivo", component: <Bid /> },
  { path: "/termosecondicoes", component: <TermsConditions/>},
  { path: "/politicadeprivacidade", component: <PrivacyPolicy/>},
  { path: "/findByAuction", component: <Home/>},
  { path: "/fetchByActioneer", component: <Home/>},
  { path: "/findByState", component: <Home/>}
]

// ROUTES TO BE USED FOR THE DEFAULT WEBSITE (ZE LEILOES), WHEN USER IS NOT LOGGED IN
const ROUTES_DEFAULT = [
  { path: "/autenticacao", component: <Auth /> },
  { path: "/auth", component: <Auth /> },
  { path: "/perfil", component: <Profile /> },
  { path: "/recuperar-senha", component: <RecoveryPassword /> },
  { path: "/email-de-confirmacao", component: <SendoConfirmationEmail /> },
  { path: "/peca", component: <ProductDetails /> },
  { path: "/FAQ", component: <Sobre /> },
  { path: "/vantagens", component: <Vantagens /> },
  { path: "/contato", component: <Contact /> },
  { path: "/comocomprar", component: <BuyandSell /> },
  { path: "/aovivo", component: <Bid /> },
  { path: "/termosecondicoes", component: <TermsConditions/>},
  { path: "/politicadeprivacidade", component: <PrivacyPolicy/>},
  { path: "/findByAuction", component: <Home/>},
  { path: "/fetchByActioneer", component: <Home/>},
  { path: "/findByState", component: <Home/>}
]

export default function Root() {
  const dispatch = useDispatch();

  const [contentType, setContentType] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (window.location.hostname === 'teste.zeleiloes.com') {
      setContentType(1);
      // dispatch(setUserData({
      //   theme: {
      //     YELLOW_DARK: '#cccc34',
      //     AZUL_DARK: '#1d4d74',
      //     GREY: '#cad3d0',
      //     YELLOW_LIGTH: '#dbdb6b',
      //     BLUE_LIGTH: '#6c8ca4',
      //     GREEN: '#a0d064',
      //     ORANGE: '#e88c64',
      //     WHITE: '#ffffff'
      //   },
      //   logo_name: 'https://teste.zeleiloes.com/static/media/logo1-horizontal-transparente.1f591076.png',
      //   logo_square: 'https://teste.zeleiloes.com/static/media/logo1-horizontal-transparente.1f591076.png',
      // }));
      setLoading(false);
    } else {
      setContentType(1);
      // dispatch(setUserData(response.token));
      setLoading(false);
    }
  }, [])

  const renderCustomRoutes = () => {
    return (
      <Router>
        <Switch>
          <Route path="/leiloeiros" exact>
            <AuctioneersScreen />
          </Route>
          <Route path="/leiloeiro/:id/" exact>
            <Auctioneer />
          </Route>
          {
            store.getState().user.token ?
              (
                <Switch>
                  {
                    store.getState().user.data.role === 2 && ROUTES_ADMIN.map((item, index) => (
                      <Route key={index} path={item.path} exact>
                        {item.component}
                      </Route>
                    ))
                  }
                  {
                    ROUTES_CUSTOM_LOGGED.map((item, index) => (
                      <Route key={index} path={item.path} exact>
                        {item.component}
                      </Route>
                    ))
                  }
                  <Route path="*">
                    <Redirect to="/home" />
                  </Route>
                </Switch>
              ) : (
                <Switch>
                  {
                    ROUTES_CUSTOM.map((item, index) => (
                      <Route key={index} path={item.path} exact>
                        {item.component}
                      </Route>
                    ))
                  }
                  <Route path="*">
                    <Redirect to="/" />
                  </Route>
                </Switch>
              )
          }
        </Switch>
      </Router>
    )
  }

  const renderRegularRoutes = () => {
    return (
      <Router>
        <Switch>

          <Route path="/" exact>
            <Home />
          </Route>
          <Route path="/leiloeiros" exact>
            <AuctioneersScreen />
          </Route>
          <Route path="/leiloeiro/:id/" exact>
            <Auctioneer />
          </Route>
          <Route path="/leiloeiro/:id/listacatalogo" exact>
            <ListCatalog/>
          </Route>
          <Route path="/leiloeiro/:id/catalogo" exact>
            <Catalog/>
          </Route>
          <Route path="/leiloeiro/:id/convite" exact>
            <Invitation/>
          </Route>
          <Route path="/busca" exact>
            <Search />
          </Route>

          {
            store.getState().user.token ?
              (
                <Switch>
                  {
                    store.getState().user.data.role === 2 && ROUTES_ADMIN.map((item, index) => (
                      <Route key={index} path={item.path} exact>
                        {item.component}
                      </Route>
                    ))
                  }
                  {
                    ROUTES_DEFAULT_LOGGED.map((item, index) => (
                      <Route key={index} path={item.path} exact>
                        {item.component}
                      </Route>
                    ))
                  }
                  <Route path="*">
                    <Redirect to="/" />
                  </Route>
                </Switch>
              ) : (
                <Switch>
                  {
                    ROUTES_DEFAULT.map((item, index) => (
                      <Route key={index} path={item.path} exact>
                        {item.component}
                      </Route>
                    ))
                  }
                  <Route path="*">
                    <Redirect to="/" />
                  </Route>
                </Switch>
              )
          }

        </Switch>

      </Router>
    )
  }

  return loading ? <LoadingScreen /> : contentType === 1 ? renderRegularRoutes() : renderCustomRoutes()
}

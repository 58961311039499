import React, { useEffect, useState } from 'react';

import {
    Container, Row, Col, Dropdown, InputGroup, Carousel, Button, Card, Badge, Form
  } from 'react-bootstrap';

import './styles.css';

import LogoZe from '../../assets/logo2-transparente.png';
import Slide1 from '../../assets/logo2-horizontal.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRss, faCog, faHome, faMousePointer, faSignOutAlt, faReply, faGavel, faHandPaper } from "@fortawesome/free-solid-svg-icons";



const Bid = () => {

    const [mudarAlerta, setMudarAlerta] = useState(0)
    const [mudarmsgLance, mudarMsgLance] = useState(0)

    useEffect(() => {
        setTimeout(function(){setMudarAlerta(1)},5000 )
        setTimeout(function(){setMudarAlerta(2)},8000 )
        setTimeout(function(){setMudarAlerta(3)},9000 )
        setTimeout(function(){setMudarAlerta(4)},10000 )
        setTimeout(function(){mudarMsgLance(1)},2000 )
        setTimeout(function(){mudarMsgLance(2)},4000 )
        setTimeout(function(){mudarMsgLance(3)},6000 )
        setTimeout(function(){mudarMsgLance(4)},8000 )

      }, []);
    return (
        <Container className="py-4">
            <Row>
                <Col md={8} className="column">
                    <section>
                        <header className="headerAjuste">
                            <Row>
                                <Col md={12}>
                                    <div className="presencial-title">
                                        <h1>
                                            <a href="#" title="Marcio Pinho - Leiloeiro Público" target="_blank">
                                                <img className="headerimg" src={LogoZe}/>
                                            </a>
                                        </h1>
                                        <h2>
                                        <span class="title-numleilao">Leilão <b>21864</b> - Marcio Pinho Pereira - JUCERJA 192</span>
                                        66º Leilão Memorabilia, Numismática, Objetos, Coleções e Curiosidades <br/>
                                        <span class="title-autorizado">ELIANA ALVARENGA - RJ - Tel: 21 2537-3717 / 21 99923-9208</span>
                                        </h2>

                                        <div className="log-box">
                                            <div className="log-box-wrap">
                                                <span className="is-titlelogin">
                                                    <a className="icon" href="#" target="_blank" data-tippy="Fazer login ou cadastro" alt="Fazer login ou cadastro">
                                                        <FontAwesomeIcon icon={faSignOutAlt}/>
                                                    </a>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </header>
                    </section>

                    <section id="infoprod-presencial">
                        <Row>
                            <Col md={12}>
                                <div className="nome-1">
                                    <h2>Lote: <span id="lote">113</span></h2>
                                </div>

                                <ul class="presencial-tools">
                                    <li class="is-voltacatalogo">
                                        <a href="../catalogo.asp?Num=21864" data-tippy="Ir ao catálogo" alt="Ir ao catálogo">
                                            <FontAwesomeIcon icon={faReply}/>
                                        </a>
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <div class="descricao-text">
                                    <p>
                                        <span id="Descricao" class="hide-for-small">CARTÃO POSTAL - Navio Fascination não circulado . - <b>VALOR INICIAL:</b> R$10,00</span>
                                    </p>
                                </div>
                            </Col>
                        </Row>

                        <Row className="py-3">
                            <Col md={12}>
                                <Carousel controls={true} indicators={false} interval={3000} className="borderWhite">
                                    <Carousel.Item>
                                        <div className="d-flex justify-content-center">
                                            <img
                                            className="w-100"
                                            src={Slide1}
                                            alt="First slide"
                                            />
                                        </div>
                                    </Carousel.Item>
                                </Carousel>
                            </Col>
                        </Row>

                        <Row>
                        <div id="proxlote" class="hide-for-small">
                            <Col md={12}>
                                <div class="section-title">
                                    <h2><span>Próximos Lotes</span></h2>
                                </div>
                            </Col>
                            <ul>
                                <li class="columns">
                                    <div class="proxlote-imgwrap">
                                        <img class="imagem2" src="https://socialistmodernism.com/wp-content/uploads/2017/07/placeholder-image.png" title=""/>
                                    </div>
                                </li>
                                <li class="columns">
                                    <div class="proxlote-imgwrap">
                                        <img class="imagem3" src="https://socialistmodernism.com/wp-content/uploads/2017/07/placeholder-image.png" title=""/>
                                    </div>
                                </li>
                                <li class="columns">
                                    <div class="proxlote-imgwrap">
                                        <img class="imagem4" src="https://socialistmodernism.com/wp-content/uploads/2017/07/placeholder-image.png" title=""/>
                                    </div>
                                </li>
                                <li class="columns">
                                    <div class="proxlote-imgwrap">
                                        <img class="imagem5" src="https://socialistmodernism.com/wp-content/uploads/2017/07/placeholder-image.png" title=""/>
                                    </div>
                                </li>
                                <li class="columns lastitem">
                                    <div class="proxlote-imgwrap">
                                        <img class="imagem6" src="https://socialistmodernism.com/wp-content/uploads/2017/07/placeholder-image.png" title=""/>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        </Row>

                        <Row>
                            <Col md={12}>
                            <a href="#" target="_blank" style={{lineHeight:'50px', float:'right', color:'#757575'}}>Powered by 
                                <img className="rodapeimg" src={LogoZe} width="100"/>

                            </a>
                            </Col>
                        </Row>
                    </section>
                </Col>

                <Col md={4}>
                    <div class="gray-wrap hide-for-small">
                        <div class="termometro">
                            <div style={{height: '31px', border: '1px solid #ddd', background: '#fff', width: '100%'}}></div>
                            <div class="pb-pct" style={{width: '44%', background: '#1d4d74'}}></div>
                            <p className='font-weight-bold'>Peça nº <span class="valor1">170</span> de <span class="valor2">384</span> (<span class="valperc">44</span>% Completo)</p>
                        </div>
                    </div>

                        <div className="spacetry">
                            <div id="info-lance" className="gray-wrap">
                                {
                                    mudarmsgLance === 0 ? (
                                        <div id="mensagens-leilao">
                                            <ul>
                                                <li class="leilao-semlance">
                                                    <h3 class="nome-3">Lance vencedor Atual</h3>
                                                    <p>
                                                        <span class="valor">R$ 80,00 - <span className="internet">Internet</span> - 30****</span>
                                                    </p>
                                                </li>
                                            </ul>
                                            <ul style={{padding: '10px'}}>
                                                <li class="">
                                                    <p>
                                                        <span class="">R$ 70,00 - <span className="internet">Internet</span> - 55****</span>
                                                    </p>
                                                </li>
                                                <li class="">
                                                    <p>
                                                        <span class="">R$ 60,00 - <span className="internet">Internet</span> - 87****</span>
                                                    </p>
                                                </li>
                                                <li class="">
                                                    <p>
                                                        <span class="">R$ 50,00 - <span className="internet">Internet</span> - 12****</span>
                                                    </p>
                                                </li>
                                                <li class="">
                                                    <p>
                                                        <span class="">R$ 40,00 - <span className="internet">Internet</span> - 10****</span>
                                                    </p>
                                                </li>
                                            </ul>
                                        </div>
                                    ) : null
                                }

{
                                    mudarmsgLance === 1 ? (
                                        <div id="mensagens-leilao">
                                            <ul>
                                                <li class="leilao-semlance">
                                                    <h3 class="nome-3">Lance vencedor Atual</h3>
                                                    <p>
                                                        <span class="valor">R$ 10,00 - <span className="internet">Internet</span> - 30****</span>
                                                    </p>
                                                </li>
                                            </ul>
                                            <ul style={{padding: '10px'}}>
                                                <li class="">
                                                    <p>
                                                        <span class="">R$ 5,00 - <span className="internet">Internet</span> - 55****</span>
                                                    </p>
                                                </li>
                                                <li class="">
                                                    <p>
                                                        <span class="">R$ 3,00 - <span className="internet">Internet</span> - 87****</span>
                                                    </p>
                                                </li>
                                                <li class="">
                                                    <p>
                                                        <span class="">R$ 2,00 - <span className="internet">Internet</span> - 12****</span>
                                                    </p>
                                                </li>
                                                <li class="">
                                                    <p>
                                                        <span class="">R$ 1,00 - <span className="internet">Internet</span> - 10****</span>
                                                    </p>
                                                </li>
                                            </ul>
                                        </div>
                                    ) : null
                                }

                                {
                                    mudarmsgLance === 2 ? (
                                        <div id="mensagens-leilao">
                                            <ul>
                                                <li class="leilao-semlance">
                                                    <h3 class="nome-3">Lance vencedor Atual</h3>
                                                    <p>
                                                        <span class="valor">R$ 1000,00 - <span className="internet">Internet</span> - 30****</span>
                                                    </p>
                                                </li>
                                            </ul>
                                            <ul style={{padding: '10px'}}>
                                                <li class="">
                                                    <p>
                                                        <span class="">R$ 800,00 - <span className="internet">Internet</span> - 55****</span>
                                                    </p>
                                                </li>
                                                <li class="">
                                                    <p>
                                                        <span class="">R$ 500,00 - <span className="internet">Internet</span> - 87****</span>
                                                    </p>
                                                </li>
                                                <li class="">
                                                    <p>
                                                        <span class="">R$ 200,00 - <span className="internet">Internet</span> - 12****</span>
                                                    </p>
                                                </li>
                                                <li class="">
                                                    <p>
                                                        <span class="">R$ 100,00 - <span className="internet">Internet</span> - 10****</span>
                                                    </p>
                                                </li>
                                            </ul>
                                        </div>
                                    ) : null
                                }

                                { 
                                    mudarmsgLance === 3 ? (
                                        <div id="mensagens-leilao">
                                            <ul>
                                                <li class="leilao-semlance">
                                                    <h3 class="nome-3">Lance vencedor Atual</h3>
                                                    <p>
                                                        <span class="valor">R$ 200,00 - <span className="internet">Internet</span> - 30****</span>
                                                    </p>
                                                </li>
                                            </ul>
                                            <ul style={{padding: '10px'}}>
                                                <li class="">
                                                    <p>
                                                        <span class="">R$ 100,00 - <span className="internet">Internet</span> - 55****</span>
                                                    </p>
                                                </li>
                                                <li class="">
                                                    <p>
                                                        <span class="">R$ 60,00 - <span className="internet">Internet</span> - 87****</span>
                                                    </p>
                                                </li>
                                                <li class="">
                                                    <p>
                                                        <span class="">R$ 50,00 - <span className="internet">Internet</span> - 12****</span>
                                                    </p>
                                                </li>
                                                <li class="">
                                                    <p>
                                                        <span class="">R$ 20,00 - <span className="internet">Internet</span> - 10****</span>
                                                    </p>
                                                </li>
                                            </ul>
                                        </div>
                                    ) : null
                                }

                                {
                                    mudarmsgLance === 4 ? (
                                        <div id="mensagens-leilao">
                                            <ul>
                                                <li class="leilao-semlance">
                                                    <h3 class="nome-3">Lance vencedor Atual</h3>
                                                    <p>
                                                        <span class="valor">R$ 100,00 - <span className="internet">Internet</span> - 30****</span>
                                                    </p>
                                                </li>
                                            </ul>
                                            <ul style={{padding: '10px'}}>
                                                <li class="">
                                                    <p>
                                                        <span class="">R$ 90,00 - <span className="internet">Internet</span> - 55****</span>
                                                    </p>
                                                </li>
                                                <li class="">
                                                    <p>
                                                        <span class="">R$ 80,00 - <span className="internet">Internet</span> - 87****</span>
                                                    </p>
                                                </li>
                                                <li class="">
                                                    <p>
                                                        <span class="">R$ 20,00 - <span className="internet">Internet</span> - 12****</span>
                                                    </p>
                                                </li>
                                                <li class="">
                                                    <p>
                                                        <span class="">R$ 10,00 - <span className="internet">Internet</span> - 10****</span>
                                                    </p>
                                                </li>
                                            </ul>
                                        </div>
                                    ) : null
                                }
                            </div>

                            <div className="zero-wrap">

                                {
                                    mudarAlerta === 0 ? (<div id="statusinicio" class="alerta inicio" style={{display: 'block'}}>
                                    <div class="section-title">
                                        <h2><span><FontAwesomeIcon icon={faGavel}/>  Alerta do Leiloeiro</span></h2>
                                    </div>
                                    <p>Nenhum alerta ativo...</p>
                                    </div>) : null
                                }

                                {
                                    mudarAlerta === 1 ? (
                                        <div id="statusvermelho" class="alerta vermelho">
                                        <div class="section-title">
                                            <h2><span><FontAwesomeIcon icon={faGavel}/>  Alerta do Leiloeiro</span></h2>
                                        </div>
                                        <p style={{color: 'white'}}><FontAwesomeIcon icon={faHandPaper} className=" mx-2"/>Lote Prestes a Fechar,<br/>Lance Rápido</p>
                                        </div>
                                    ) : null
                                }

                                {
                                    mudarAlerta === 2 ? (
                                    <div id="statuslaranja" class="alerta laranja">
                                        <div class="section-title">
                                            <h2><span><FontAwesomeIcon icon={faGavel}/>  Alerta do Leiloeiro</span></h2>
                                        </div>
                                        <p style={{color: 'white'}}><FontAwesomeIcon icon={faGavel} className=""/><span id="msgstatuslaranja"></span></p>
                                    </div>
                                    ) : null
                                }

                                {
                                    mudarAlerta === 3 ? (
                                    <div id="statusazul" class="alerta azul">
                                        <div class="section-title">
                                            <h2><span><FontAwesomeIcon icon={faGavel}/>  Alerta do Leiloeiro</span></h2>
                                        </div>
                                        <p style={{color: 'white'}} id="msgstatusazul">Aviso Azul</p>
                                    </div>
                                    ) : null
                                }

                                {
                                    mudarAlerta === 4 ? (
                                        <div id="statuscinza" class="alerta cinza">
                                            <div class="section-title">
                                                <h2><span><FontAwesomeIcon icon={faGavel}/>  Alerta do Leiloeiro</span></h2>
                                            </div>
                                        <p style={{color: 'white'}}>Lote Vendido</p>
                                        </div>
                                    ) : null
                                }
                            </div>
                        </div>

                        <div className="gray-wrap">
                            <div class="section-title">
                                <h2>
                                    <span>
                                        <FontAwesomeIcon icon={faGavel}/> Lance
                                    </span>
                                </h2>
                            </div>
                            <div class="lance naologado">
                                <h3 class="nome-3 hide-for-small">
                                <i class="fa fa-user"></i> 
                                <span class="title-numleilao">Cartela --</span>
                                Usuário não logado
                                </h3>
                                <p><a href="../login_site.asp" target="_parent"><i class="fa fa-sign-in"></i> Faça seu login para lançar</a></p>
                            </div>
                        </div>
                </Col>
            </Row>
        </Container>
    )

}


export default Bid;
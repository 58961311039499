import API from './config';

const Product = {
  getAll: async () => {
    try {
      let response = await API().get(`/products/fetch/all`);
      return await response.data;
    } catch (e) {
      return { status: 'error', message: 'Something is wrong.' };
    }
  },
  search: async (query) => {
    try {
      let response = await API().get(`/products/fetch/findByQuery${query}`);
      return await response.data;
    } catch (e) {
      return { status: 'error', message: 'Something is wrong.' };
    }
  },
  getById: async (id) => {
    try {
      let response = await API().get(`/products/${id}`);
      return await response.data;
    } catch (e) {
      return { status: 'error', message: 'Something is wrong.' };
    }
  },
  getCategories: async () => {
    try {
      let response = await API().get(`/productCategories`);
      return await response.data;
    } catch (e) {
      return { status: 'error', message: 'Something is wrong.' };
    }
  },
  getCategoryById: async (id) => {
    try {
      let response = await API().get(`/productCategories/${id}`);
      return await response.data;
    } catch (e) {
      return { status: 'error', message: 'Something is wrong.' };
    }
  },
  getTypes: async () => {
    try {
      let response = await API().get(`/productTypes`);
      return await response.data;
    } catch (e) {
      return { status: 'error', message: 'Something is wrong.' };
    }
  },
  getTypeById: async (id) => {
    try {
      let response = await API().get(`/productTypes/${id}`);
      return await response.data;
    } catch (e) {
      return { status: 'error', message: 'Something is wrong.' };
    }
  },
  getRandom: async (quantity, id) => {
    try {
      let response = await API().get(`/products/fetch/carroussel/${quantity}${id ? `/${id}` : ""}`);
      return await response.data;
    } catch (e) {
      return { status: 'error', message: 'Something is wrong.' };
    }
  },
  uploadMedia: async (params) => {
    try {
      let response = await API().post(`/productsMedia`, params, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });
      return await response.data;
    } catch (e) {
      return { status: 'error', message: 'Something is wrong.' };
    }
  },
  deleteMedia: async (id) => {
    try {
      let response = await API().delete(`/productsMedia/delete/${id}`)
      return await response.data;
    } catch(e) {
      return { status: 'error', message: 'Something is wrong.' };
    }
  },
  getMedia: async(productId) => {
    try {
      let response = await API().get(`productsMedia/fetch/findByProduct/${productId}`)
      return await response.data;
    } catch(e) {
      return { status: 'error', message: 'Something is wrong.' };
    }
  },
  create: async (params) => {
    try {
      let response = await API().post(`/products`, params);
      return await response.data;
    } catch (e) {
      return { status: 'error', message: 'Something is wrong.' };
    }
  },
  delete: async (id) => {
    try {
      let response = await API().delete(`/products/${id}`);
      return await response.data;
    } catch (e) {
      return { status: 'error', message: 'Something is wrong.' };
    }
  },
  update: async (params) => {
    try {
      let response = await API().put(`/products/${params.id}`, params);
      return await response.data;
    } catch (e) {
      return { status: 'error', message: 'Something is wrong.' };
    }
  },
  deleteCategory: async (id) => {
    try {
      let response = await API().delete(`/productCategories/${id}`);
      return await response.data;
    } catch (e) {
      return { status: 'error', message: 'Something is wrong.' };
    }
  },
  deleteType: async (id) => {
    try {
      let response = await API().delete(`/productTypes/${id}`);
      return await response.data;
    } catch (e) {
      return { status: 'error', message: 'Something is wrong.' };
    }
  },
  newCategory: async (params) => {
    try {
      let response = await API().post(`/productCategories`, params);
      return await response.data;
    } catch (e) {
      return { status: 'error', message: 'Something is wrong.' };
    }
  },
  newType: async (params) => {
    try {
      let response = await API().post(`/productTypes`, params);
      return await response.data;
    } catch (e) {
      return { status: 'error', message: 'Something is wrong.' };
    }
  },
  addToWatchlist: async (params) => {
    try {
      let response = await API().post(`/watchList`, params);
      return await response.data;
    } catch (e) {
      return { status: 'error', message: 'Something is wrong.' };
    }
  },
  getWatchlist: async () => {
    try {
      let response = await API().get(`/user/watch-list/fetch/fromUser`);
      return await response.data;
    } catch (e) {
      return { status: 'error', message: 'Something is wrong.' };
    }
  },
  deleteWatchlist: async (id) => {
    try {
      let response = await API().delete(`watchList/${id}`);
      return await response.data;
    } catch (e) {
      return { status: 'error', message: 'Something is wrong.' };
    }
  },
  addToFavorites: async (params) => {
    try {
      let response = await API().post(`/favorites`, params);
      return await response.data;
    } catch (e) {
      return { status: 'error', message: 'Something is wrong.' };
    }
  },
  getFavorites: async () => {
    try {
      let response = await API().get(`/user/favorites/fetch/fromUser`);
      return await response.data;
    } catch (e) {
      return { status: 'error', message: 'Something is wrong.' };
    }
  },
  deleteFavorites: async (id) => {
    try {
      let response = await API().delete(`favorites/${id}`);
      return await response.data;
    } catch (e) {
      return { status: 'error', message: 'Something is wrong.' };
    }
  }
}

export default Product;
import API from './config';

const Sale = {
  getAll: async () => {
    try {
      let response = await API().get(`/sales/fetch/findByUser`);
      return await response.data;
    } catch (e) {
      return { status: 'error', message: 'Something is wrong.' };
    }
  },
  getAllFromStore: async (id) => {
    try {
      let response = await API().get(`/sales/fetch/findByStore/${id}`);
      return await response.data;
    } catch (e) {
      return { status: 'error', message: 'Something is wrong.' };
    }
  },
  buy: async (params) => {
    try {
      let response = await API().post(`/sales`, params);
      return await response.data;
    } catch (e) {
      return { status: 'error', message: 'Something is wrong.' };
    }
  },
  delete: async (id) => {
    try {
      let response = await API().delete(`/sales/${id}`);
      return await response.data;
    } catch (e) {
      return { status: 'error', message: 'Something is wrong.' };
    }
  },
}

export default Sale;
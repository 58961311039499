import React from 'react';
import { FaAt } from 'react-icons/fa';
import { useSelector, useDispatch } from 'react-redux';
import { User } from '../../api';
import { ProfileLeft } from '../../components';
import { useAlert, positions } from "react-alert";
import { setToken, setUserData } from '../../redux/actions/user';

const EditEmail = () => {
  const alert = useAlert();
  const user = useSelector((state) => state.user.data);
  const dispatch = useDispatch();

  const logout = () => {
    dispatch(setToken(null));
    dispatch(setUserData({}));
    setTimeout(() => {
      window.location.assign("/autenticacao?verify=1#login")
    }, 500);
  }

  const updateUser = async (event) => {
    event.preventDefault();

    let obj = {
      id: user.id,
      new_email: event.target[1].value,
      old_email: user.email
    }

    if(event.target[1].value !== event.target[2].value){
      alert.error('Email de confirmação diferente', { position: positions.TOP_RIGHT });
      return false;
    }

    let response = await User.updateEmail(obj, user.id);
    if(response.status !== 'error') {
      alert.success('Email Atualizado')
      logout()

      // alert('Email atualizado!');
      // window.location.assign('/perfil')
    } else {
      alert.error("Erro! O Email " + event.target[1].value + " já possui cadastro. Tente outro Email!", { position: positions.TOP_RIGHT });
    }
  }

  return (
    <div className="row pl-3 pr-3 mt-5">
      <ProfileLeft />

      <div className="col-9">
        <div className="row align-items-center">
          <FaAt className="ml-3 mt-1" size={35} />
          <h3 className="col m-0">Alterar E-mail</h3>
        </div>

        <hr />

        <form onSubmit={updateUser} className="col-12 mt-3 mb-5 pl-0 pr-0">
          <div className="form-group has-feedback">
            <label>Seu e-mail:</label>
            <input
              required
              disabled
              value={user.email}
              className="contact form-control"
              placeholder="Digite seu email" />
          </div>
          <div className="form-group has-feedback">
            <label>Novo e-mail:</label>
            <input
              required
              type="email"
              className="contact form-control"
              placeholder="Digite seu email" />
          </div>
          <div className="form-group has-feedback">
            <label>Confirme o novo e-mail:</label>
            <input
              required
              type="email"
              className="contact form-control"
              placeholder="Digite seu email" />
          </div>

          <button className="btn btn-info" type="submit">
            Atualizar
          </button>
        </form>

      </div>
    </div>
  )
}

export default EditEmail;
import React, { useEffect, useState } from 'react';

import LogoHeader from '../../../assets/logo1-horizontal-transparente.png';

import {
  FaUserCircle,
  FaSignOutAlt
} from 'react-icons/fa';

import {
  Container, Col, Media, Dropdown, InputGroup, FormControl,
  Navbar, Nav, NavDropdown
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from 'react-redux';
import { User, Product } from '../../../api';
// import categories from '../../../utils/categories';
import Select from 'react-select'

import {
  // eslint-disable-next-line no-unused-vars
  Link
} from 'react-router-dom';

import './style.css';
import themeContext from '../../../../src/utils/theme';
import { setToken, setUserData } from '../../../redux/actions/user';

const HeaderWithSearch = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.user.token);

  const [searchValue, setSearchValue] = useState("");
  const [selectedSearch, setSelectedSearch] = useState(1);
  const [categories, setCategories] = useState([]);

  const loadCategories = async () => {
    let response = await Product.getCategories()
    if (response.status !== 'error') {
      let aux = []
      response.forEach((item) => {
        aux.push({
          ...item,
          label: item.name
        })
      })
      setCategories(aux)
    }
  }

  const logout = () => {
    dispatch(setToken(null));
    dispatch(setUserData({}));
    setTimeout(() => {
      window.location.assign("home")
    }, 500);
  }

  const loadData = async () => {

    /*
     * Assure that the current user data will always be updated globally
     */

    let response = await User.getData();
    if (response.status !== 'error') {
      dispatch(setUserData(response));
      if (response.role === 1 && window.location.pathname !== '/novo-cadastro') {
        if (response.has_store === 0)
          window.location.assign("novo-cadastro")
      }
    }
  }

  useEffect(() => {
    loadData();
    loadCategories();
  }, [])

  const goToSearch = (e) => {
    try {
      e.preventDefault()
    } catch (error) { }
    window.location.assign(`/busca?name=${searchValue}&typeId=${selectedSearch}`)
  }

  return (
    <Navbar collapseOnSelect style={{ backgroundColor: themeContext.AZUL_DARK }} expand="lg">
      <Container className="navbar-large">
        <Col xs={2} className="ml-n4">
          <Navbar.Brand>
            <Media>
              <a href="/">
                <img
                  src={window.location.hostname === 'teste.zeleiloes.com' ? LogoHeader : 'https://bigu.digital/images/bigu-v2-white.png'}
                  className="img-fluid"
                  alt="Leilões BR"
                />
              </a>
            </Media>
          </Navbar.Brand>
        </Col>


        <Col xs={5}>
          <form onSubmit={goToSearch}>
            <InputGroup style={{ backgroundColor: 'white', borderRadius: 5 }}>
              <input
                style={{ color: 'black', border: 'none', borderColor: 'none' }}
                className="form-control"
                placeholder="Pesquise por itens ou categorias"
                aria-label="Pesquise por itens ou categorias"
                aria-describedby="basic-addon2"
                onChange={(event) => setSearchValue(event.target.value)}
              />

              <Dropdown as={InputGroup.Append}>
                <Dropdown.Toggle variant="outline-secondary" style={{ backgroundColor: 'rgba(32, 78, 115, 0.5)', border: 'none', color: 'white' }}>
                  {
                    selectedSearch === 1 ? "Leilões Ativos" :
                      selectedSearch === 2 ? "Últimas Publicações" :
                        selectedSearch === 3 ? "Leilões Pós Pregão" : "Leilões Finalizados"
                  }
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => setSelectedSearch(1)}>Leilões Ativos</Dropdown.Item>
                  <Dropdown.Item onClick={() => setSelectedSearch(2)}>Últimas Publicações</Dropdown.Item>
                  <Dropdown.Item onClick={() => setSelectedSearch(3)}>Leilões Pós Pregão</Dropdown.Item>
                  <Dropdown.Item onClick={() => setSelectedSearch(4)}>Leilões Finalizados</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              <InputGroup.Append onClick={goToSearch}>
                <InputGroup.Text className="btn" style={{ color: 'white', border: 'none', backgroundColor: 'rgba(32, 78, 115, 0.5)' }} >
                  <FontAwesomeIcon icon={faSearch} />
                </InputGroup.Text>
              </InputGroup.Append>

            </InputGroup>
          </form>
        </Col>

        <Col xs={3} className="mr-n3">
          <Select
            classNamePrefix="categories-select"
            placeholder="Produtos por Categorias"
            options={categories}
            onChange={(event) => {
              window.location.href = `/busca?categoryId=${event.id}`
            }}
          />
        </Col>
      </Container >

    </Navbar >
  )
}

export default HeaderWithSearch;
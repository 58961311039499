import React, { useState, useEffect } from 'react';

import {
  FaAt, FaEnvelope, FaEye, FaGavel, FaLock, FaShoppingCart, FaStar, FaTags, FaThLarge, FaUser
} from 'react-icons/fa';

import {Header } from '../../components';
import './style.css';
import { useSelector } from 'react-redux';

import MyResume from './myResume';
import MyData from './myData';
import EditEmail from './editEmail';
import EditPassword from './editpassword';
import MyBids from './myBids';
import MyProducts from './myProducts';
import MyShop from './myShop';
import MyFavorites from './myFavorites';
import MyWatches from './myWatches';
import MyMessages from './myMessages';

const MENU_OPTIONS = [
  { title: 'Meu Resumo', icon: <FaThLarge />, url: '#meu-resumo' },
  { title: 'Meus Dados', icon: <FaUser />, url: '#meus-dados' },
  { title: 'Alterar Email', icon: <FaAt />, url: '#editar-email' },
  { title: 'Alterar Senha', icon: <FaLock />, url: '#editar-senha' },
  { title: 'Meus Lances', icon: <FaGavel />, url: '#meus-lances' },
  { title: 'Minhas Peças', icon: <FaTags />, url: '#minhas-pecas' },
  { title: 'Minhas Compras', icon: <FaShoppingCart />, url: '#minhas-compras' },
  { title: 'Meus Favoritos', icon: <FaStar />, url: '#meus-favoritos' },
  { title: 'Minhas Vigias', icon: <FaEye />, url: '#minhas-vigias' },
  { title: 'Minhas Mensagens', icon: <FaEnvelope />, url: '#minhas-mensagens' },
]

const Profile = () => {

  const user = useSelector((state) => state.user.data);

  const [currentPath, setCurrentPath] = useState('#meu-resumo');

  const listenToHashChange = () => {
    /*
     * Check the window URL and set the form to be rendered according to the page type
     */
    const currentWindow = window.location.hash;
    setCurrentPath(window.location.hash);
  };

  useEffect(() => {
    listenToHashChange();
    window.addEventListener("hashchange", () => listenToHashChange(), false);
    return () => {
      window.removeEventListener("hashchange", () => listenToHashChange());
    };
  }, [])

  const renderScreen = () => {
    switch (currentPath) {
      case '#meu-resumo':
        return <MyResume />
        break;
      case '#meus-dados':
        return <MyData />
        break;
      case '#editar-email':
        return <EditEmail />
        break;
      case '#editar-senha':
        return <EditPassword />
        break;
      case '#meus-lances':
        return <MyBids />
        break;
      case '#minhas-pecas':
        return <MyProducts />
        break;
      case '#minhas-compras':
        return <MyShop />
        break;
      case '#meus-favoritos':
        return <MyFavorites />
        break;
      case '#minhas-vigias':
        return <MyWatches />
        break;
      case '#minhas-mensagens':
        return <MyMessages />
        break;

      default:
        window.location = "#meu-resumo";
        setCurrentPath('#meu-resumo')
        return null;
        break;
    }
  }

  return (
    <div className="is-contasite">
      <Header />

      <div className="container mt-3 full-height-container mb-5">

        <h3 className="mb-4">Bem-vindo, {user.first_name}</h3>

        <div className="row justify-content-between m-0">
          {/* btn-outline-info */}
          {
            MENU_OPTIONS.map((item, index) => (
              <a key={index}
                onClick={() => {
                  setCurrentPath(item.url)
                }}
                href={item.url}
                className={`col-lg-1 col-6 text-center btn ${currentPath === item.url ? "btn-outline-info" : "btn-light"}`}
              >
                <div className="pb-2">
                  {item.icon}
                </div>
                <h6>{item.title}</h6>
              </a>
            ))
          }
        </div>

        {renderScreen()}

      </div>
    </div>
  )
}


export default Profile;
import React, { useEffect, useState } from 'react';
import { FaGavel, FaTags, FaUsers, FaTrash } from 'react-icons/fa';
import { Product, Store, User } from '../../api';
import { Footer, Header } from '../../components';
import { Modal } from 'react-bootstrap';
import { useAlert, positions, types } from "react-alert";

import './style.css';

const MENU_OPTIONS = [
  { title: 'Usuários', icon: <FaUsers size={40} />, url: '/admin/usuarios' },
  { title: 'Leiloeiros', icon: <FaGavel size={40} />, url: '/admin/leiloeiros' },
  { title: 'Produtos', icon: <FaTags size={40} />, url: '/admin/produtos' },
]


const Admin = () => {

  const [users, setUsers] = useState([])
  const [stores, setStores] = useState([])
  const [products, setProducts] = useState([])
  const [categories, setCategories] = useState([])
  const [types, setTypes] = useState([])
  const [selected, setSelected] = useState([]);
  const [newItem, setNewItem] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [showNewModal, setShowNewModal] = useState(false);
  const alert = useAlert();

  const handleClose = () => setShowModal(false);
  const handleCloseNew = () => setShowNewModal(false);

  const loadUsers = async () => {
    let response = await User.getAllUsers();
    if (response.status !== 'error') {
      setUsers(response)
    }
  }

  const loadStores = async () => {
    let response = await Store.getAll();
    if (response.status !== 'error') {
      setStores(response)
    }
  }

  const loadProducts = async () => {
    let response = await Product.getAll();
    if (response.status !== 'error') {
      setProducts(response)
    }
  }

  const loadCategories = async () => {
    let response = await Product.getCategories();
    if (response.status !== 'error') {
      setCategories(response)
    }
  }

  const loadTypes = async () => {
    let response = await Product.getTypes();
    if (response.status !== 'error') {
      setTypes(response)
    }
  }

  useEffect(() => {
    loadUsers();
    loadStores();
    loadProducts();
    loadCategories();
    loadTypes();
  }, [])


  const handleDelete = (item, type) => {
    setSelected({ item, type });
    setShowModal(true);
  }

  const confirmDelete = async () => {
    if (selected.type === "category") {
      let response = await Product.deleteCategory(selected.item.id);
      if (response.status !== 'error') {
        loadCategories();
      } else {
        alert.error('Erro')
      }
    } else {
      let response = await Product.deleteType(selected.item.id);
      if (response.status !== 'error') {
        loadTypes();
      } else {
        alert.error('Erro')
      }
    }
    setShowModal(false);
  }

  const requestNew = async () => {
    if (newItem === 0) {
      let response = await Product.newCategory({ name: document.getElementById('newInput').value })
      if (response.status !== 'error') {
        loadCategories()
      } else {
        alert.error('erro')
      }
    } else if (newItem === 1) {
      let response = await Product.newType({ name: document.getElementById('newInput').value })
      if (response.status !== 'error') {
        loadTypes()
      } else {
        alert.error('erro')
      }
    }
    setShowNewModal(false)
  }

  return (
    <>
      <Header />

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Tem certeza que deseja exvluir {selected.type === 'category' ? "a categoria selecionada" : "o tipo selecionado"}?</Modal.Title>
        </Modal.Header>
        {/* <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body> */}
        <Modal.Footer>
          <div className="btn btn-secondary" onClick={handleClose}>
            Cancelar
            </div>
          <div className="btn btn-primary" onClick={confirmDelete}>
            Excluir
            </div>
        </Modal.Footer>
      </Modal>

      <Modal show={showNewModal} onHide={handleCloseNew}>
        <Modal.Header closeButton>
          <Modal.Title>Insira o nome {newItem === 0 ? "da nova categoria" : "do novo tipo"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input className="form-control" id="newInput" />
        </Modal.Body>

        <Modal.Footer>
          <div className="btn btn-secondary" onClick={handleCloseNew}>
            Cancelar
            </div>
          <div className="btn btn-primary" onClick={requestNew}>
            Salvar
            </div>
        </Modal.Footer>
      </Modal>

      <div className="container mt-3">
        <h2>Painel Admin</h2>
        <div className="row mx-1 mt-3 justify-content-between">
          {
            MENU_OPTIONS.map((item, index) => (
              <a key={index}
                href={item.url}
                className="text-center admin-btn btn btn-outline-info m-auto"
              >
                <div className="pb-2">
                  {item.icon}
                </div>
                <h4 className="text-info admin-info-btn-label">Ver {item.title}</h4>
              </a>
            ))
          }
        </div>

        <div className="row mx-1 mt-3 pb-5 justify-content-between">
          <div className="card col-lg-2 col-6" >
            <div className="card-body">
              <h1 className="card-title">{users.length}</h1>
              <p className="card-text">Usuários</p>
            </div>
          </div>

          <div className="card col-lg-2 col-6" >
            <div className="card-body">
              <h1 className="card-title">{stores.length}</h1>
              <p className="card-text">Leiloeiros</p>
            </div>
          </div>

          <div className="card col-lg-2 col-6" >
            <div className="card-body">
              <h1 className="card-title">{products.length}</h1>
              <p className="card-text">Produtos</p>
            </div>
          </div>

          <div className="card col-lg-2 col-6" >
            <div className="card-body">
              <h1 className="card-title">{0}</h1>
              <p className="card-text">Produtos leiloados</p>
            </div>
          </div>

          <div className="card col-lg-2 col-6" >
            <div className="card-body">
              <h1 className="card-title">{0}</h1>
              <p className="card-text">Produtos vendidos</p>
            </div>
          </div>
        </div>

        <div className="list-group mt-3 mb-5">
          <div className="row justify-content-between mb-3 mx-1">
            <h3>Categorias de produtos:</h3>
            <button
              onClick={() => {
                setNewItem(0)
                setShowNewModal(true)
              }}
              className="btn btn-primary">Nova Categoria</button>
          </div>
          {
            categories.map((item, index) => (
              <div key={index} className="list-group-item">
                <div className="row">
                  <div className="col-11">
                    <h5>{item.name}</h5>
                  </div>
                  <div className="col-1 d-flex align-items-center">
                    <button onClick={() => handleDelete(item, 'category')} className="btn btn-danger" >
                      <FaTrash />
                    </button>
                  </div>

                </div>
              </div>
            ))
          }
        </div>

        <div className="list-group mt-3 mb-5">
          <div className="row justify-content-between mb-3 mx-1">
            <h3>Tipos de produtos:</h3>
            <button
              onClick={() => {
                setNewItem(1)
                setShowNewModal(true)
              }}
              className="btn btn-primary">Novo Tipo</button>
          </div>
          {
            types.map((item, index) => (
              <div key={index} className="list-group-item">
                <div className="row">
                  <div className="col-11">
                    <h5>{item.name}</h5>
                  </div>
                  <div className="col-1 d-flex align-items-center">
                    <button onClick={() => handleDelete(item, "type")} className="btn btn-danger" >
                      <FaTrash />
                    </button>
                  </div>

                </div>
              </div>
            ))
          }
        </div>

      </div>
    </>
  )
}

export default Admin;
import axios from 'axios';
import API from './config';

const User = {
  signIn: async (params) => {
    try {
      let response = await API().post(`/user/login`, params);
      return await response.data;
    } catch (e) {
      return e.response.data;
    }
  },
  signUp: async (params) => {
    return API().post(`/user/register`, params)
      .then(async (response) => {
        return await response.data;
      })
      .catch((error) => {
        return { status: 'error', message: error.response.data };
      })
  },
  getData: async () => {
    try {
      let response = await API().get(`/user`);
      return await response.data;
    } catch (e) {
      return { status: 'error', message: 'Something is wrong.' };
    }
  },
  getAllUsers: async () => {
    try {
      let response = await API().get(`/user/getAll`);
      return await response.data;
    } catch (e) {
      return { status: 'error', message: 'Something is wrong.' };
    }
  },
  update: async (params) => {
    try {
      let response = await API().put(`/user/${params.id}`, params);
      return await response.data;
    } catch (e) {
      return { status: 'error', message: 'Something is wrong.' };
    }
  },
  updateEmail: async (params) => {
    try {
      let response = await API().put(`/user/updateEmail/${params.id}`, params);
      return await response.data;
    } catch (e) {
      return { status: 'error', message: 'Something is wrong.' };
    }
  },
  delete: async (id) => {
    try {
      let response = await API().delete(`/user/${id}`);
      return await response.data;
    } catch (e) {
      return { status: 'error', message: 'Something is wrong.' };
    }
  },
  requestNewPassword: async (email) => {
    try {
      let response = await axios.post(`https://bigu.digital/zeleiloes-plataforma-backend/public/api/password/email`, { email });
      return await response.data;
    } catch (e) {
      return { status: 'error', message: 'Something is wrong.' };
    }
  },
  requestNewEmailConfirmation: async(email) => {
    try {
      let response = await axios.post(`https://bigu.digital/zeleiloes-plataforma-backend/public/api/email/verify/resend`, { email });
      return await response.data;
    } catch (e) {
      return { status: 'error', message: 'Something is wrong.' };
    }
  },
  cep: async(cep) => {
    try {
      let response = await axios.get(`https://viacep.com.br/ws/${cep}/json`)
      return await response.data;
    } catch (e) {
      return { status: 'error', message: 'Something is wrong.' }
    }
  },
  getAllEstados: async() => {
    try {
      let response = await axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados?orderBy=nome`)
      return await response.data;
    } catch (e) {
      return { status: 'error', message: 'Something is wrong.' }
    }
  }
}

export default User;

import React, { useEffect, useState } from 'react';
import {
  Container, Row, Col, Table
} from 'react-bootstrap';

import { Link } from 'react-router-dom';
import { faRss, faCog } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './style.css';
import { Header } from '../../../components';
import themeContext from '../../../utils/theme';
import { Store, User } from '../../../api';

const AdminAuctioneers = () => {

  const [auctioneers, setAuctioneers] = useState([]);

  const loadData = async () => {
    let response = await Store.getAll();
    if (response.status !== 'error') {
      setAuctioneers(response);
    }
  }

  useEffect(() => {
    loadData();
  }, [])

  return (
    <div>
      <Header />
      <Container fluid="md" className="mt-5 full-height-container">
        <h4>Leiloeiros</h4>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Nome Fantasia</th>
              <th>CNPJ</th>
              <th>Usuário</th>
            </tr>
          </thead>
          <tbody>
            {
              auctioneers.map((item, index) => (
                <tr key={index}>
                  <td>{item.id}</td>
                  <td>{item.fantasy_name}</td>
                  <td>{item.cnpj}</td>
                  <td>{item.user_id}</td>
                </tr>
              ))
            }
          </tbody>
        </Table>
        {
          auctioneers.length === 0 ? (
            <div style={{ textAlign: 'center' }}>
              Não há leiloeiros cadastrados
            </div>
          ) : null
        }

      </Container>
    </div>
  )
}

export default AdminAuctioneers;
import React, { useState, useEffect } from 'react';
import { Footer, Header } from '../../components';
import theme from '../../utils/theme';
import { Store } from '../../api';
import './style.css';

const AuctioneersScreen = () => {
  const [auctioneers, setAuctioneers] = useState([]);

  const loadData = async () => {
    let response = await Store.getAll();
    if (response.status !== 'error') {
      setAuctioneers(response);
    }
  }

  useEffect(() => {
    loadData();
  }, [])

  return (
    <>
      <Header />

      <div className="container pt-5 pb-5 full-height-container">
        <h3>Nossos Leiloeiros</h3>

        <div className="row">
          {
            auctioneers.map((item, index) => (
              <a key={index} className="card mr-3 auctioneers-card-link" href={`/leiloeiro/${item.id}`} style={{ width: "18rem" }}>
                <img className="card-img-top"
                  src={
                    item.img_url ? `https://bigu.digital/zeleiloes-plataforma-backend/public/storage/stores/${item.img_url}` :
                      "https://awlights.com/wp-content/uploads/sites/31/2017/05/placeholder_featured_image.svg"
                  }
                  alt="Card image cap"
                />
                <div className="card-body">
                  <span className="badge badge-pill badge-primary mt-1 mb-1 ml-0">Ativo</span>
                  <h5 className="card-title">{item.fantasy_name}</h5>
                  <p className="card-text text-truncate">
                    {item.cnpj}
                  </p>
                </div>
              </a>
            ))
          }
        </div>

        {
          auctioneers.length === 0 ? (
            <div style={{ textAlign: 'center' }}>
              Não há leiloeiros cadastrados
            </div>
          ) : null
        }
      </div>
    </>
  )
}

export default AuctioneersScreen;
import React from 'react';
import {Container, Row, Col, Dropdown, InputGroup, Carousel, Button, Card, Badge, Form} from 'react-bootstrap';
import { faRss, faCog, faBars, faLock, faTools } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './styles.css';

const Admin = () => {
    return (
        <div className="is-footeraux-wrap">
            <Container>
                <Row>
                    <div className="mq-faux">
                        <div className="col-xs-4">
                            <h4>
                                <FontAwesomeIcon icon={faTools} className="mx-1"/>
                                Zé Leilões:
                            </h4>
                            <ul>
                                <li>
                                    <a href="clientes.asp">Clientes</a>
                                </li>
                                <li>
                                    <a href="contato.asp">Contato</a>
                                </li>
                            </ul>
                        </div>

                        <div class="col-xs-4">
                            <h4>Para arrematantes:</h4>
                                <ul>
                                    <li><a href="/comocomprar">Como Comprar / Vender</a></li>
                                    <li><a href="#">Últimos leilões realizados</a></li>
                                </ul>
                        </div>

                        <div class="col-xs-4">
                            <h4>Leiloeiros e Galerias:</h4>
                            <ul>
                                <li><a href="/vantagens">Vantagens e Recursos</a></li>
                                <li><a href="#">Venda no Zé Leilões (obter plataforma)</a></li>
                            </ul>
                        </div>
                    </div>
                </Row>
            </Container>
        </div>
    )
}

export default Admin;
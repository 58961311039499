import React from 'react';

const LoadingScreen = () => {
  return (
    <div style={{ height: '100vh' }}>
      <div className="h-100 d-flex justify-content-center align-items-center">
        <div className="spinner-border"></div>
      </div>
    </div>
  )
}

export default LoadingScreen;
import React, {useState} from 'react';
import { useAlert, positions, types } from "react-alert";
import './style.css';

import { User, Store, Product, Auction} from '../../api';


const FutureAuctionsFooter = () => {

  const alert = useAlert();

  const getNewsletter = async(event) => {
    event.preventDefault();
    console.log("entrou")

    let form = {
      email: event.target[0].value
    }

    let response = await fetch('https://bigu.digital/zeleiloes-plataforma-backend/public/api/newsletter/add', {
      method: 'POST',
      body: JSON.stringify(form)
    })

    if(response.ok) {
      alert.success('Email cadastrado com sucesso')
    } else {
      alert.error('Erro')
    }
  }

  return (
    <div className="row future-auctions-footer mt-5 d-flex justify-content-center">
      <img hidden className="col-4 mobile-none"/>
      <div className="col-8 mt-3 d-flex flex-column justify-content-center">
        <h4>Quer saber sobre os próximos leilões?</h4>
        <p>
          Preencha o campo abaixo e receba a agenda dos nossos próximos leilões diariamente.
        </p>
        <form className="input-group mb-3" onSubmit={getNewsletter}>
          <div class="input-group mb-3">
            <input required type="email" className="form-control" placeholder="Digite seu email" />
            <div class="input-group-append">
              <button type="submit" className="btn btn-warning">
                Cadastrar
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default FutureAuctionsFooter;
import React, { useState, useEffect } from 'react';
import { FaStar } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { Product } from '../../api';
import { ProfileLeft } from '../../components';

const MyFavorites = () => {
  const user = useSelector((state) => state.user.data);

  const [type, setType] = useState(0);
  const [list, setList] = useState([]);


  const loadData = async () => {
    let response = await Product.getFavorites()
    if (response.status !== 'error') {
      console.log(response);
      setList(response)
    }
  }

  useEffect(() => {
    loadData()
  }, [])

  return (
    <div className="row pl-3 pr-3 mt-5">
      <ProfileLeft />

      <div className="col-9">
        <div className="row align-items-center">
          <FaStar className="ml-3 mt-1" size={35} />
          <h3 className="col m-0">Meus Favoritos</h3>
        </div>

        <hr />

        <div className="row">
          <h5 className="col-9 m-0">Meus favoritos - Leilões
            <b style={{ fontSize: 17.5 }}>
              {type === 0 ? " Ativos" : " Finalizados"}
            </b>
          </h5>
          <h6 onClick={() => setType(type === 0 ? 1 : 0)} className="col-3 text-right btn btn-link p-0 pr-3">Ver Leilões{type === 1 ? " Ativos" : " Finalizados"}</h6>
        </div>

        {
          list.map((item, index) => (
            <a href={`/peca?id=${item.products.id}`} key={index} className="btn text-left list-group-item mt-2">
              <div className="row align-items-center">
                <div className="col-6">
                  <img src={item.products.product_media[0] ?
                  `https://bigu.digital/zeleiloes-plataforma-backend/public/storage/products/${item.products.product_media[0].url}` :
                  "https://socialistmodernism.com/wp-content/uploads/2017/07/placeholder-image.png"}
                  style={{ width: '100%' }}
                  />
                </div>
                <div className="col-6">
                  <h5>{item.products.name}</h5>
                  {/* <p>
                    {item.products.description}
                  </p> */}
                </div>
                {/* <div className="col-1 d-flex align-items-center">
                  <button onClick={() => handleDelete(item)} className="btn btn-danger" >
                    <FaTrash />
                  </button>
                </div> */}
              </div>
            </a>
          ))
        }

        {
          list.length === 0 && (
            <div className="col-12 mt-3 mb-5 p-0">
              Não existem peças favoritadas em leilões {type === 0 ? "ativos" : "finalizados"}.
            </div>
          )
        }

      </div>
    </div>
  )
}

export default MyFavorites;
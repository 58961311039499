import React from 'react';
import { Link } from 'react-router-dom'
import {
    Container, Row, Col, Button
} from 'react-bootstrap';
import './styles.css';
import { Header, HeaderWithSearch } from '../../components';

import About from '../../assets/about.png';


const Sobre = () => {
    return (
        <div>
            <Header/>
            <HeaderWithSearch/>
                <Container className="py-4">
                    <div>
                        <img
                        className="w-100"
                        src={About}
                        />
                    </div>
                </Container>
                <Container>
                    <Row className="py-2">
                        <Col>
                        <h2 className="TextH2 font-weight-bold">Sobre o Zé Leilões</h2>
                        <p className="textP">Zé leiloes é uma plataforma que reúne os principais mais tradicionais leilões de arte e antiguidades nacionais para a realização leilões através da internet, fornecendo facilidade para os seus usuários e diminuindo distâncias. 
                            A tecnologia que utilizamos possibilita aos organizadores de leilões tornarem as suas propostas acessíveis para  diversas pessoas interessadas e muito mais lances por produto, 
                            tudo isso sem barreiras geográficas, atingindo todo o público nacional, 
                            permitindo que estes participem em tempo real sem sair do conforto de sua casa.
                        </p>
                        <p className="textP">
                        O legal da nossa plataforma é que além dos compradores poderem participar de leilões em todo o país, eles também têm acesso a diversos canais de contato dos organizadores podendo disponibilizar seus bens para avaliação e possível venda em leilões online.
                        Por fim, a nossa plataforma contempla uma série se recursos e ferramentas que tem o intuito de facilitar o processo de organização dos leilões por parte dos fornecedores, em uma plataforma pensada e projetada especialmente para isso. E também pensada para facilitar o processo de acompanhamento e interação por parte dos arrematantes, que são os compradores, de maneira fácil, rápida, intuitiva, fluida, e, mais importante, com a segurança de estar fazendo isso em um espaço confiável, algo fundamental para qualquer operação que envolva investimento financeiro.
                        </p>

                        <h3 className="TextH2 font-weight-bold">Inovação e Tecnologia</h3>
                        <p className="textP">A nossa plataforma e dotada de uma tecnologia voltada para atender tanto o mercado presencial quanto online, sendo o nosso grande diferencial e vantagem a possibilidade de realizar leilões em domínio próprio, o que abre margem para contatos posteriores e a facilidade de acesso via motores de busca como o Google.</p>
                        
                        <h3 className="TextH2 font-weight-bold">Dados Seguros</h3>
                        <p className="textP">Na nossa plataforma, todos os dados trafegam por uma conexão SSL segura! Sua privacidade é garantida.</p>
                        
                        <h3 className="TextH2 font-weight-bold">Agilidade</h3>
                        <p className="textP">Outro importante atributo da nossa plataforma é que a possibilidade dada aos organizadores de leilão, que conseguem de maneira muito rápida e pontual realizar o cadastramento dos produtos, permitindo agilidade nos processos, e celeridade na realização de leilões, que podem ocorrer, inclusive, em maior quantidade do que seria possível na modalidade presencial.
Além disso, no quesito agilidade, há também a facilidade no envio de e-mails de cobrança aos arrematantes e outros recursos para gerenciamento dos participantes.</p>

                        <h3 className="TextH2 font-weight-bold">Divulgação e Publicidade</h3>
                        <p className="textP">Para que as pessoas tenham conhecimento dos leilões em curso, a nossa plataforma utiliza o maior e melhor sistema de Redes e Servidores da Web, com ampla divulgação nos principais canais de mídias online: Google Adwords, Facebook Ads, Twitter, Google Plus, Snapchat e Instagram.
Para a maior capilaridade das informações, há também o trabalho complementar de marketing, que envolve o envio diário de e-mails com a agenda de leilões futuros e leilões do dia para toda base de arrematantes e ainda, envio de mensagem de texto em SMS para todos os arrematantes que registraram lances e/ou selecionaram algum recurso para acompanhar lotes específicos de seu interesse.</p>
                        
                        <h3 className="TextH2 font-weight-bold">Métricas e Controle</h3>
                        <p className="textP">Além disso, a nossa plataforma, para a melhor análise de dados e controle de métricas, fornece aos clientes acesso a várias métricas de desempenho, entre elas, o resultado de vendas, registro de pagamentos recebidos, gerenciamento por comitente, bloqueios entre outros.
Além disso,Zé leiloes fornece serviço (SaaS) dentro da sua plataforma, o que permite escalar ainda mais o negócio e alcançar diversos arrematantes em todo Brasil.</p>
                        </Col>
                    </Row>

                    <Row className="py-2">
                        <Col>
                            <h2 className="TextH2 font-weight-bold">Qual é a sua dúvida?</h2>
                            <p className="textP">Veja a seguir algumas das principais dúvidas dos nossos clientes e receba o esclarecimento que você precisa! </p>

                            <h3 className="TextH2 font-weight-bold">Como participo de um leilão?</h3>
                            <p className="textP">Para você participar de um leilão no papel da pessoa que compra, será necessário fazer o login, clicar no item ou lote do seu interesse e fazer um lance através de um registro. 
                            Se você não possui registro ainda, clique no botão abaixo para começar: <br/>
                            <Button style={{backgroundColor: '#1d4d74', borderColor: '#1d4d74', margin: '5px 0px'}}><Link to="/autenticacao#cadastro" className="TextH2 font-weight-bolder" style={{color: 'white'}}>Registre-se</Link></Button>
                            </p>
                            <p className="textP">
                            É possível fazer lances antes da realização do pregão, porém o lance vencedor é registrado apenas no momento do ao vivo, a data e o horário corretos serão divulgados em cada item ou lote. 
                            Somente nessa data e ao final do processo se decidirá o lance vencedor formalizando o lote ou item como vendido. <br/>
                            <b className="font-weight-bold" style={{color: 'red', fontSize: '17px'}}>ATENÇÃO:</b> <br/> lances efetuados após o pregão poderão ser considerados, mas isso depende de avaliação do leiloeiro. <br/>
                            Se a dúvida persistir pode entrar em contato conosco através do botão abaixo
                            </p>
                            <h2 className="TextH2 font-weight-bold">Como cancelar um lance feito?</h2>
                            <p className="textP">Caso deseja cancelar um lance feita, essa solicitação deverá ser realizada por e-mail.</p>
                            <p className="textP">No corpo do e-mail deve constar: <br/> Qual o leilão, o número de lote, número do seu lance e o motivo do cancelamento. <br/>
                            <b className="font-weight-bold" style={{color: 'red', fontSize: '17px'}}>ATENÇÃO:</b> <br/> O cancelamento só poderá ser solicitado ANTES do pregão e a confirmação do cancelamento realizada pelo leiloeiro. 
                            Se a dúvida persistir pode entrar em contato conosco através do botão abaixo <br/>
                            <Button style={{backgroundColor: '#1d4d74', borderColor: '#1d4d74', margin: '5px 0px'}}><Link to="/contato" className="TextH2 font-weight-bolder" style={{color: 'white'}}>Entre em contato</Link></Button> <br/> 
                            </p>
                            <h2 className="TextH2 font-weight-bold">Como identificar meus dados, lances e compras?</h2>
                            <p className="textP">Para ter acesso aos seus dados, lances e compras dentro do nosso site é necessário fazer o login e clicar em "minha conta".</p>
                            <h2 className="TextH2 font-weight-bold">É possível dar Lance Automático?</h2>
                            <p className="textP">Sim, o lance automático é possível, além disso, ao acioná-lo, você consegue melhores valores. <br/>
                            Escolha um item e coloque um valor máximo que você pagaria por ela e o nosso sistema 
                            fará com que você participe e de preferência ganhe com o menor lance necessário. 
                            E em caso de empate o lance automático sempre vence, você só perde em casos de lances maiores que o seu lance máximo. <br/>
                            Se a dúvida persistir pode entrar em contato conosco através do botão abaixo <br/>
                            <Button style={{backgroundColor: '#1d4d74', borderColor: '#1d4d74', margin: '5px 0px'}}><Link to="/contato" className="TextH2 font-weight-bolder" style={{color: 'white'}}>Entre em contato</Link></Button> <br/> 
                            </p>
                            <h2 className="TextH2 font-weight-bold">Como Vender?</h2>
                            <p className="textP">Se você não quiser comprar peças, e sim vender, para remetê-las para avaliação acesse o link abaixo e 
                            entre em contato com a galeria associada ao nosso site onde você deseja exibir a sua peça. <br/>
                            <Button style={{backgroundColor: '#1d4d74', borderColor: '#1d4d74', margin: '5px 0px'}}><Link to="/contato" className="TextH2 font-weight-bolder" style={{color: 'white'}}>Entre em contato</Link></Button> <br/> 
                            Depois disso, acesse o site da galeria e prossiga com o seguinte passo a passo: <br/>
                            1)	Envie imagens de qualidade de todos os ângulos da peça no formato JPG ou PNG. <br/>
                            2)	Informe se há avarias. <br/>
                            3)	Envie para avaliação. Caso a peça tenha algum documento ou garantia esse deve ser encaminhado junto. <br/>
                            O serviço de avaliação é gratuito. Se der tudo certo, será feita a assinatura de um contrato onde você autoriza a venda do item em leilão. <br/> 
                            Ao término do leilão você receberá o valor correspondente com o desconto da comissão acordada em contrato. <br/>
                            Caso a venda do item não ocorra, você terá a opção de pegá-lo de volta a qualquer momento ou disponibilizá-lo para novo leilão. <br/>
                            <b className="font-weight-bold" style={{color: 'red', fontSize: '17px'}}>ATENÇÃO:</b> <br/>
                            •	Nosso site não se responsabiliza pelas suas negociações com a nossa galeria parceira. <br/>
                            •	O demonstrativo de clientes que utilizam nosso sistema de leilões é apenas informativo, não há vínculos. <br/>
                            •	Todo processo de alienação de bens deve ser efetuado mediante a formalização de Contrato entre as partes. <br/>
                            Se a dúvida persistir pode entrar em contato conosco através do botão abaixo: <br/>
                            <Button style={{backgroundColor: '#1d4d74', borderColor: '#1d4d74', margin: '5px 0px'}}><Link to="/contato" className="TextH2 font-weight-bolder" style={{color: 'white'}}>Entre em contato</Link></Button> <br/> 
                            </p>
                            <h2 className="TextH2 font-weight-bold">Como Comprar um item em leilão?</h2>
                            <p className="textP">
                            Para participar de um leilão e poder comprar um item você deverá ter um cadastro válido e ativo no nosso site. <br/>
                            Se você não possui registro ainda, clique no botão abaixo para começar: <br/>
                            <Button style={{backgroundColor: '#1d4d74', borderColor: '#1d4d74', margin: '5px 0px'}}><Link to="/autenticacao#cadastro" className="TextH2 font-weight-bolder" style={{color: 'white'}}>Registre-se</Link></Button> <br/> 
                            1) Após o preenchimento, você receberá um E-MAIL com LINK para Ativação de seu cadastro. <br/>
                            2) Clique no link recebido para ativar seu cadastro. <br/>
                            3) Pronto, você já está pronto para a sua primeira compra. <br/>
                            </p>
                            <h2 className="TextH2 font-weight-bold">Como efetuar Lances?</h2>
                            <p className="textP">
                            Para realizar um lance, você deve fazer o login e clicar em um item ou lote de seu interesse. <br/>
                            Depois disso selecione a opção “Fazer Lance”. <br/>
                            O andamento do seu lance poderá ser acompanhado na sua área do nosso site, apenas clicando em “Minha Conta”. <br/>

                            <b className="font-weight-bold" style={{color: 'red', fontSize: '17px'}}>ATENÇÃO:</b> <br/>
                            1 – Um Leilão não é a mesma coisa que comércio eletrônico. Todo leilão é obrigatoriamente certificado por um Leiloeiro(a) público oficial; <br/>
                            2 – Antes de registrar seu lance, é fundamental que você leia os “Termos e condições” para entender os procedimentos para retirada /envio e as condições de pagamento;  <br/>
                            Atentando-se que cada leilão possui as regras específicas. <br/>
                            3 – Embora você até possa realizar lances antes da data do pregão, o lance vencedor será validado somente na data e horário informados. <br/>
                            4 – Os lances feitos depois do encerramento do leilão poderão serão aceitos ou não, a critério exclusivo do leiloeiro responsável; <br/>
                            6 – O comprador pagará ao leiloeiro uma comissão legal de 5% sobre o valor final da compra. <br/>
                            </p>
                            <h2 className="TextH2 font-weight-bold">Qual a diferença de leilão para pregão?</h2>
                            <p className="textP">O Leilão se refere a forma de alienação de bens, já o pregão é uma forma de licitação pública, com data e horário definidos, onde é validado a escolha do melhor candidato pelo respectivo leiloeiro(a) responsável. <br/>
                            Se a dúvida persistir pode entrar em contato conosco através do botão abaixo <br/>
                            <Button style={{backgroundColor: '#1d4d74', borderColor: '#1d4d74', margin: '5px 0px'}}><Link to="/contato" className="TextH2 font-weight-bolder" style={{color: 'white'}}>Entre em contato</Link></Button> <br/> 
                            </p>
                            <h2 className="TextH2 font-weight-bold">Como fazer meu próprio leilão online?</h2>
                            <p className="textP">
                            Você quer levar a sua empresa galeria a um número grandes de pessoas interessadas em leilões? <br/>
                            Com o nosso site você se conecta a milhares de arrematantes em todo o Brasil, o que traz movimento e engajamento para os seus leilões, além de ter um certo filtro de compradores e vendedores confiáveis. <br/>
                            Aumente significativamente as suas vendas vencendo as barreiras geográficas e anunciando os seus leilões para os cantos mais remotos do Brasil. <br/>
                            Se a dúvida persistir pode entrar em contato conosco através do botão abaixo <br/>
                            <Button style={{backgroundColor: '#1d4d74', borderColor: '#1d4d74', margin: '5px 0px'}}><Link to="/contato" className="TextH2 font-weight-bolder" style={{color: 'white'}}>Entre em contato</Link></Button> <br/> 
                            <b className="font-weight-bold" style={{color: 'red', fontSize: '17px'}}>ATENÇÃO:</b> <br/>
                            Caso você deseje vender itens individualmente, por favor, entre em contato com alguma das nossas galerias parceiras e solicite uma avaliação a da sua peça. <br/>
                            Se a dúvida persistir pode entrar em contato conosco através do botão abaixo <br/>
                            <Button style={{backgroundColor: '#1d4d74', borderColor: '#1d4d74', margin: '5px 0px'}}><Link to="/contato" className="TextH2 font-weight-bolder" style={{color: 'white'}}>Entre em contato</Link></Button> <br/> 
                            </p>
                            <h2 className="TextH2 font-weight-bold">Como eu altero o meu e-mail?</h2>
                            <p className="textP">
                            1) Faça o login <br/>
                            2) Acesse “Minha Conta” <br/>
                            3) Clique em “Alterar e-mail” <br/>

                            Se a dúvida persistir pode entrar em contato conosco através do botão abaixo <br/>
                            <Button style={{backgroundColor: '#1d4d74', borderColor: '#1d4d74', margin: '5px 0px'}}><Link to="/contato" className="TextH2 font-weight-bolder" style={{color: 'white'}}>Entre em contato</Link></Button> <br/> 
                            </p>
                        </Col>
                    </Row>
                </Container>
        </div>
    )
}


export default Sobre;
import React, { useEffect, useState } from 'react';
import { FaPhone, FaEnvelope, FaSearch, FaUserCircle, FaSignOutAlt } from 'react-icons/fa';
import {
  Dropdown, NavDropdown
} from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { setToken, setUserData } from '../../redux/actions/user';
import { Store, User } from '../../api';
import {
  Link
} from 'react-router-dom';
import themeContext from '../../../src/utils/theme';
import './style.css';

const AuctioneerHeader = () => {
  const dispatch = useDispatch();

  const isLoggedIn = useSelector((state) => state.user.token);
  const [searchValue, setSearchValue] = useState("");
  const [selectedSearch, setSelectedSearch] = useState(1);
  const [auctioneer, setAuctioneer] = useState({});

  const logout = () => {
    dispatch(setToken(null));
    dispatch(setUserData({}));
    setTimeout(() => {
      window.location.assign("home")
    }, 500);
  }

  const loadData = async () => {

    /*
     * Assure that the current user data will always be updated globally
     */

    let response = await User.getData();
    if (response.status !== 'error') {
      dispatch(setUserData(response));
      if (response.role > 0 && window.location.pathname !== '/novo-cadastro') {
        if (response.has_store === 0)
          window.location.assign("novo-cadastro")
      }
    }

    // Load auctioneer from URL
    let id = window.location.pathname.split('/')
    let responseAuctioneer = await Store.getById(id[2]);
    if (responseAuctioneer.status !== 'error') {
      setAuctioneer(responseAuctioneer)
    }
  }

  useEffect(() => {
    loadData();
  }, [])

  const goToSearch = (e) => {
    try {
      e.preventDefault()
    } catch (error) { }
    window.location.assign(`/busca?name=${searchValue}&typeId=${selectedSearch}`)
  }

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <p className="font-10 col-lg-3 nav-item align-self-center m-0 ml-5 pl-4">
          Atendimento 2ª a 6ª-feira das 9h às 18h
        </p>

        <a className="nav-item col-lg-2 d-flex flex-row navbar-link" href="#">
          <div className="p-1 align-self-center ml-3 mr-1">
            <FaPhone className="align-self-center" />
          </div>
          <p className="font-8 header-item m-0 p-1">
            Telefone:
            <br />
            (99) 99999-9999
          </p>
        </a>

        <a className="nav-item col-lg-3 d-flex flex-row navbar-link">
          <div className="p-1 align-self-center ml-3 mr-1">
            <FaEnvelope className="align-self-center" />
          </div>
          <p className="font-8 header-item m-0 p-1">
            E-mail:
            <br />
            duduwhitney@gmail.com
          </p>
        </a>

        {
          isLoggedIn ? (
            <div className="col-lg-3 text-right">
              <span>
                <span style={{ margin: '0 5px' }}>
                  <Link to="/perfil" style={{ color: '#cccc34' }}>
                    Minha conta
                    </Link>
                </span>
                <FaUserCircle size={21} style={{ color: '#cccc34' }} />
                <span onClick={logout} style={{ paddingLeft: '20px' }}>
                  <Link href="#" style={{ color: '#cccc34' }}>
                    <span style={{ margin: '0', paddingRight: '7px' }}>
                      Sair
                      </span>
                    <FaSignOutAlt />
                  </Link>
                </span>
              </span>
            </div>
          ) : (
            <p className="col-lg-3 text-right auctioneer-header">
              <a href="/autenticacao#login" style={{ color: themeContext.AZUL_DARK }}>
                Faça login</a> <span style={{ color: themeContext.WHITE }} >ou</span> <a href="/autenticacao#cadastro" style={{ color: themeContext.AZUL_DARK }}>Cadastre-se
              </a>
            </p>
          )
        }
      </nav>

      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
          <div className="col-6">
            <a className="navbar-brand ml-5" href="#">
              <img
                src={
                  auctioneer.img_url ? `https://bigu.digital/zeleiloes-plataforma-backend/public/storage/stores/${auctioneer.img_url}` :
                    "https://bigu.digital/images/bigu-v2-white.png"
                }
                height="80"
                alt="image" />
            </a>
          </div>
          <form onSubmit={goToSearch} className="input-group mr-5 pr-5">
            <input
              type="text"
              className="form-control"
              aria-label="Digite aqui o que você procura"
              onChange={(event) => setSearchValue(event.target.value)}
            />
            <div className="input-group-append">
              <Dropdown>
                <Dropdown.Toggle variant="outline-secondary">
                  {selectedSearch === 1 ? "Leilões Ativos" : "Leilões Encerrados"}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => setSelectedSearch(1)}>Leilões Ativos</Dropdown.Item>
                  <Dropdown.Item onClick={() => setSelectedSearch(2)}>Leilões Encerrados</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <span onClick={goToSearch} className="btn input-group-text">
                <FaSearch />
              </span>
            </div>
          </form>
        </div>
      </nav>

      <div className="bg-info px-5">
        <div className="row mx-5 auctioneer-header-buttons">
          <a href="/"
            className="col btn btn-lg btn-info rounded-0 d-flex justify-content-center align-items-center">
            Home
        </a>
          <a href="#"
            className="col btn btn-lg btn-info rounded-0  d-flex justify-content-center align-items-center">
            Catálogos
        </a>
          <a href="#"
            className="col btn btn-lg btn-info rounded-0  d-flex justify-content-center align-items-center">
            Como Comprar
        </a>
          <a href="#"
            className="col btn btn-lg btn-info rounded-0  d-flex justify-content-center align-items-center">
            Como Vender
        </a>
          <a href="#"
            className="col btn btn-lg btn-info rounded-0  d-flex justify-content-center align-items-center">
            Acervo
        </a>
          <a href="#"
            className="col btn btn-lg btn-info rounded-0  d-flex justify-content-center align-items-center">
            Quem Somos
        </a>
          <a href="#"
            className="col btn btn-lg btn-info rounded-0  d-flex justify-content-center align-items-center">
            Contato
        </a>
        </div>

      </div>
    </>
  )
}

export default AuctioneerHeader;
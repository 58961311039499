import React from 'react';
import {
    Container, Row, Col, Carousel
} from 'react-bootstrap';
import { Header, HeaderWithSearch } from '../../components';
import ContactWithoudHeader from '../../screens/contactwithouheader';
import './styles.css';

import bannerVantagens from '../../assets/vantagens-top.jpg'
import Slide1 from '../../assets/logo2-horizontal.png';

const Vantagens = () => {
    return (
        <>
            <Header/>
            <HeaderWithSearch/>

            <div className="banner-resp">
                <img src={bannerVantagens} alt="banner vantagens"/>

                <Container>
                    <Row>
                    <div class="about-title">
                        <h2>Para Leiloeiros e Galerias</h2>
                    </div>
                    </Row>
                </Container>
            </div>

            <Container className="vantagens-container">
                <Row>
                    <Col xs={12}>
                        <h2>Vantagens e Recursos</h2>
                    </Col>

                    <Col xs={12} className="vantagens-topic">
                        <h3>Empresa</h3>
                        <p>Zé Leilões é um portal que permite aos tradicionais leilões de arte e antiguidades a apresentação e realização de seus leilões através da internet.
                        A tecnologia da plataforma permite que organizadores apresentem seus leilões para arrematantes em todo país,
                        facilitando a estes, a participação em tempo real, no conforto de sua casa.
                        </p>
                    </Col>

                    <Col xs={12} className="vantagens-topic">
                        <h3>Tecnologia</h3>
                        <p>Hospedagem <b>gratuita</b> na melhor estrutura de servidores web do mundo - <b>Amazon Web Services </b>
                        Plataforma utilizada por galerias, antiquários e leiloeiros em todo país, voltados para segmento de arte, antiguidades, colecionismo, numismática, jóias, móveis antigos e muito mais.
                        Seu maior diferencial é a realização dos leilões em domínio próprio (www.seudominio.com.br), permitindo assim, possibilidade de recebimento de contatos posteriores,  bem como,
                        a <b>criação de históricos junto ao Google e outros buscadores</b>.
                        </p>
                    </Col>

                    <Col xs={12} className="vantagens-topic">
                    <h3>Divulgação</h3>
                    <p>Portal amplamente divulgado nas principais mídias sociais: <b>Facebook, Instagram, Pinterest, Google Meu Negócio e Twitter</b>.
                    Envio de e-mails diários com agenda de novos leilões publicados e leilões do dia para toda base de arrematantes cadastrados.
                    Envio de SMS para arrematantes que registraram lances e/ou selecionaram algum recurso de acompanhamento: "Vigia" ou "Favorito"</p>
                    </Col>

                    <Col xs={12} className="vantagens-topic">
                    <h3>Recursos principais</h3>
                    <p>Lance Automático: com lance automático, o sistema fará lances mínimos, sempre que necessário, para que você continue como vencedor dentro do valor que pretende gastar.<br/><br/>

                        Favoritar peça: poderá acessar lotes de seu interesse que forem marcados como "Favorito"<br/><br/>

                        Vigias: será enviado e-mail sempre que um novo lance for ofertado para o lote marcado como "Vigia". (recurso válido somente até início do pregão)<br/><br/>

                        Enviar a um amigo: recurso que permite encaminhar e-mail personalizado com sugestão de lote para e-mail de seu amigo.<br/><br/>

                        Painel de gerenciamento: envio de e-mail de cobrança, ferramenta para bloqueio/desbloqueio, descadastramento de e-mails, nota de arrematação, controle de pagamentos recebidos, entre outros.<br/><br/>

                        Timing do pregão ao vivo:  <i>"lote em pregão, lote prestes a fechar, dou-lhe uma, duas..., lote vendido"</i>, controlado por operador/leiloeiro(a), podendo assim, definir tempo necessário para apresentação de cada lote.<br/><br/>

                        Venda pós leilão: possibilidade de manter catálogo ativo por até 30 dias após o pregão, através do canal "<b>Venda direta</b>" para disponibilização dos lotes não vendidos no leilão.  (*regras específicas para aceite).</p>
                    </Col>

                    <Col xs={12} className="vantagens-topic">
                        <h3>Alguns números:</h3>
                        <p>Plataformas: 500<b>+</b><br/>
                            Organizadores: 600<b>+</b><br/>

                            Arrematantes cadastrados: 122.000<b>+</b><br/>

                            Leilões realizados: 21.000<b>+</b><br/>

                            Acessos diários: 9.000<b>+</b><br/>

                            Lotes apregoados: 10.000.000<b>+</b><br/>

                            Facebook: 166.000<b>+</b><br/>

                            Instagram: 11.000<b>+</b>
                        </p>
                    </Col>

                    <Col xs={12} className="vantagens-topic">
                    <p className="fonts">
                        Projetada para você economizar seu tempo - implementação <b>rápida e prática</b>.<br/><br/>
                        
                        Entre em contato agora mesmo e saiba como apresentar seus leilões para todo país.<br/><br/>        

                        Zé Leilões - o maior e melhor portal de leilões de arte e antiguidades do Brasil.
                    </p>
                    </Col>

                </Row>
            </Container>

            <div className="mid-section">
                <ContactWithoudHeader/>
            </div>

            <div className="depoimentos sobre-box sobre vantagens">
                <Container>
                    <Row>
                        <Col md={12}>
                            <h2>Depoimentos</h2>
                        </Col>

                        <Col md={12}>
                            <Carousel className="py-3" controls={true} indicators={true} interval={3000}>
                                <Carousel.Item>
                                    <Row>
                                       <Col md={4} sm={4}>
                                           <div className="img-controller">
                                            <img
                                                className="w-100"
                                                src={Slide1}
                                                alt="First slide"
                                                />
                                           </div>
                                       </Col> 
                                       <Col md={8} sm={8}>
                                       <div class="autor-text">
                                            <h3>Over Galeria</h3>
                                            <p>“Muito obrigado mais uma vez e deixo claro minha satisfação de estar trabalhando com a plataforma Leilões BR, uma excelente ferramenta."</p>
                                            <h5>- Over Galeria</h5>
                                        </div>
                                       </Col> 
                                    </Row>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Row>
                                       <Col md={4} sm={4}>
                                           <div className="img-controller">
                                            <img
                                                className="w-100"
                                                src={Slide1}
                                                alt="First slide"
                                                />
                                           </div>
                                       </Col> 
                                       <Col md={8} sm={8}>
                                       <div class="autor-text">
                                            <h3>Over Galeria</h3>
                                            <p>“Muito obrigado mais uma vez e deixo claro minha satisfação de estar trabalhando com a plataforma Leilões BR, uma excelente ferramenta."</p>
                                            <h5>- Over Galeria</h5>
                                        </div>
                                       </Col> 
                                    </Row>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Row>
                                       <Col md={4} sm={4}>
                                           <div className="img-controller">
                                            <img
                                                className="w-100"
                                                src={Slide1}
                                                alt="First slide"
                                                />
                                           </div>
                                       </Col> 
                                       <Col md={8} sm={8}>
                                       <div class="autor-text">
                                            <h3>Over Galeria</h3>
                                            <p>“Muito obrigado mais uma vez e deixo claro minha satisfação de estar trabalhando com a plataforma Leilões BR, uma excelente ferramenta."</p>
                                            <h5>- Over Galeria</h5>
                                        </div>
                                       </Col> 
                                    </Row>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <Row>
                                       <Col md={4} sm={4}>
                                           <div className="img-controller">
                                            <img
                                                className="w-100"
                                                src={Slide1}
                                                alt="First slide"
                                                />
                                           </div>
                                       </Col> 
                                       <Col md={8} sm={8}>
                                       <div class="autor-text">
                                            <h3>Over Galeria</h3>
                                            <p>“Muito obrigado mais uma vez e deixo claro minha satisfação de estar trabalhando com a plataforma Leilões BR, uma excelente ferramenta."</p>
                                            <h5>- Over Galeria</h5>
                                        </div>
                                       </Col> 
                                    </Row>
                                </Carousel.Item>
                            </Carousel>
                        </Col>
                    </Row>
                </Container>
            </div>

        </>
    )
}

export default Vantagens;
import React, { useEffect, useState } from 'react';
import { Header, AuctioneerHeader, Footer } from '../../components';
import './style.css';
import {
  InputGroup, FormControl, Carousel
} from 'react-bootstrap';
import './style.css';
import { FaRss } from 'react-icons/fa';
import { Product, Store } from '../../api';

const Auctioneer = () => {
  const [auctioneer, setAuctioneer] = useState({})
  const [products, setProducts] = useState([])

  const loadData = async () => {
    let id = window.location.pathname.split('/')
    let response = await Product.getRandom(2, id[2])
    if(response.status !== 'error'){
      setProducts(response);
    }
  }

  useEffect(() => {
    loadData();
  }, [])

  return (
    <>
      {/* <Header /> */}
      <AuctioneerHeader />

      <div className="container mt-5 full-height-container mb-5">
        <Carousel indicators={false}>
          {
            products.map((a, b) => (
              <Carousel.Item key={b}>
                <div className="row">
                  <div className="col p-0">
                    <img
                      className="d-block w-100"
                      src={a.product_media[0]
                        ? "https://placeholder.com/wp-content/uploads/2018/10/placeholder.com-logo1.png"
                        : "https://placeholder.com/wp-content/uploads/2018/10/placeholder.com-logo1.png"}
                      alt="First slide"
                    />
                  </div>
                  <div className="col-6 mr-5 pt-2 pr-10 p2-1 slider-description">
                    <br />
                    {/* <h3>Lote <span className="h3 font-weight-bold">17</span></h3> */}
                    <h3>{a.name}</h3>
                    <p>{a.description}</p>
                    {/* <p>Valor inicial:</p> */}
                    {/* <p>R$ <span className="h3 font-weight-bold">199,99</span></p> */}
                    <button className="btn btn-info">
                      Registrar lance
                    </button>
                  </div>
                </div>
              </Carousel.Item>
            ))
          }
        </Carousel>
      </div>
    </>
  )
}

export default Auctioneer;
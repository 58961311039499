import React from 'react';
import {Container, Row, Col} 
from 'react-bootstrap';
import './style.css';

import AuctioneerHeader from '../../components/AuctioneerHeader';
import Advertising from '../../components/Advertising';

const Invitation = () => {
    return (
        <>
            <AuctioneerHeader/>

            <Container id="content" style={{paddingBottom: '343px'}}>
                <Row>
                    <Col md={12}>
                        <Container>
                            <Row>
                                <Col md={12}>
                                <div class="inner-content-title">
                                    <h2>Convite do leilão</h2>
                                </div>
                                <div class="inner-content-container">
                                    <Row>
                                        <ul class="is-Grid" id="Lista">
                                            <li class="col-md-12" id="semImagem" style={{display: 'block'}}><p>Não há convite para este leilão</p></li>
                                        </ul>
                                    </Row>
      					        </div>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>

            <Advertising/>
        </>
    )
}

export default Invitation;
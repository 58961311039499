import { call, put, takeLatest } from 'redux-saga/effects';
import { User } from '../../api';

// worker Saga: will be fired on USER_FETCH_REQUESTED actions
function* fetchUser({ payload }) {
   try {
      const user = yield call(User.getData, payload);
      yield put({type: "USER_FETCH_SUCCEEDED", user: user});
   } catch (e) {
      yield put({type: "USER_FETCH_FAILED", message: e.message});
   }
}

/*
  takeLatest

  Does not allow concurrent fetches of user. If "USER_FETCH_REQUESTED" gets
  dispatched while a fetch is already pending, that pending fetch is cancelled
  and only the latest one will be run.
*/
function* UserSaga() {
  yield takeLatest("USER_FETCH_REQUESTED", fetchUser);
}

export default UserSaga;

import React from 'react';
import { FaEnvelope } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { ProfileLeft } from '../../components';

const MyMessages = () => {
  const user = useSelector((state) => state.user.data);

  return (
    <div className="row pl-3 pr-3 mt-5">
      <ProfileLeft />

      <div className="col-9">
        <div className="row align-items-center">
          <FaEnvelope className="ml-3 mt-1" size={35} />
          <h3 className="col m-0">Minhas Mensagens</h3>
        </div>

        {/* <hr />

        <div className="row align-items-center">
          <div className="btn btn-info ml-3 mt-1 pt-1">
            <FaUser />
          </div>
          <h5 className="col-8 m-0">Dados Cadastrais</h5>
        </div>

        <div className="col-12 mt-3 mb-5">
          <h6>CPF/CNPJ: {user.cpf}</h6>
          <h6>E-mail: {user.email}</h6>
          <h6>Nome: {user.first_name} {user.last_name}</h6>
        </div> */}

      </div>
    </div>
  )
}

export default MyMessages;
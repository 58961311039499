import React, { useState, useEffect } from 'react';
import { Header } from '../../components';
import {
  Modal
} from 'react-bootstrap';
import './style.css';
import theme from '../../utils/theme';
import { Product } from '../../api';
import './style.css';
import { FaPen, FaShippingFast, FaTrash } from 'react-icons/fa';
import { useAlert } from "react-alert";

const ProductsScreen = () => {

  const alert = useAlert();
  const [products, setProducts] = useState([]);
  const [selected, setSelected] = useState([]);
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => setShowModal(false);

  const loadData = async () => {
    let response = await Product.getAll();
    // let response = await Store.getData();
    if (response.status !== 'error') {
      // setProducts(response[0].products);
      setProducts(response);
    }
  }

  useEffect(() => {
    loadData();
  }, [])

  const handleDelete = (item) => {
    setSelected(item);
    setShowModal(true);
  }

  const confirmDelete = async () => {
    let response = await Product.delete(selected.id);
    if (response.status !== 'error') {
      loadData();
    } else {
      alert.error('Erro')
    }
    setShowModal(false);
  }

  return (
    <>
      <Header />

      <div className="container pt-5 pb-5 full-height-container">
        <div className="row justify-content-between mb-3">
          <h3>Produtos</h3>
          <a href="/novo-produto"
            className="btn product-btn"
            style={{ backgroundColor: theme.AZUL_DARK, color: 'white' }}
          >
            Novo Produto
          </a>
        </div>

        <Modal show={showModal} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Tem certeza que deseja excluir o produto?</Modal.Title>
          </Modal.Header>
          {/* <Modal.Body>Woohoo, you're reading this text in a modal!</Modal.Body> */}
          <Modal.Footer>
            <div className="btn btn-secondary" onClick={handleClose}>
              Cancelar
            </div>
            <div className="btn btn-primary" onClick={confirmDelete}>
              Excluir
            </div>
          </Modal.Footer>
        </Modal>

        <div className="row">
          {
            products.map((item, index) => (
              <div key={index} className="card mr-3 mb-3" style={{ width: "18rem" }}>
                <button onClick={() => handleDelete(item)} className="btn btn-danger product-delete">
                  <FaTrash />
                </button>
                <button onClick={() => handleDelete(item)} className="btn btn-warning product-item">
                  <FaPen />
                </button>
                <img className="card-img-top"
                  src={item.product_media[0] ? `https://bigu.digital/zeleiloes-plataforma-backend/public/storage/products/${item.product_media[0].url}` : "https://socialistmodernism.com/wp-content/uploads/2017/07/placeholder-image.png"}
                  alt="Card image cap"
                />
                <div className="card-body">
                  <span className="badge badge-pill badge-primary mt-1 mb-1 ml-0">{item.product_types.name}</span>
                  <span className="badge badge-pill badge-info m-1 mb-1 ml-0">{item.product_categories.name}</span>
                  {
                    !item.auction ? null : item.auction.active === 1 ? (
                      <span className="badge badge-pill badge-danger mt-1 mb-1 ml-0">Ativo</span>
                    ) : null
                  }
                  {
                    item.free_shipping ? (
                      <span className="badge badge-pill badge-warning m-1 mb-1 ml-0">
                        <FaShippingFast />
                      </span>
                    ) : null
                  }

                  <h5 className="card-title">{item.name}</h5>
                  <p className="card-text text-truncate">{item.description}</p>
                  {
                    item.auction ? (
                      <a href={`/peca?id=${item.id}`} className="btn btn-info" disabled>
                        Ver Leilão
                      </a>
                    ) : item.sale ? item.sale.length > 0 ? (
                      <a href={`/peca?id=${item.id}`} className="btn btn-info" disabled>
                        Ver Anúncio
                      </a>
                    ) : (
                      <>
                        <a className="btn btn-primary" href={`/novo-leilao?prod=${item.id}`}>
                          Criar Leilão
                        </a>
                        {/* <a className="btn btn-primary mt-2" href={`/novo-anuncio?prod=${item.id}`}>
                          Criar Venda Direta
                        </a> */}
                      </>
                    ) : (
                      <>
                        <a className="btn btn-primary" href={`/novo-leilao?prod=${item.id}`}>
                          Criar Leilão
                        </a>
                        {/* <a className="btn btn-primary mt-2" href={`/novo-anuncio?prod=${item.id}`}>
                          Criar Venda Direta
                        </a> */}
                      </>
                    )
                  }
                  {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
                </div>
              </div>
            ))
          }
        </div>

        {
          products.length === 0 ? (
            <div style={{ textAlign: 'center' }}>
              Não há produtos cadastrados
            </div>
          ) : null
        }
      </div>
    </>
  )
}

export default ProductsScreen;
import React from 'react';
import { FaLock } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { User } from '../../api';
import { ProfileLeft } from '../../components';
import { useAlert, positions, types } from "react-alert";

const EditPassword = () => {
  const alert = useAlert();
  const user = useSelector((state) => state.user.data);

  const updateUser = async (event) => {
    event.preventDefault();

    let obj = {
      ...user,
      password: event.target[1].value,
    }

    if(event.target[1].value !== event.target[2].value){
      alert.error('Senha de confirmação diferente');
      return false;
    }

    let response = await User.update(obj, user.id);
    if(response.status !== 'error') {
      alert.success('Senha atualizada!');
      window.location.assign('/perfil')
    } else {
      alert.error('Erro')
    }
  }

  return (
    <div className="row pl-3 pr-3 mt-5">
      <ProfileLeft />

      <div className="col-9">
        <div className="row align-items-center">
          <FaLock className="ml-3 mt-1" size={35} />
          <h3 className="col m-0">Alterar Senha</h3>
        </div>

        <hr />

        <form onSubmit={updateUser} className="col-12 mt-3 mb-5 pl-0 pr-0">
          <div className="form-group has-feedback">
            <label>Informa sua senha atual:</label>
            <input
              required
              type="password"
              className="contact form-control"
              placeholder="Digite sua senha" />
          </div>
          <div className="form-group has-feedback">
            <label>Informe sua nova senha:</label>
            <input
              required
              type="password"
              className="contact form-control"
              placeholder="Digite sua nova senha" />
          </div>
          <div className="form-group has-feedback">
            <label>Confirme sua nova senha:</label>
            <input
              required
              type="password"
              className="contact form-control"
              placeholder="Confirme sua nova senha" />
          </div>

          <button className="btn btn-info" type="submit">
            Atualizar
          </button>
        </form>

      </div>
    </div>
  )
}

export default EditPassword;
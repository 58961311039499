import React from 'react';
import Header from '../../components/Header';
import HeaderWithSearch from '../../components/Header/HeaderWithSearch';

import './style.css';

const TermsConditions = () => {
    return (
        <>
            <Header/>
            <HeaderWithSearch/>
            <div class="conteudo-wrap py-3 my-3">
                <div class="conteudo-sizectrl">

                    <div class="container">
                        <div class="is-geraltitle row">
                        <div class="col-sm-12 d-flex justify-content-center">
                            <h3 className="text-uppercase">TERMO DE ADESÃO E CONDIÇÕES DE USO DA PLATAFORMA Zé Leilões</h3>
                        </div>
                        </div>
                    </div>

                    <div class="container">
                        <div class="row">
                            <div class="col-xs-12">
                            <p className="text-justify">Zé leilões empresa de direito privado, com sede no endereço Rua XXXXXX, nº XXX, XXXXX, na cidade de XXXXX, inscrito no CNPJ/MF sob o nº XXXXX, com base neste Termo de Adesão e Condições de Uso da Plataforma Nome Fantasia, disponibiliza aos interessados os serviços de leilões de objetos, a partir do uso da plataforma da Zé leilões. </p>
                            <p className="text-justify">Pede-se aos Usuários que leiam atentamente as cláusulas abaixo e também os demais avisos que possam aparecer no acesso de outras páginas do site da NOME FANTASIA. O Usuário fica ciente que ao acessar e utilizar os serviços aqui descritos concorda com o presente termo. </p>
                            <p className="text-justify font-weight-bold px-3">1. SOBRE O SERVIÇO DE LEILÕES ONLINE</p>
                            <p className="text-justify">Para melhor compreensão, define-se as partes da relação regulada por este documento: </p>
                            <p className="text-justify">Zé Leilões empresa detentora da plataforma para realização de leilões de forma online;</p>
                            <p className="text-justify"><b>USUÁRIO:</b> pessoa física capaz ou pessoa jurídica contratante dos serviços e/ou navegadora da plataforma da Zé Leilões.</p>
                            <p className="text-justify"><b>EMPRESA:</b> detentora dos produtos que vão à leilão e estão expostos no site da Zé Leilões;</p>
                            <p className="text-justify"><b>ARREMATANTES:</b> pessoa física ou jurídica que adquire os produtos da Empresa, através da plataforma da Zé Leilões.</p>
                            <p className="text-justify">A Zé Leilões corresponde a uma plataforma que permite que Usuários da internet ofertem seus produtos em um sistema de leilão. Também pela plataforma da Zé Leilões é possível que Usuários da internet adquirem produtos em sistema de leilão.</p>
                            <p className="text-justify px-3">Assim, a plataforma de leilões da Zé Leilões funcionará da seguinte maneira: a Empresa se cadastra na plataforma da Zé Leilões e dentro do site, importa os dados dos produtos a serem leiloados e a oferta mínima requerida. A partir daí, os Usuários da plataforma da Zé Leilões poderão participar do leilão, que ocorrerá na data e horário estabelecido pela Empresa.</p>
                            <p className="text-justify">Fica expresso que a <b>EMPRESA</b> e o <b>USUÁRIO</b> livremente desejam a participar do sistema de leilões online, nos moldes deste documento.</p>
                            <p className="text-justify">A Zé Leilões atuará somente como uma plataforma para realização dos leilões online, de modo que não terá nenhuma responsabilidade pela intermediação, negociação, recebimento de pagamento, envio dos produtos e todo e qualquer ato que seja extrapole seu papel de disponibilizar a plataforma.</p>
                            <p className="text-justify">Os custos para utilização da plataforma da Zé Leilões serão arcados pela Empresa, sendo calculados sob um percentual do valor pago pelo Arrematante pelo produto leiloado.</p>
                            <p className="text-justify px-3">Desse modo, os serviços prestados pela Zé Leilões às Empresas estão vinculados a comissionamento a ser pago à Zé Leilões, nos termos Cláusula 3 do presente documento.</p>
                            <p className="text-justify">Os produtos leiloados pelas Empresas podem ser de qualquer natureza, desde que lícita e permitida de ser comercializada no Brasil.</p>
                            <p className="text-justify font-weight-bold px-3">2. MODO DE FUNCIONAMENTO DA PLATAFORMA DA ZÉ LEILÕES</p>
                            <p className="text-justify px-5">2.1 Para o funcionamento do serviço de leilão online, os usuários, tanto Empresa quanto Cliente, deverão cumprir os itens a seguir:</p>
                            <p className="text-justify px-5 mx-4">
                            2.1.1 - O Usuário, seja ele Empresa ou Cliente, declara que leu e concorda com os termos e condições previstos no presente documento.<br/>
                            2.1.2 - A participação do usuário no programa ocorre com o preenchimento do formulário disponível no site do Zé Leilões, a partir do fornecimento de dados corretos, conforme solicitados.<br/>
                            2.1.3 - Com o recebimento dos dados, a Zé Leilões fará a conferência dos dados antes da aprovação.
                            </p>
                            <p className="text-justify px-5">
                            2.2 - A Zé Leilões se reserva no direito de não aprovar o cadastro do usuário ou excluí-lo da Plataforma de Leilões Online, na hipótese de divulgação de qualquer material, produto, imagem ou conteúdo em discordância com o presente documento e/ou que tenha qualquer dado que seja contrário aos valores da Zé Leilões.
                            </p>
                            <p className="text-justify px-5 mx-4">
                            2.2.1 - A Zé Leilões se reserva no direito de não aprovar o cadastro do usuário na plataforma caso os dados inseridos possuam informações ilícitas e que de certa forma contrarie a lei e as condições de uso aqui determinada, que contenha informações falsas, não verídicas, ofensivas, contra a ordem, a moral e os bons costumes ou que incentivem a prática de qualquer crime, inclusive a discriminação e o preconceito de raça, cor, religião ou ideologia política; utilize palavras, termos, expressões, imagens, figuras, símbolos ou fotos obscenos ou pornográficos; contenha vírus, programas ou códigos que possam danificar dados, informações ou o funcionamento de outros dispositivos móveis, impedindo o uso normal da rede, do sistema ou dos equipamentos de terceiros; falsifique, omita ou simule endereços IP´s, de rede ou de correio eletrônico, na tentativa de ocultar identidade ou autoria ou ainda responsabilizar terceiros inocentes. <br/>
                            2.2.2 - Na hipótese de cadastro já aprovado e que posteriormente a Zé Leilões verifique que o produto ou serviço da Empresa incorra em qualquer uma das hipóteses do item 2.2.1, a Zé Leilões realizará a exclusão da empresa, sem a realização de aviso prévio. <br/>
                            2.2.3 - Caso haja violação das cláusulas 2.2.1 e 2.2.2 e comunicada o Usuário sobre sua exclusão da plataforma, no caso de Usuário Empresa, será o seu dever remover toda e qualquer informação contida em seu site e/ou serviços que vincule a Zé Leilões, que seja decorrente ou não deste Termo de Uso, de modo que não será gerado à Empresa nenhum direito a perdas e danos, prejuízos, indenização, lucro cessante ou qualquer outra classe de ressarcimento.<br/>
                            </p>
                            <p className="text-justify px-5">
                            3. A Empresa, ao contratar os serviços da plataforma da Zé Leilões, poderá escolher quais produtos/serviços deseja que sejam divulgados na plataforma para o leilão. Após sua escolha, a Zé Leilões analisará o pedido para realização do leilão e posteriormente agendará o leilão em seu site, deixando público para que possíveis interessados possam participar.
                            </p>
                            <p className="text-justify px-5">
                            4. Tendo a Zé Leilões recebido o pedido da Empresa para o uso da plataforma de leilões, será encaminhado à Empresas todos os passos e dados para que eles tenham a acesso ao serviço de leilão.
                            </p>
                            <p className="text-justify px-5">
                            5. A partir da aprovação do cadastro do Usuário, é de sua inteira responsabilidade a manutenção dos dados atualizados na plataforma da Zé Leilões estando isenta a referida de qualquer responsabilidade em caso de descumprimento deste item.
                            </p>
                            <p className="text-justify px-5">
                            6. Em nenhum caso a Zé Leilões será responsabilizada pela ocorrência de um erro técnico cometido pelo Empresa durante a realização do leilão online.
                            </p>
                            <p className="text-justify px-5">
                            7. Caberá ao Arrematante do objeto leiloado entrar em contato com o Leiloeiro, no intuito de serem acertadas as condições de pagamento e retirada. 
                            </p>
                            <p className="text-justify px-5 mx-4">
                            7.1 - As informações para contato deverão ser disponibilizadas pela Empresa no próprio leilão online; <br/>
                            7.2 - Só será permitido que o Arrematante não adquira o produto arrematado na hipótese de desistência mútua entre ele e a Empresa;<br/>
                            7.3 -. Os Usuários tem por obrigação requererem a emissão de nota fiscal ou documento equivalente ao Leiloeiro, sob pena de desistência da aquisição por justa causa.
                            </p>
                            <p className="text-justify px-5">
                            8. A Empresa isenta a Zé Leilões de quaisquer responsabilidades acerta de inserção de preço errado no sistema de leilão.
                            </p>
                            <p className="text-justify font-weight-bold px-3">3. DO CUSTO DE SERVIÇOS E PROCESSAMENTO DA COMISSÃO DA ZÉ LEILÕES</p>
                            <p className="text-justify px-5">
                            3.1 - Para utilização dos serviços da plataforma de leilões, a Zé leilões cobrará da Empresa o percentual de XX% sobre o valor do produto arrematado.
                            </p>
                            <p className="text-justify px-5 mx-4">
                            3.1.1 - Deste modo, ainda que a Zé leilões  não disponibilize uma plataforma do pagamento, será de responsabilidade da Empresa Leiloeiro repassar a comissão devida à Zé leilões  ainda que o Arrematante não cumprir com o pagamento e aquisição do objeto leiloado, sob pena de cobrança pelos meios judiciais e extrajudiciais cabíveis.
                            </p>
                            <p className="text-justify px-5">
                            3.2 - O repasse da comissão da Zé leilões  deve ser feita através de transferência bancária à conta indicada pela Zé leilões, em até XX dias da data do término do leilão.
                            </p>
                            <p className="text-justify px-5">
                            3.3 - Do CLIENTE não será cobrada nenhuma taxa para utilização da plataforma de leilões online. Zé leilões 
                            </p>
                            <p className="text-justify font-weight-bold px-3">4. DAS OBRIGAÇÕES DOS USUÁRIOS</p>
                            <p className="text-justify px-5">
                            4.1 - São obrigações do Cliente e da Empresa as seguintes disposições
                            </p>
                            <p className="text-justify px-5 mx-4">
                            4.1.1 - Cumprir todas as cláusulas do presente documento; Zé Leilões <br/>
                            4.1.2 - Utilizar a plataforma da Zé Leilões  somente para fins lícitos; <br/>
                            4.1.3 - Cadastrar-se na plataforma da  Zé Leilões  e preencher corretamente todos os seus dados pessoais;<br/>
                            4.1.4 - No caso da Empresa, cumprir com a venda ao Arrematante, nos termos do que for arrematado no leilão online;<br/>
                            4.1.5 - Caberá à Empresa somente realizar a venda após a emissão de nota fiscal;<br/>
                            4.1.6 - Comunicar imediatamente a Zé Leilões  em caso de mudança de qualquer dado;<br/>
                            4.1.7 - Respeitar as normas de antifraude e veiculação de conteúdo e comércio ilegal, não permitindo que seja utilizado qualquer meio fraudulento ou artificias que gerem vendas e/ou receitas para a Empresa;<br/>
                            4.1.8 - Caberá aos Usuários e Empresa acertarem entre si as condições para pagamento, entrega e garantia do produto arrematado, estando a Zé Leilões  isenta de qualquer responsabilidade neste sentido;<br/>
                            4.1.9 - No caso da Empresa, ela se compromete a somente leiloar produtos que estejam em conformidade com a lei, sendo vedado a venda de produtos provenientes de ato ilícito, sem documentação fiscal, contrabandeados, armas, produtos que estimulem a violência, substâncias ilícitas, entre outros produtos do gênero;<br/>
	                        4.1.10 - Ainda que todas as ferramentas e mecanismos de segurança da informação sejam aplicados pela  Zé Leilões, a utilização dos serviços somente será segura se os Usuários observarem todas as regras de segurança. Sendo assim, os usuários se obrigam a utilizar os serviços de forma segura, sendo, portanto, os únicos responsáveis pelo uso incorreto dos tais serviços e eventuais prejuízos decorrentes de tal fato. A Zé Leilões  não se responsabiliza pelas perdas e danos dos usuários decorrentes da má utilização do site e/ou indicados;<br/>
	                        4.1.11 - Fica expresso que a Zé Leilões  não se obriga a monitorar o conteúdo das informações trocadas entre Empresa e Usuário/Arrematante durante a realização do leilão online, mas em caso de violação as normas deste termo, a Zé Leilões  se reserva no direito de restringir o acesso do Usuário à plataforma, no intuito de manter a boa qualidade dos serviços prestados.<br/>
                            </p>
                            <p className="text-justify px-5">
                            4.2 - Tanto Usuário quanto Empresa desde já expressam a ciência de que a Zé Leilões não se responsabiliza por obrigações tributárias, trabalhistas, penais, civis, administrativas, ambientais, nem quaisquer outras, pertinentes às atividades realizadas na plataforma da isolada ou em conjunto, estando expressa a renúncia das partes em responsabilizar à Zé Leilões  por qualquer obrigação deste tipo.
                            </p>
                            <p className="text-justify px-5 mx-4">
                            4.2.1 - Usuário/Arrematante e Empresa também expressam o seu entendimento de que a relação decorrente do presente instrumento possui única e exclusivamente natureza cível e que a utilização da plataforma pelos usuários não dará ensejo a qualquer vínculo empregatício entre a Zé Leilões  e o Usuário/Arrematante e Empresa.
                            </p>
                            <p className="text-justify font-weight-bold px-3">5. OBRIGAÇÕES DA ZÉ LEILÕES</p>
                            <p className="text-justify px-5">5.1 - São obrigações da Zé Leilões  as seguintes disposições:</p>
                            <p className="text-justify px-5 mx-4">
                            5.1.1 - Disponibilizar a plataforma necessária a para que a Empresa possa convidar potenciais clientes para a participação do leilão online; <br/>
                            5.1.2 - Ofertar um espaço em sua plataforma, para que a Empresa possa controlar o número de participantes no leilão online e os ter acesso ao Arrematante.<br/>
                            5.1.3 - Realizar a cobrança da sua comissão sobre o valor do produto arrematado, nos termos da cláusula 3 do presente instrumento;<br/>
                            5.1.4 -Observar a legislação cível e consumerista, no que se refere o cumprimento do presente termo.<br/>
                            </p>
                            <p className="text-justify font-weight-bold px-3">6. HIPÓTESES DE EXCLUSÃO DA EMPRESA E DO CLIENTE DA PLATAFORMA DA ZÉ LEILÕES </p>
                            <p className="text-justify px-5">6.1 - O Usuário desde já expressa sua ciência de que, qualquer forma de realização de leilão online sugerida pela Empresa e que seja em plataforma externa a da Zé Leilões  serão considerados fraudulentos, podendo acarretar em exclusão da Empresa e do Usuário da plataforma da Zé Leilões.</p>
                            <p className="text-justify px-5 mx-4">
                            6.1.1 - Também poderão ser excluídos os Usuários (tanto Empresa quanto participante de leilão) que utilizarem de meios fraudulentos. Considera-se meios fraudulentos as hipóteses de fornecer qualquer forma de vantagem ou trocar favores, induzindo os usuários da Internet a cometer erros através de mensagens (spam) ou qualquer outro meio, não se limitando aos exemplos deste item. 
                            </p>
                            <p className="text-justify px-5">6.2 - Uma vez encontradas indicações fraudulentas ou geradas artificialmente, a Zé Leilões  excluirá qualquer leilão que esteja agendado, estando isenta de ressarcir a Empresa de qualquer dano sofrido.</p>
                            <p className="text-justify px-5 mx-4">
                            6.2.1 - Sendo o Usuário e/ou Empresa excluído, a Zé Leilões  se reserva no direito de não restituir qualquer valor a ele, sendo qualquer montante utilizado para ressarcir os danos e prejuízos incorridos pela infração do referido, independente se o valor tenha sido computado e/ou pendente para pagamento.
                            </p>
                            <p className="text-justify px-5">6.3 - Sem prejuízo do item anterior, a Zé Leilões  tem o ônus e direito de, a qualquer tempo, independente de notificação prévia, excluir o <b>Usuário</b> da plataforma de leilões caso o referido incorra nas seguintes hipóteses, cumuladas ou não:</p>
                            <p className="text-justify px-5">
                            6.4 - Divulgação de produto para leilão que não tenha sido aprovado previamente pela Zé Leilões; <br/>
                            6.5 - Utilização de URLs e/ou domínios e/ou marcas da Zé Leilões  para cadastramento em sites de busca, pesquisa, portais, comparações de produtos e preços, comunidades na internet, dentre outros;<br/>
                            6.6 - Violação por parte do Usuário de qualquer regra presente neste documento;
                            </p>
                            <p className="text-justify px-5">
                            6.7 - Tais hipóteses são consideradas infrações gravíssimas que acarretarão na exclusão imediata do Usuário da plataforma de leilões online.
                            </p>
                            <p className="text-justify px-5 mx-4">
                            6.7.1 - A partir da exclusão do Usuário por qualquer motivo da presente cláusula, o referido ficará proibido de realizar novo cadastro no site da Zé Leilões  pelo prazo de 01 (um) ano, a contar da data da exclusão, ficando sujeito à nova exclusão do programa sem prévia justificativa.
                            </p>
                            <p className="text-justify px-5">
                            6.8 - Nos casos de exclusão do Usuário nas hipóteses acima ou ainda por violação de qualquer direito de propriedade intelectual, estipula-se a multa não compensatória, que será equivalente a xx (por extenso).
                            </p>
                            <p className="text-justify font-weight-bold px-3">7. DIREITOS AUTORAIS E PROPRIEDADE INTELECTUAL </p>
                            <p className="text-justify px-5"> 
                            7.1 - Todo o conteúdo disposto no site da Zé Leilões  é de sua propriedade, já que é a empresa que o mantém e organiza. A utilização de tal tecnologia por terceira pessoa não autorizada expressamente a fazê-lo será considerada uma violação aos direitos autorais e de propriedade intelectual, conforme o caso. <br/>
                            7.2 - Todas as marcas comerciais incluídas nos serviços da Zé Leilões  são de sua propriedade ou lhe foram outorgadas sob licença ou autorização por seus titulares para sua legítima utilização. As marcas do Zé Leilões, bem como o nome de domínio que as abrigam, são bens intangíveis de propriedade do próprio Zé Leilões, que detém, portanto, o direito de exclusividade sobre seu uso. Assim, a utilização destes bens intangíveis por qualquer terceiro deverá estar expressa e formalmente autorizada sob as penas da lei.<br/>
                            7.3 - Ao acessar os conteúdos da Zé Leilões, o Usuário declara que irão respeitar todos os direitos de propriedade intelectual e industrial, os decorrentes da proteção de marcas registradas, bem como de todos os direitos referentes a terceiros que porventura estejam, ou estiveram, de alguma forma, disponíveis em tais serviços. <br/>
                            7.4 - O simples acesso ao site da Zé Leilões e divulgação dos seus serviços não confere ao usuário qualquer direito de uso dos nomes, títulos, palavras, frases, marcas, patentes, obras literárias, artísticas, lítero-musicais, entre outras, que nele estejam, ou estiveram, disponíveis.<br/>
                            7.5 - O Usuário assume toda e qualquer responsabilidade, de caráter civil e/ou criminal, pela utilização indevida das informações, textos, gráficos, marcas, obras, enfim, de todo e qualquer direito de propriedade intelectual ou industrial presentes na  Zé Leilões.<br/>
                            7.6 - A licença de uso da plataforma da Zé Leilões é fornecida para o Usuário, ora Licenciado, para o seu uso exclusivo, sendo vedado a cessão, sublocação, venda, etc., sem a devida autorização da Zé Leilões, sob pena de aplicação das medidas judiciais e administrativas cabíveis.
                            </p>
                            <p className="text-justify font-weight-bold px-3">8. DO TRATAMENTO DE INFORMAÇÕES </p>
                            <p className="text-justify px-5"> 
                            8.1 - A Zé Leilões declara que os registros de acesso, bem como o registro de dados pessoais dos usuários, a obediência aos direitos à privacidade, proteção de dados pessoais e ao sigilo das comunicações privadas são realizados nos termos da legislação em vigor. <br/>
                            8.2 - Para fins deste termo de uso, os Usuários reconhecem e concordam que os são controladores e a Zé Leilões  operadora dos Dados Pessoais do Usuário, aplicando-se a cada um, portanto, as obrigações e responsabilidades previstas na legislação aplicável no tocante à sua atuação. <br/>
                            8.3 - Os usuários autorizam expressamente a Zé Leilões a coletar, usar e armazenar seus dados pessoais. Só serão coletados os dados necessários para o processamento dos serviços. Todo e qualquer dado que ultrapasse esta utilidade será fornecido de maneira voluntária pelo Usuário. <br/>
                            </p>
                            <p className="text-justify px-5 mx-4">
                            8.3.1 - No caso das Empresas serão coletados os seguintes dados: razão social, CNPJ, endereço da empresa, nome completo do representante da empresa, além dos dados como CPF, RG; número de telefone da empresa e e-mail. De modo opcional, este usuário poderá fornecer o link para suas redes sociais e carregar uma foto para o seu perfil. <br/>
                            8.3.2 - Para os usuários clientes, serão coletados os seguintes dados: RG, CPF, telefone, e-mail.
                            </p>
                            <p className="text-justify px-5"> 
                            8.4 - Os respectivos registros serão mantidos pela Zé Leilões sob sigilo, em ambiente controlado e de segurança, pelo prazo em que vigorar a relação entre as partes e, também, para os devidos fins legais, na hipótese de rescisão contratual. <br/>
                            8.5 - Os dados pessoais dos usuários serão excluídos definitivamente mediante requerimento do próprio usuário ou ao término da relação entre as partes, obedecido o prazo de guarda estabelecido em lei. <br/>
                            8.6 - É proibido o uso de qualquer equipamento, software ou outros recursos que possam interferir nas atividades e operações do site da Zé Leilões  e em anúncios, descrições, contas ou em seus bancos de dados. As injunções estipuladas nestas cláusulas farão com que a pessoa responsável assuma a responsabilidade de processos legais relevantes e seja responsável pelos danos causados. 
                            </p>
                            <p className="text-justify font-weight-bold px-3">9. DA VIGÊNCIA</p>
                            <p className="text-justify px-5"> 
                            9.1 - O presente termo será vigente tanto para Empresa quanto para o Usuário durante o prazo que houver obrigações entre as partes.
                            </p>
                            <p className="text-justify font-weight-bold px-3">10. DA EXTINÇÃO E RESCISÃO DO PRESENTE TERMO</p>
                            <p className="text-justify px-5">
                            10.1 - O descumprimento de qualquer das cláusulas qualquer uma das partes ensejará na rescisão deste instrumento.
                            10.2 - Em caso de inexecução, descumprimento total ou parcial deste termo, a rescisão ocorrerá automaticamente, independente de notificação judicial ou extrajudicial por qualquer uma das partes.
                            </p>
                            <p className="text-justify font-weight-bold px-3">11. DO SUPORTE E CONTATO</p>
                            <p className="text-justify px-5"> 
                            11.1 - Quaisquer dúvidas relacionadas à Plataforma de Leilões Online da Zé Leilões podem ser sanadas em sua Central de Atendimento, através do telefone XXXXXXXX ou a partir do formulário de atendimento, disposto no sítio da empresa na internet.
                            </p>
                            <p className="text-justify font-weight-bold px-3">12. DO FORO E DISPOSIÇÕES GERAIS</p>
                            <p className="text-justify px-5"> 
                            12.1 - A Zé Leilões  se reserva no direito de modificar o presente documento a qualquer tempo, bem como limitar, cancelar e suspender seu uso ou acesso, sem atingir as operações já concluídas. <br/>
                            12.2 - As Empresas e Usuários declaram a ciência quanto a não participação da Zé Leilões  na transação do leilão, isentando a referido de qualquer problema quanto ao controle de identidade do Usuário, veracidade dos anúncios e qualidade e características dos objetos leiloados.<br/>
                            12.3 - O não-exercício e o não-uso pela  Zé Leilões  de qualquer direito ou faculdade previstos nestes instrumento não caracterizarão novação, renúncia, perdão, precedente ou alteração destes termos, de modo que tais direitos ou faculdades permanecerão vigentes e disponíveis para todos os efeitos.<br/>
                            12.4 - Em caso de modificação do documento, os usuários serão informados desta alteração, através do e-mail cadastrado na plataforma, com 30 (trinta) dias de antecedência.<br/>
                            12.5 - A partir da comunicação de alteração, o usuário terá o ônus de excluir a sua conta da plataforma da Zé Leilões , em caso de discordância nos termos do novo documento.<br/>
                            12.6 - Na hipótese de o usuário não excluir sua conta até a data da vigência do novo termo, ficará implícita a aceitação do novo documento.<br/>
                            12.7 - Fica eleito pelas partes o foro da Comarca da cidade de XXXXXXX, estado do XXXXXXX, competente para dirimir quaisquer questões referentes ao presente, com renuncia expressa de qualquer outro, por mais privilegiado que seja.<br/>
                            12.8 - E, por estarem de acordo com as cláusulas e termos do presente contrato, os usuários aceitam o presente Termo de Adesão e Condições de Uso, em todas as suas cláusulas e disposições.<br/>
                            </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TermsConditions;
import React, { useState, useEffect } from 'react';
import { useAlert, positions } from 'react-alert';
import { useSelector } from 'react-redux';
import { Product, Sale } from '../../api';
import { Header } from '../../components';

const ProductPurchase = () => {
  const alert = useAlert()
  const user = useSelector((state) => state.user.data);

  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState({});

  const loadData = async (id) => {
    let response = await Product.getById(id);
    if (response.status !== 'error') {
      setProduct(response)
    }
  }

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const prodId = urlParams.get('id');

    loadData(prodId)
  }, [])

  const handleSubmit = async () => {
    setLoading(true)
    let obj = {
      user_id: user.id,
      store_id: product.store_id,
      product_id: product.id,
      bid_id: 0,
      payment_method: "1",
      payment_status: 1,
      free_shipping: 0,
    }
    let response = await Sale.buy(obj)
    if(response.status !== 'error') {
      alert.success('Sucesso! A compra foi concluída')
      window.location.assign('/perfil#minhas-compras')
    } else {
      alert.error('Erro! Não foi possível concluir a compra', { position: positions.TOP_RIGHT })
      setLoading(false)
    }
  }

  return (
    <div className="wrapper">
      <Header />

      <div className="container">
        <div className="mt-5">
          <h4>Compra pós pregão</h4>

          <div className="row mt-3 p-3 border rounded">
            <div className="col-md-1">
              {
                product.product_media ? (
                  <div
                    className="border rounded"
                    style={{
                      height: 100,
                      width: 100,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      backgroundImage: `url("https://bigu.digital/zeleiloes-plataforma-backend/public/storage/products/${product.product_media[0].url}")`
                    }}
                  ></div>
                ) : (
                  <div
                    className="border rounded"
                    style={{
                      height: 100,
                      width: 100,
                    }}
                  ></div>
                )
              }

            </div>
            <div className="col-md-9 pl-md-5">
              <h4>{product.name}</h4>
              <p>{product.description}</p>
            </div>
            <div className="col-md-2 d-flex align-items-center justify-content-end">
              <h3>R${(product?.auction?.min_bid || 0).toFixed(2).replace('.', ',')}</h3>
            </div>

            <div className="p-3 col-md-12 d-flex justify-content-end">
              <button onClick={handleSubmit} disabled={loading} className="btn btn-primary">
                {
                  loading ? (
                    <div className="spinner-border"></div>
                  ) : "Finalizar Compra"
                }
              </button>
            </div>
          </div>

          {/* <div className="row d-flex justify-content-between">
            <div className="col-md-3 mt-3 p-3 border rounded">
              <h4>Forma de pagamento</h4>
              <div className="pt-3 w-100">
                <div className="d-flex justify-content-between">
                  <label for="credit-card" className="w-100">Cartão de crédito</label>
                  <input name="payment" id="credit-card" type="radio" />
                </div>
                <div className="d-flex justify-content-between">
                  <label for="boleto" className="w-100">Boleto</label>
                  <input name="payment" id="boleto" type="radio" />
                </div>
              </div>
            </div>
            <div className="col-md-8 mt-3 p-3 border rounded">
              
            </div>
          </div> */}

        </div>
      </div>

    </div>
  )
}

export default ProductPurchase;
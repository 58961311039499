const theme = {
    YELLOW_DARK: '#cccc34',
    AZUL_DARK: '#1d4d74',
    GREY: '#cad3d0',
    YELLOW_LIGTH: '#dbdb6b',
    BLUE_LIGTH: '#6c8ca4',
    GREEN: '#a0d064',
    ORANGE: '#e88c64',
    WHITE: '#ffffff'
}



export default theme;
import React, { useEffect, useState } from 'react';
import { Footer, Header } from '../../../components';
import { useDropzone } from 'react-dropzone';
import './style.css';
import { FaTimes } from 'react-icons/fa';
import { Product, Store } from '../../../api';
import { useAlert, positions, types } from "react-alert";
import { useSelector } from 'react-redux';

const EditProduct = () => {

  const alert = useAlert();

  const [categories, setCategories] = useState([]);
  const [types, setTypes] = useState([]);
  const [files, setFiles] = useState([]);
  const [stores, setStores] = useState([]);
  const [step, setStep] = useState(1);
  const [product, setProduct] = useState({});
  const [newProduct, setNewProduct] = useState({});
  const [editProduct, setEditProduct] = useState({});
  const [medias, setMedias] = useState([])

  /* Deletar imagens dos produtos */

  const deleteProductImage = async(id) => {
    let response = await Product.deleteMedia(id)
    if (response.status !== 'error') {
      loadMedias()
      setEditProduct(response)
    } else {
      alert.error("Não foi possível deletar as fotos!", { position: positions.TOP_RIGHT });
    }
  }

  

  /* Atualizar produtos */

  const loadInfo = async (idProduct) => {
    var idProduct = window.location.pathname.split("/")[2];
    let verifycheckbox = document.getElementById("freeShipping");
    let response = await Product.getById(idProduct);
    if (response.status !== 'error') {
        setEditProduct(response)
    } else {
      alert.error("Não foi possível atualizar seus dados!", { position: positions.TOP_RIGHT });
    }
  }

  /* Fim requisição de atualizar */


  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    disabled: step === 1 ? true : false,
    onDrop: acceptedFiles => {
      let aux = files.slice();
      acceptedFiles.forEach((item, index) => {
        aux.push(Object.assign(item, { preview: URL.createObjectURL(item) }))
      })
      setFiles(aux);
    }
  });

  const thumbs = files.map((file, index) => (
    <div className="dropzone-thumb" key={file.name}>
      <div className="dropzone-thumb-inner">
        <button onClick={() => removeFile(index)} className="btn btn-danger dropzone-thumb-delete-btn">
          <FaTimes className="dropzone-thumb-delete-icon" />
        </button>
        <img
          src={file.preview}
          className="dropzone-img"
        />
      </div>
    </div>
  ));

  const removeFile = (index) => {
    let aux = files.slice();
    aux.splice(index, 1)
    setFiles(aux)
  }

  useEffect(() => () => {
    files.forEach(file => URL.revokeObjectURL(file.preview));
  }, [files]);

  const loadCategories = async () => {
    let response = await Product.getCategories();
    if (response.status !== 'error') {
      setCategories(response)
    }
  }

  const loadTypes = async () => {
    let response = await Product.getTypes();
    if (response.status !== 'error') {
      setTypes(response)
    }
  }

  const loadStores = async () => {
    let response = await Store.getData();
    if (response.status !== 'error') {
      setStores(response)
    }
  }

  /* Carregar medias */

  const loadMedias = async () => {
    var idProduct = window.location.pathname.split("/")[2];
    let response = await Product.getMedia(idProduct);
    if (response.status !== 'error') {
      loadInfo(idProduct)
    }
  }

  useEffect(() => {
    loadMedias();
    loadTypes();
    loadCategories();
    loadStores();
    loadInfo();
  }, [])

  const uploadFile = async () => {
    var idProduct = window.location.pathname.split("/")[2];
    let promises = []
    files.forEach((item) => {
      let formdata = new FormData();
      formdata.append("product_id", parseInt(idProduct));
      formdata.append("is_featured", 1);
      formdata.append("type", "photo");
      formdata.append("file", item, "image.jpg");
      promises.push(Product.uploadMedia(formdata))
    })

    Promise.all(promises).then((values) => {
      let err = false
      values.forEach((item) => {
        if (item.status === 'error') {
          err = true
        }
      })

      if (err) {
        alert.error('Erro')
      } else {
        alert.success('Arquivos enviados com sucesso!')
        // window.location.href = '/produtos'
        // window.location.href = '/perfil#minhas-pecas'
        window.location.href = '/minha-loja'
      }
    });

  }

  const submitProduct = async (event) => {
    event.preventDefault();
    var idProduct = window.location.pathname.split("/")[2];
    let obj = {
      id: idProduct,
      store_id: event.target[0].value,
      name: event.target[1].value,
      description: event.target[2].value,
      type_id: event.target[3].value,
      category_id: event.target[4].value,
      free_shipping: event.target[5].checked || 0,
    }
    let response = await Product.update(obj);
    if (response.status !== 'error') {
        setNewProduct(response)
        setStep(2)
        alert.success('Produto Atualizado! Agora adicione imagens para seu produto')
    } else {
        console.log(obj)
        alert.error('Erro')
    }
  }

  return (
    <>
      <Header />

      <div className="container pt-5 pb-5 full-height-container">
        <div className="row d-flex justify-content-center">
          <div className="col-6">
            <h3>Edição do seu produto</h3>
            <form onSubmit={submitProduct}>
              <div className="form-group">
                <label>Loja</label>
                <select 
                required 
                className="custom-select" 
                disabled={step === 1 ? false : true}
                onChange={(e) => setEditProduct(Object.assign({}, { ...editProduct, store_id: e.target.value }))}
                >
                  {
                    stores.map((item, index) => (
                      <option key={index} value={item.id} selected={editProduct.store_id === item.id}>{item.company_name}</option>
                    ))
                  }
                </select>
              </div>
              <div className="form-group">
                <label>Nome do item</label>
                <input 
                    required 
                    className="form-control" 
                    placeholder="Insira o nome aqui"
                    disabled={step === 1 ? false : true} 
                    value={editProduct.name}
                    onChange={(e) => setEditProduct(Object.assign({}, { ...editProduct, name: e.target.value }))}
                />
              </div>
              <div className="form-group">
                <label>Descrição</label>
                <textarea 
                    className="form-control"
                    placeholder="Insira a descrição aqui"
                    rows="3"
                    disabled={step === 1 ? false : true}
                    value={editProduct.description}
                    onChange={(e) => setEditProduct(Object.assign({}, { ...editProduct, description: e.target.value }))}
                />
              </div>
              <div className="form-group">
                <label>Tipo</label>
                <select 
                    required 
                    className="custom-select" 
                    disabled={step === 1 ? false : true}
                    onChange={(e) => setEditProduct(Object.assign({}, { ...editProduct, type_id: e.target.value }))}
                >
                  {
                    types.map((item, index) => (
                        <option key={index} value={item.id} selected={editProduct.type_id === item.id}>{item.name}</option>
                    ))
                  }
                </select>
              </div>
              <div className="form-group">
                <label>Categoria</label>
                <select 
                required 
                className="custom-select" 
                disabled={step === 1 ? false : true}
                onChange={(e) => setEditProduct(Object.assign({}, { ...editProduct, category_id: e.target.value }))}
                >
                  {
                    categories.map((item, index) => (
                        <option key={index} value={item.id} selected={editProduct.category_id === item.id}>{item.name}</option>
                    ))
                  }
                </select>
              </div>
              <div className="custom-control custom-checkbox mb-3">
                <input
                    type="checkbox" 
                    className="custom-control-input" 
                    id="freeShipping" 
                    disabled={step === 1 ? false : true}
                    value={editProduct.free_shipping}
                    checked={editProduct.free_shipping == 1 ? true : false}
                    onChange={(e) => setEditProduct(Object.assign({}, { ...editProduct, free_shipping: e.target.checked }))}
                />
                <label className="custom-control-label" for="freeShipping">Entrega grátis</label>
              </div>
              {
                step === 1 ? (
                  <button type="submit" className="btn btn-primary" disabled={step === 1 ? false : true}>Salvar</button>
                ) : null
              }
            </form>
          </div>

          <div className="col-6">
            <h3 className="ml-3">Depois, adicione imagens e vídeos</h3>
            <section className="container">
              <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                <p>Arraste ou clique aqui para adicionar imagens</p>
              </div>
              <aside className="dropzone-thumbs-container">
                {thumbs}
                {
                  editProduct.product_media ? editProduct.product_media.map((item, index) => (
                    <div className="dropzone-thumb">
                      <div className="dropzone-thumb-inner">
                        {
                          step === 2 ? (
                            <button onClick={() => deleteProductImage(item.id)} className='btn btn-danger dropzone-thumb-delete-btn'>
                              <FaTimes className="dropzone-thumb-delete-icon" />
                            </button>
                          ) : null
                        }
                      <img
                        className="product-image-btn"
                        src={`https://bigu.digital/zeleiloes-plataforma-backend/public/storage/products/${item.url}`} />
                      </div>
                    </div> 
                  )) : null
                }
              </aside>
            </section>
            {
              step === 2 ? (
                <button className="btn btn-primary ml-3" onClick={uploadFile} disabled={step === 2 ? false : true}>Salvar</button>
              ) : null
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default EditProduct;
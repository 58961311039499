import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import dayjs from 'dayjs';
import { Product, Store, Auction, User } from '../../api';
import Select from 'react-select'
import './style.css'

const FilterTabs = () => {
  const [value, onChange] = useState((new Date()));
  const [auctions, setAuctions] = useState([]);
  const [tab, setTab] = useState(0);
  const [leiloeiros, setLeiloeiros] = useState([]);
  const [findbydate, setFindByDate] = useState([]);
  const [states, setStates] = useState([]);
  const [name, setName] = useState([]);
  const [load, setLoad] = useState(false)

  const loadLeiloeiros = async () => {
    let response = await Store.getAll();
    if (response.status !== 'error') {
      setLeiloeiros(response)
    }
  }

  const loadFutureAuctions = async () => {
    let response = await Auction.getAllActive();
    console.log(response)
    if (response.status !== 'error') {
      setAuctions(response);
    }
  }

  const dataValue = async (value) => {
    setLoad(true)
    setTimeout(function () {
      window.location.assign(`/findByAuction?data=${value}`)
    }, 2000)
  }

  const nameValue = async (value) => {
    setLoad(true)
    setTimeout(function () {
      window.location.assign(`/fetchByActioneer?name=${value.label}`)
    }, 2000)
  }

  const stateValue = async (value) => {
    setLoad(true)
    setTimeout(function () {
      window.location.assign(`/findByState?state=${value.label}`)
    }, 2000)
  }

  const loadEstados = async () => {
    let response = await User.getAllEstados();
    if (response.status !== 'error') {
      setStates(response)
    }
  }

  const options = auctions.map((item) => {
    return {
      value: item.id,
      label: item.auctioneer_name,
    }
  })

  const optionStates = states.map((item) => {
    return {
      value: item.id,
      label: item.nome
    }
  })
  

  useEffect(() => {
    loadLeiloeiros();
    loadFutureAuctions();
    loadEstados();
  }, [])

  return (
    <div style={{ width: 300 }}>
      <ul className="nav nav-tabs" style={{ cursor: 'pointer' }}>
        <li className="nav-item">
          <span className={`nav-link  ${tab === 0 ? 'active' : ''}`} onClick={() => setTab(0)}>Data</span>
        </li>
        <li className="nav-item">
          <span className={`nav-link  ${tab === 1 ? 'active' : ''}`} onClick={() => setTab(1)}>Leiloeiro</span>
        </li>
        <li className="nav-item">
          <span className={`nav-link  ${tab === 2 ? 'active' : ''}`} onClick={() => setTab(2)}>Estado</span>
        </li>
      </ul>
      {
        tab === 0 ? (
          <div style={{ padding: 10 }}>
            <Calendar
              onActiveStartDateChange = {({ activeStartDate, view }) => {
              }}
              onChange={(e) => dataValue(dayjs(e).format('YYYY-MM-DD'))}
              tileDisabled={({activeStartDate, date, view }) => {
                return dayjs(date).isBefore(dayjs().subtract(1, 'day'))
              }}
            />
          </div>
        ) : null
      }

      {
        tab === 1 ? (
          <div style={{ padding: 10 }}>
            <span style={{ fontSize: 12 }}>Escolha um leiloeiro:</span>
            <Select 
            options={options}
            placeholder={'Selecione um leiloeiro'}
            onChange={(e) => nameValue(e)}/>
          </div>
        ) : null
      }

      {/* 
        {
          tab === 1 ? (
            <div style={{ padding: 10 }}>
              <span style={{ fontSize: 12 }}>Escolha um leiloeiro:</span>
              <select className="form-control">
                {
                  leiloeiros.map((item, index) => (
                    <option value={item.id}>{item.company_name}</option>
                  ))
                }
              </select>
            </div>
          ) : null
        }
      */}

      {
        tab === 2 ? (
          <div style={{ padding: 10 }}>
            <span style={{ fontSize: 12 }}>Escolha um estado:</span>
            <Select 
            options={optionStates}
            placeholder={'Selecione um Estado'}
            onChange={(e) => stateValue(e)}/>
          </div>
        ) : null
      }
      {/* 
      {
        tab === 2 ? (
          <div style={{ padding: 10 }}>
            <span style={{ fontSize: 12 }}>Escolha um estado:</span>
            <select className="form-control">
              <option value="">Selecione um Estado</option>
              <option value="AC">Acre</option>
              <option value="AL">Alagoas</option>
              <option value="AM">Amazonas</option>
              <option value="AP">Amapá</option>
              <option value="BA">Bahia</option>
              <option value="CE">Ceará</option>
              <option value="DF">Distrito Federal</option>
              <option value="ES">Espírito Santo</option>
              <option value="GO">Goiás</option>
              <option value="MA">Maranhão</option>
              <option value="MG">Minas Gerais</option>
              <option value="MS">Mato Grosso do Sul</option>
              <option value="MT">Mato Grosso</option>
              <option value="PA">Pará</option>
              <option value="PB">Paraíba</option>
              <option value="PE">Pernambuco</option>
              <option value="PI">Piauí</option>
              <option value="PR">Paraná</option>
              <option value="RJ">Rio de Janeiro</option>
              <option value="RN">Rio Grande do Norte</option>
              <option value="RO">Rondônia</option>
              <option value="RR">Roraima</option>
              <option value="RS">Rio Grande do Sul</option>
              <option value="SC">Santa Catarina</option>
              <option value="SP">São Paulo</option>
              <option value="SE">Sergipe</option>
              <option value="TO">Tocantins</option>
            </select>
          </div>
        ) : null
      }
      */}

    {
      load && (
        <div id="spinnertable"></div>
      )
    }
    </div>
  )
}

export default FilterTabs;
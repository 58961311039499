import API from './config';

const Store = {
  /* 
   * Second step of user SignUp request
   */
  completeSignUp: async (params) => {
    try {
      let response = await API().post(`/stores`, params);
      return await response.data;
    } catch (e) {
      return { status: 'error', message: 'Something is wrong.' };
    }
  },
  uploadMedia: async (params) => {
    try {
      let response = await API().post(`/stores/upload/logo`, params, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      });
      return await response.data;
    } catch (e) {
      return { status: 'error', message: 'Something is wrong.' };
    }
  },
  getData: async () => {
    try {
      let response = await API().get(`/stores/fetch/fromUser`);
      return await response.data;
    } catch (e) {
      return { status: 'error', message: 'Something is wrong.' };
    }
  },
  getById: async (id) => {
    try {
      let response = await API().get(`/stores/${id}`);
      return await response.data;
    } catch (e) {
      return { status: 'error', message: 'Something is wrong.' };
    }
  },
  getRandom: async (quantity) => {
    try {
      let response = await API().get(`/stores/fetch/carroussel/${quantity}`);
      return await response.data;
    } catch (e) {
      return { status: 'error', message: 'Something is wrong.' };
    }
  },
  getAll: async () => {
    try {
      let response = await API().get(`/stores`);
      return await response.data;
    } catch (e) {
      return { status: 'error', message: 'Something is wrong.' };
    }
  },
  update: async (params, id) => {
    try {
      let response = await API().put(`/stores/${id}`, params);
      return await response.data;
    } catch (e) {
      return { status: 'error', message: 'Something is wrong.' };
    }
  }
}

export default Store;
import React, { useEffect, useState } from 'react';
import { Footer, Header } from '../../../components';
import { useDropzone } from 'react-dropzone';
import './style.css';
import { FaTimes } from 'react-icons/fa';
import { Auction, Product, Store, User } from '../../../api';
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import MaskedInput from 'react-text-mask'
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { useAlert, positions, types } from "react-alert";

dayjs.extend(customParseFormat)

const numberMask = createNumberMask({
  prefix: 'R$',
  thousandsSeparator: false,
  thousandsSeparatorSymbol: '',
  allowDecimal: true,
  decimalSymbol: ',',
})

const dateMask = [
  /[0-9]/,
  /[0-9]/,
  "-",
  /[0-9]/,
  /[0-9]/,
  "-",
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  " ",
  /[0-9]/,
  /[0-9]/,
  ":",
  /[0-9]/,
  /[0-9]/,
]

const NewAuction = () => {
  const user = useSelector((state) => state.user.data);
  const alert = useAlert();

  const [users, setUsers] = useState([])
  const [product, setProduct] = useState({});
  const [types, setTypes] = useState([]);
  const [leiloeiros, setLeiloeiros] = useState([]);


  const loadProductData = async () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const prodId = urlParams.get('prod');
    if (prodId) {
      let response = await Product.getById(prodId);
      if(response.status !== 'error') {
        setProduct(response)
      }
    } else {
      window.location = '/leiloes'
    }
  }

  const loadUsers = async () => {
    let response = await User.getAllUsers();
    console.log(response)
    if (response.status !== 'error') {
      setUsers(response)
    }
  }

  const loadLeiloeiros = async () => {
    let response = await Store.getAll();
    console.log(response)
    if (response.status !== 'error') {
      setLeiloeiros(response)
    }
  }

  const submitAuction = async (event) => {
    event.preventDefault();

  
    let initial_price = parseFloat(event.target[4].value.replace(',', '.').replace('R$', ''))
    let min_bid = parseFloat(event.target[5].value.replace(',', '.').replace('R$', ''))
    //console.log(event.target[5].value)
    //console.log(event.target[6].value)
    //let auction_date = dayjs(event.target[5].value, 'DD-MM-YYYY HH:MM').format(event.target[5].value, 'YYYY-MM-DD HH:MM')
    //console.log(auction_date)
    //let final_date = dayjs(event.target[6].value, 'DD-MM-YYYY HH:MM').format(event.target[6].value, 'YYYY-MM-DD HH:MM')
    

    let obj = {
      auction_name: event.target[3].value,
      auctioneer_name: event.target[1].value,
      auctioneer_description: event.target[2].value,
      product_id: product.id,
      initial_price,
      min_bid,
      auction_date: event.target[6].value,
      final_date: event.target[7].value,
      state: event.target[8].value,
      active: true
    }
    let response = await Auction.create(obj);
    if(response.status !== 'error'){
      alert.success('Leilão cadastrado!')
      window.location.href = '/minha-loja'
    } else {
      console.log(response)
      alert.error('Erro! Contatar o suporte!')
    }
  }

  useEffect(() => {
    loadUsers()
    loadLeiloeiros()
    loadProductData()
  }, [])

  return (
    <>
      <Header />

      <div className="container pt-5 pb-5 full-height-container">
        <h3>Criar Leilão</h3>

        <div className="row d-flex justify-content-center">
          <div className="col-6">
            <form onSubmit={submitAuction}>
              <div className="form-group">
                <label>Item</label>
                <input className="form-control" value={product.name} disabled />
              </div>
              <div className="form-group">
                <label>Nome do leiloeiro</label>
                <input className="form-control" />
              </div>
              <div className="form-group">
                <label>Descrição do Produto</label>
                <textarea id="story" name="story"rows="5" cols="33"></textarea>
              </div>
              
              <div className="form-group">
                <label>Nome</label>
                <input className="form-control" />
              </div>
              <div className="form-group">
                <label>Preço inicial</label>
                <MaskedInput
                  required
                  mask={numberMask}
                  className="contact form-control"
                  placeholder="Insira o valor aqui"
                />
              </div>
              <div className="form-group">
                <label>Oferta mínima</label>
                <MaskedInput
                  required
                  mask={numberMask}
                  className="contact form-control"
                  placeholder="Insira o valor aqui"
                />
              </div>
              <div className="form-group">
                <label>Data do leilão</label>
                <MaskedInput 
                required
                mask={dateMask}
                className="form-control"
                placeholder="Insira a data + hora inicial"
                />
              </div>
              <div className="form-group">
                <label>Data final</label>
                <MaskedInput 
                required
                mask={dateMask}
                className="form-control"
                placeholder="Insira a data + hora final"
                />
              </div>
              <div className="form-group">
                <label>Estado do Leilão</label>
                <select id="estado" name="estado" className="custom-select" required>
                    <option value="Acre">Acre</option>
                    <option value="Alagoas">Alagoas</option>
                    <option value="Amapá">Amapá</option>
                    <option value="Amazonas">Amazonas</option>
                    <option value="Bahia">Bahia</option>
                    <option value="Ceará">Ceará</option>
                    <option value="Distrito Federal">Distrito Federal</option>
                    <option value="Espírito Santo">Espírito Santo</option>
                    <option value="Goiás">Goiás</option>
                    <option value="Maranhão">Maranhão</option>
                    <option value="Mato Grosso">Mato Grosso</option>
                    <option value="Mato Grosso do Sul">Mato Grosso do Sul</option>
                    <option value="Minas Gerais">Minas Gerais</option>
                    <option value="Pará">Pará</option>
                    <option value="Paraíba">Paraíba</option>
                    <option value="Paraná">Paraná</option>
                    <option value="Pernambuco">Pernambuco</option>
                    <option value="Piauí">Piauí</option>
                    <option value="Rio de Janeiro">Rio de Janeiro</option>
                    <option value="Rio Grande do Norte">Rio Grande do Norte</option>
                    <option value="Rio Grande do Sul">Rio Grande do Sul</option>
                    <option value="Rondônia">Rondônia</option>
                    <option value="Roraima">Roraima</option>
                    <option value="Santa Catarina">Santa Catarina</option>
                    <option value="São Paulo">São Paulo</option>
                    <option value="Sergipe">Sergipe</option>
                    <option value="Tocantins">Tocantins</option>
                </select>
              </div>
              <button type="submit" className="btn btn-primary">Salvar</button>
            </form>
          </div>
        </div>

      </div>
    </>
  )
}

export default NewAuction;
import React, { useState, useEffect } from 'react';
import {
    Container, Col, Media, Dropdown, InputGroup, FormControl,
    Navbar, Nav, NavDropdown, Row, Button
  } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './style.css';

import LogoZe from '../../../assets/logotransparente-horizontal.png'
import AuctioneerHeader from '../../../components/AuctioneerHeader';
import AdvertisingWhite from '../../../components/Advertising/AdvertisingWhite';
import { faBars, faBook, faCalendar, faCaretRight, faChevronDown, faChevronLeft, faChevronRight, faChevronUp, faCogs, faEnvelope, faEye, faGavel, faList, faListUl, faRss, faSearch, faSignInAlt, faStar, faTable, faTh, faTruck } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faFacebookF, faInstagram, faPinterest, faTwitter, faWhatsapp } from '@fortawesome/free-brands-svg-icons';



const Catalog = () => {

    function showGrid () {
        console.log("Mostrar Grid")
        var grid = document.getElementById("grid");
        var lista = document.getElementById("lista");
        lista.setAttribute('hidden', '');
        grid.removeAttribute('hidden')

        var gridActive = document.getElementById("gridactive");
        var listaActive = document.getElementById("listactive");
        
        gridActive.classList.add('active')
        listaActive.classList.remove('active')
    }
    
    function showLista () {
        console.log("Mostrar Lista")
        var grid = document.getElementById("grid");
        var lista = document.getElementById("lista");
        grid.classList.remove('active');
        lista.classList.add('active');
        grid.setAttribute('hidden', '');
        lista.removeAttribute('hidden')

        var gridActive = document.getElementById("gridactive");
        var listaActive = document.getElementById("listactive");
        
        gridActive.classList.remove('active')
        listaActive.classList.add('active')
    }
    
    return (
        <>
            <AuctioneerHeader/>
            <Container id="content" className="is-catalogo">
                <Row>
                    <Col md={12}>
                    <div class="inner-content-title">
                        <div class="row is-breadcrumb">
                            <div class="twelve columns">
                                <span class="is-breadlabel">Navegação:</span>
                                <ul>
                                    <li>
                                        <a href="#">Home</a>
                                        <FontAwesomeIcon icon={faChevronRight} className="iconRight" />
                                    </li>
                                    <li>
                                        <a href="#">Lista de Catálogos</a>
                                        <FontAwesomeIcon icon={faChevronRight} className="iconRight" />
                                    </li>
                                    <li>
                                        <a href="#">Leilão 22330</a>
                                        <FontAwesomeIcon icon={faChevronRight} className="iconRight" />
                                    </li>
                                    <li>Catálogo de peças</li>
                                </ul>
                            </div>
                        </div>
                        <div class="row">
                            <Col md={12} className="cat-title">
                                <h2>
                                    <span>Catálogo de peças:</span>
                                    Leilão 22330 - Leilão Bata-me o Martelo-Vinis raros para colecionadores exigentes
                                </h2>

                                <div class="cat-title-btns">
                                <a href="#" class="more-info-btn">
                                    <span class="more-info-span"><FontAwesomeIcon icon={faChevronUp}/> Menos informações</span>
                                    <span class="less-info-span"> <FontAwesomeIcon icon={faChevronDown}/>  Mais informações </span>
                                </a>
                                <a href="#" class="fb-share">
                                    <FontAwesomeIcon icon={faFacebook}/>  
                                </a>
                                </div>
                            </Col>
                        </div>
                    </div>
                    <div className="inner-content-container">
                        <div className="catalog">
                            <div className="row destaque-row">
                                <div class="twelve columns">
                                    <div class="destaque-wrap">
                                    <p id="encontrados"><span class="find-numb">840</span> Itens encontrados</p>
                                        <div class="paginacao">
                                        <p>Página:</p>
                                            <ul>
                                                
                                                <li><span><FontAwesomeIcon icon={faChevronLeft}/></span></li>
                                                <li class="fixedpag"><span>Primeira</span></li>
                                                
                                                <li class="dotdot"><span>...</span></li>
                                                
                                                <li class="ativo"><span>1</span></li>
                                                
                                                <li><a href="#">2</a></li>
                                                
                                                <li><a href="#">3</a></li>
                                                
                                                <li><a href="#">4</a></li>
                                                
                                                <li class="dotdot"><span>...</span></li>
                                                
                                                <li class="fixedpag"><a href="#">Última</a></li>
                                                <li class="nextarrow"><a href="#" class="proximo"><FontAwesomeIcon icon={faChevronRight}/></a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="three columns mobile-four sidebar-tools open">
                                    <div className="mc-menu">
                                        <div className="tools-container">
                                            <div className="row">
                                                <div className="twelve columns ToolBox-wrap">
                                                    <div className="ToolBox ShowBox">
                                                        <div class="section-title">
                                                            <h2><span><FontAwesomeIcon icon={faCogs} className="mr-2"/>Ferramentas</span></h2>
                                                        </div>
                                                        <div class="row">
                                                            <div class="twelve columns ContentToolBox">
                                                                <ul class="is-filtro is-ferramenta">
                                                                    <li class="twelve columns mobile-four">
                                                                        <a href="#" class="ativo">
                                                                            <label id="totcatalogo"><FontAwesomeIcon icon={faBook} className="mr-1 ml-1"/><span>Catalogo ( 840 )</span></label>
                                                                        </a>
                                                                    </li>
                                                                    <li class="twelve columns mobile-four">
                                                                        <a href="#">
                                                                            <label id="totcatalogo">
                                                                                <FontAwesomeIcon icon={faGavel} className="mr-1 ml-1"/>Meus Lances (0)
                                                                            </label>
                                                                        </a>
                                                                    </li>
                                                                    <li class="twelve columns mobile-four">
                                                                        <a href="#">
                                                                            <label id="totfavoritos">
                                                                                <FontAwesomeIcon icon={faStar} className="mr-1 ml-1"/> 
                                                                                <span>Favoritos ( 0 )</span>
                                                                            </label>
                                                                        </a>
                                                                    </li>
                                                                    <li class="twelve columns mobile-four">
                                                                        <a href="#">
                                                                            <label id="totvigias">
                                                                                <FontAwesomeIcon icon={faEye} className="mr-1 ml-1"/>
                                                                                <span>Vigiados ( 0 )</span>
                                                                            </label>
                                                                        </a>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="twelve columns ToolBox-wrap">
                                                                <div className="ToolBox ShowBox">
                                                                    <div class="section-title">
                                                                        <h2>
                                                                            <span><FontAwesomeIcon icon={faTable}/> Navegação</span>
                                                                        </h2>
                                                                    </div>
                                                                    <div class="row">
                                                                        <div class="twelve columns ContentToolBox">
                                                                            <ul class="is-filtro">
                                                                                <li class="hide-for-small">
                                                                                <a id="listactive" href="#" class="grid-options active" onClick={showLista}>
                                                                                    <FontAwesomeIcon icon={faList} className="mr-2"/>Lista
                                                                                </a>
                                                                                <a id="gridactive" href="#" class="grid-options" onClick={showGrid}>
                                                                                    <FontAwesomeIcon icon={faTh} className="mr-2"/>Grid
                                                                                </a>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="twelve columns ToolBox-wrap">
                                                                <div class="ToolBox ShowBox">

                                                                <div class="section-title">
                                                                    <h2>
                                                                        <span>
                                                                            <FontAwesomeIcon icon={faCalendar} className="custom-padding"/> Dias do Leilão
                                                                        </span>
                                                                    </h2>
                                                                </div>

                                                                <div class="row">
                                                                    <div class="twelve columns ContentToolBox">
                                                                        <ul class="UserTools is-Submit">
                                                                        
                                                                        <li>                              
                                                                            <label for="dia1">
                                                                            <input type="radio" id="dia1" name="dia" value="1" class="checkbox dia"/>
                                                                            <a style={{padding:'0'}} href="#" title="">1º DIA - 1/9/2021  - 19:00</a>
                                                                            </label>
                                                                        </li>
                                                                        
                                                                        <li>                              
                                                                            <label for="dia2">
                                                                            <input type="radio" id="dia2" name="dia" value="2" class="checkbox dia"/>
                                                                            <a style={{padding:'0'}} href="#" title="">2º DIA - 2/9/2021  - 19:00</a>
                                                                            </label>
                                                                        </li>
                                                                        
                                                                        <li>                              
                                                                            <label for="dia3">
                                                                            <input type="radio" id="dia3" name="dia" value="3" class="checkbox dia"/>
                                                                            <a style={{padding:'0'}} href="#" title="">3º DIA - 3/9/2021  - 19:00</a>
                                                                            </label>
                                                                        </li>
                                                                        
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="twelve columns ToolBox-wrap">
                                                                <div class="ToolBox ShowBox">

                                                                <div class="section-title">
                                                                    <h2><span><FontAwesomeIcon icon={faListUl} className="custom-padding"/> Escolha pelo Tipo</span></h2>
                                                                </div>

                                                                <div class="row">
                                                                    <div class="twelve columns ContentToolBox">
                                                                        <ul class="UserTools is-Submit">
                                                                        <li>
                                                                            <label for="tipo102"><input type="checkbox" id="tipo102" name="tipo" value="102" class="checkbox tipo tipo102"/> Eletrônicos (3)</label>
                                                                        </li>
                                                                        <li>
                                                                            <label for="tipo34"><input type="checkbox" id="tipo34" name="tipo" value="34" class="checkbox tipo tipo34"/> Música (837)</label>
                                                                        </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div class="twelve columns ToolBox-wrap">
                                                                <div class="ToolBox ShowBox">

                                                                <div class="section-title">
                                                                    <h2><span><FontAwesomeIcon icon={faTruck} className="mr-2"/>Pagamento/Frete</span></h2>
                                                                </div>

                                                                <div class="row">
                                                                <div class="twelve columns ContentToolBox">
                                                                    <p>Terminado o leilão, enviamos e-mail aos arrematantes vencedores com os dados de conta bancária do leiloeiro para depósito em até 72 horas.</p>
                                                                    <p>Confirmado o depósito de pagamento dos lotes arrematados, fornecemos informações de opções de valores de frete, inclusive, se for o caso, via correio.</p>
                                                                </div>
                                                                </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="twelve columns ToolBox-wrap">
                                                                <div class="ToolBox ShowBox">

                                                                <div class="section-title">
                                                                    <h2><span><FontAwesomeIcon icon={faEnvelope} className="mr-2"/>Convite por E-mail</span></h2>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="twelve columns ContentToolBox">
                                                                        <form method="post" name="convite">
                                                                            <ul class="NewsletterForm">
                                                                                <li><label class="is-label">Receba o convite de nossos leilões por email</label></li>
                                                                                <li><input id="emailconvite" type="text" name="arroba" value="Digite seu email" class="EmailNewsletter w-100 mb-2 mt-1"/></li>
                                                                                <li><input type="submit" name="botao" value="Cadastrar" class="gar Botao"/></li>
                                                                            </ul>
                                                                        </form>
                                                                    </div>
                                                                </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="twelve columns ToolBox-wrap">
                                                                <div class="ToolBox ShowBox media-container">

                                                                <div class="powered-by">
                                                                    <a href="#"><img src={LogoZe} style={{width: '250px'}}/></a>
                                                                </div>
                                                                <div class="social-media">
                                                                    <ul>
                                                                    <li class=""><a href="#"><FontAwesomeIcon icon={faFacebook} className="i"/></a></li>
                                                                    <li class=""><a href="#"><FontAwesomeIcon icon={faTwitter} className="i"/></a></li>
                                                                    <li class=""><a href="#"><FontAwesomeIcon icon={faPinterest} className="i"/></a></li>
                                                                    <li class=""><a href="#"><FontAwesomeIcon icon={faInstagram} className="i"/></a></li>
                                                                    <li class=""><a href="#"><FontAwesomeIcon icon={faRss} className="i"/></a></li>
                                                                    </ul>
                                                                </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="nine columns catalog-content">
                                    <div className="row is-catalog-content-tools">
                                       <div class="columns view-options-search hide-for-small">
                                           <form>
                                               <div className="search-container">
                                                   <div className="row">
                                                   <div class="columns input-spacement">
                                                        <input id="pesquisacat" name="pesquisa" type="text" class="is-InputText" placeholder="Digite sua pesquisa" value=""/>
                                                        <button class="Botao-novo mr-2"><FontAwesomeIcon icon={faSearch}/></button>
                                                    </div>
                                                   </div>
                                               </div>
                                           </form>
                                       </div>
                                       <div class="columns is-irparalote hide-for-small">
                                            <form >
                                                <div class="row">
                                                    <div class="columns input-spacement">
                                                        <input id="irpara" name="irpara" placeholder="Nº do lote" value="" type="text" class="is-InputText"/>
                                                        <button class="Botao-novo" value=""><FontAwesomeIcon icon={faCaretRight}/></button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                        <div class="columns ordernar-box hide-for-small">
                                            <form name="FilterBy">
                                            <label class="is-label">Ordenar:
                                            <select id="ordenar" class="is-Select">
                                                <option value="1">Lote</option>
                                                <option value="2">Maior Valor</option>
                                                <option value="3">Menor Valor</option>
                                                <option value="5">Mais Lançados</option>
                                                <option value="4">Menos Lançados</option>
                                                <option value="10">Mais Visitados</option>
                                                <option value="11">Menos Visitados</option>
                                            </select>
                                            </label>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div class="twelve columns results">
                                            <ul id="lista" class="list">
                                                <li>
                                                    <div className="row">
                                                    <div class="four columns">
                                                        <div class="product-image zoom_01" data-zoom-image="">
                                                            <a href="">
                                                                <img src="https://d1o6h00a1h5k7q.cloudfront.net/imagens/img_m/22330/10601728.jpg" value="" border="0" alt=""/>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div class="eight columns product-content-container hide-for-small">
                                                            <div class="row product-content">
                                                            <div class="twelve columns top-line">
                                                                <div class="row">
                                                                <div class="nine columns loteestatus">
                                                                    <a href="#" id="lote1"><span>1</span></a>  
                                                                </div>
                                                                <div class="three columns">
                                                                    <div class="fav-watch-btns">
                                                                    <a href="#" data-watch="10601728,1" class="watch V10601728" data-width="210" data-tippy="Vigiar peça - Você receberá um email para cada lance efetuado no lote. (Exceto durante o pregão)"><i class="fa fa-eye icon"></i></a>
                                                                    <a href="#" class="fav F10601728" data-fav="10601728,1" data-tippy="Favoritar peça"><i class="fa fa-star icon"></i></a>
                                                                    </div>
                                                                </div>
                                                                </div>
                                                            </div>
                                                            <div class="twelve columns">
                                                                <div class="row">
                                                                <div class="twelve columns product-description">
                                                                    <h3>
                                                                    <a href="#">ÁLBUM DUPLO DE CAPA DUPLA: STEVIE WONDER "Songs in the Key of Life". Brilhante trabalho deste cantor e compositor americano. O exemplar contém o grande folheto de 24p., mas não possui o compacto que também vinha ...</a>
                                                                    </h3>
                                                                </div>
                                                                <div class="twelve columns price-bid-custom-padding">
                                                                    <div class="visits">
                                                                    <p><b>Visitas:</b> 45</p>
                                                                    </div>
                                                                    <div class="product-price-bid">
                                                                    <p><strong>
                                                                        7
                                                                    </strong> Lance(s)</p>
                                                                    </div>
                                                                </div>
                                                                </div>
                                                            </div>
                                                            

                                                            <div class="twelve columns product-price-bid-new">
                                                                <div class="row">
                                                                <div class="twelve columns product-toolbox">
                                                                    <div class="product-price-bid">

                                                                    
                                                                        <p>Valor atual:</p>
                                                                    
                                                                    <p class="price-bid">R$ 60.00</p>
                                                                    
                                                                    </div>
                                                                    <div class="lote-status">
                                                                    
                                                                        <div class="is-CoolBtn lote-control">
                                                                        <a href="#">Registrar lance</a>
                                                                        </div>
                                                                    
                                                                    </div>

                                                                </div>
                                                                </div>
                                                            </div>
                                                            <div class="twelve columns product-toolbox">
                                                                <div class="row">
                                                                <div class="eight columns mobile-four tools" sytle={{display: 'none'}}>
                                                                    <div class="fav-watch-btns">
                                                                    <a href="#" class="fav F10601728" data-fav="10601728,1" data-tippy="Favoritar peça"><i class="fa fa-star icon"></i><span class="L10601728"></span></a>
                                                                    <a href="#" data-watch="10601728,1" class="watch V10601728" data-width="210"><i class="fa fa-eye icon"></i><span class="G10601728"></span></a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="row">
                                                    <div class="four columns">
                                                        <div class="product-image zoom_01" data-zoom-image="">
                                                            <a href="">
                                                                <img src="https://d1o6h00a1h5k7q.cloudfront.net/imagens/img_m/22330/10601728.jpg" value="" border="0" alt=""/>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div class="eight columns product-content-container hide-for-small">
                                                            <div class="row product-content">
                                                            <div class="twelve columns top-line">
                                                                <div class="row">
                                                                <div class="nine columns loteestatus">
                                                                    <a href="#" id="lote1"><span>1</span></a>  
                                                                </div>
                                                                <div class="three columns">
                                                                    <div class="fav-watch-btns">
                                                                    <a href="#" data-watch="10601728,1" class="watch V10601728" data-width="210" data-tippy="Vigiar peça - Você receberá um email para cada lance efetuado no lote. (Exceto durante o pregão)"><i class="fa fa-eye icon"></i></a>
                                                                    <a href="#" class="fav F10601728" data-fav="10601728,1" data-tippy="Favoritar peça"><i class="fa fa-star icon"></i></a>
                                                                    </div>
                                                                </div>
                                                                </div>
                                                            </div>
                                                            <div class="twelve columns">
                                                                <div class="row">
                                                                <div class="twelve columns product-description">
                                                                    <h3>
                                                                    <a href="#">ÁLBUM DUPLO DE CAPA DUPLA: STEVIE WONDER "Songs in the Key of Life". Brilhante trabalho deste cantor e compositor americano. O exemplar contém o grande folheto de 24p., mas não possui o compacto que também vinha ...</a>
                                                                    </h3>
                                                                </div>
                                                                <div class="twelve columns price-bid-custom-padding">
                                                                    <div class="visits">
                                                                    <p><b>Visitas:</b> 45</p>
                                                                    </div>
                                                                    <div class="product-price-bid">
                                                                    <p><strong>
                                                                        7
                                                                    </strong> Lance(s)</p>
                                                                    </div>
                                                                </div>
                                                                </div>
                                                            </div>
                                                            

                                                            <div class="twelve columns product-price-bid-new">
                                                                <div class="row">
                                                                <div class="twelve columns product-toolbox">
                                                                    <div class="product-price-bid">

                                                                    
                                                                        <p>Valor atual:</p>
                                                                    
                                                                    <p class="price-bid">R$ 60.00</p>
                                                                    
                                                                    </div>
                                                                    <div class="lote-status">
                                                                    
                                                                        <div class="is-CoolBtn lote-control">
                                                                        <a href="#">Registrar lance</a>
                                                                        </div>
                                                                    
                                                                    </div>

                                                                </div>
                                                                </div>
                                                            </div>
                                                            <div class="twelve columns product-toolbox">
                                                                <div class="row">
                                                                <div class="eight columns mobile-four tools" sytle={{display: 'none'}}>
                                                                    <div class="fav-watch-btns">
                                                                    <a href="#" class="fav F10601728" data-fav="10601728,1" data-tippy="Favoritar peça"><i class="fa fa-star icon"></i><span class="L10601728"></span></a>
                                                                    <a href="#" data-watch="10601728,1" class="watch V10601728" data-width="210"><i class="fa fa-eye icon"></i><span class="G10601728"></span></a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="row">
                                                    <div class="four columns">
                                                        <div class="product-image zoom_01" data-zoom-image="">
                                                            <a href="">
                                                                <img src="https://d1o6h00a1h5k7q.cloudfront.net/imagens/img_m/22330/10601728.jpg" value="" border="0" alt=""/>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div class="eight columns product-content-container hide-for-small">
                                                            <div class="row product-content">
                                                            <div class="twelve columns top-line">
                                                                <div class="row">
                                                                <div class="nine columns loteestatus">
                                                                    <a href="#" id="lote1"><span>1</span></a>  
                                                                </div>
                                                                <div class="three columns">
                                                                    <div class="fav-watch-btns">
                                                                    <a href="#" data-watch="10601728,1" class="watch V10601728" data-width="210" data-tippy="Vigiar peça - Você receberá um email para cada lance efetuado no lote. (Exceto durante o pregão)"><i class="fa fa-eye icon"></i></a>
                                                                    <a href="#" class="fav F10601728" data-fav="10601728,1" data-tippy="Favoritar peça"><i class="fa fa-star icon"></i></a>
                                                                    </div>
                                                                </div>
                                                                </div>
                                                            </div>
                                                            <div class="twelve columns">
                                                                <div class="row">
                                                                <div class="twelve columns product-description">
                                                                    <h3>
                                                                    <a href="#">ÁLBUM DUPLO DE CAPA DUPLA: STEVIE WONDER "Songs in the Key of Life". Brilhante trabalho deste cantor e compositor americano. O exemplar contém o grande folheto de 24p., mas não possui o compacto que também vinha ...</a>
                                                                    </h3>
                                                                </div>
                                                                <div class="twelve columns price-bid-custom-padding">
                                                                    <div class="visits">
                                                                    <p><b>Visitas:</b> 45</p>
                                                                    </div>
                                                                    <div class="product-price-bid">
                                                                    <p><strong>
                                                                        7
                                                                    </strong> Lance(s)</p>
                                                                    </div>
                                                                </div>
                                                                </div>
                                                            </div>
                                                            

                                                            <div class="twelve columns product-price-bid-new">
                                                                <div class="row">
                                                                <div class="twelve columns product-toolbox">
                                                                    <div class="product-price-bid">

                                                                    
                                                                        <p>Valor atual:</p>
                                                                    
                                                                    <p class="price-bid">R$ 60.00</p>
                                                                    
                                                                    </div>
                                                                    <div class="lote-status">
                                                                    
                                                                        <div class="is-CoolBtn lote-control">
                                                                        <a href="#">Registrar lance</a>
                                                                        </div>
                                                                    
                                                                    </div>

                                                                </div>
                                                                </div>
                                                            </div>
                                                            <div class="twelve columns product-toolbox">
                                                                <div class="row">
                                                                <div class="eight columns mobile-four tools" sytle={{display: 'none'}}>
                                                                    <div class="fav-watch-btns">
                                                                    <a href="#" class="fav F10601728" data-fav="10601728,1" data-tippy="Favoritar peça"><i class="fa fa-star icon"></i><span class="L10601728"></span></a>
                                                                    <a href="#" data-watch="10601728,1" class="watch V10601728" data-width="210"><i class="fa fa-eye icon"></i><span class="G10601728"></span></a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="row">
                                                    <div class="four columns">
                                                        <div class="product-image zoom_01" data-zoom-image="">
                                                            <a href="">
                                                                <img src="https://d1o6h00a1h5k7q.cloudfront.net/imagens/img_m/22330/10601728.jpg" value="" border="0" alt=""/>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div class="eight columns product-content-container hide-for-small">
                                                            <div class="row product-content">
                                                            <div class="twelve columns top-line">
                                                                <div class="row">
                                                                <div class="nine columns loteestatus">
                                                                    <a href="#" id="lote1"><span>1</span></a>  
                                                                </div>
                                                                <div class="three columns">
                                                                    <div class="fav-watch-btns">
                                                                    <a href="#" data-watch="10601728,1" class="watch V10601728" data-width="210" data-tippy="Vigiar peça - Você receberá um email para cada lance efetuado no lote. (Exceto durante o pregão)"><i class="fa fa-eye icon"></i></a>
                                                                    <a href="#" class="fav F10601728" data-fav="10601728,1" data-tippy="Favoritar peça"><i class="fa fa-star icon"></i></a>
                                                                    </div>
                                                                </div>
                                                                </div>
                                                            </div>
                                                            <div class="twelve columns">
                                                                <div class="row">
                                                                <div class="twelve columns product-description">
                                                                    <h3>
                                                                    <a href="#">ÁLBUM DUPLO DE CAPA DUPLA: STEVIE WONDER "Songs in the Key of Life". Brilhante trabalho deste cantor e compositor americano. O exemplar contém o grande folheto de 24p., mas não possui o compacto que também vinha ...</a>
                                                                    </h3>
                                                                </div>
                                                                <div class="twelve columns price-bid-custom-padding">
                                                                    <div class="visits">
                                                                    <p><b>Visitas:</b> 45</p>
                                                                    </div>
                                                                    <div class="product-price-bid">
                                                                    <p><strong>
                                                                        7
                                                                    </strong> Lance(s)</p>
                                                                    </div>
                                                                </div>
                                                                </div>
                                                            </div>
                                                            

                                                            <div class="twelve columns product-price-bid-new">
                                                                <div class="row">
                                                                <div class="twelve columns product-toolbox">
                                                                    <div class="product-price-bid">

                                                                    
                                                                        <p>Valor atual:</p>
                                                                    
                                                                    <p class="price-bid">R$ 60.00</p>
                                                                    
                                                                    </div>
                                                                    <div class="lote-status">
                                                                    
                                                                        <div class="is-CoolBtn lote-control">
                                                                        <a href="#">Registrar lance</a>
                                                                        </div>
                                                                    
                                                                    </div>

                                                                </div>
                                                                </div>
                                                            </div>
                                                            <div class="twelve columns product-toolbox">
                                                                <div class="row">
                                                                <div class="eight columns mobile-four tools" sytle={{display: 'none'}}>
                                                                    <div class="fav-watch-btns">
                                                                    <a href="#" class="fav F10601728" data-fav="10601728,1" data-tippy="Favoritar peça"><i class="fa fa-star icon"></i><span class="L10601728"></span></a>
                                                                    <a href="#" data-watch="10601728,1" class="watch V10601728" data-width="210"><i class="fa fa-eye icon"></i><span class="G10601728"></span></a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                            <ul hidden id="grid" class="list row">
                                                <li class="four columns grid-box">
                                                <div class="prod-box ativo-box">
                                                <div class="row">
                                                    <div class="twelve columns status-prod">
                                                    <p>
                                                        
                                                        <a class="aovivo-status" href="#" target="_blank" data-tippy="Leilão ao vivo ">
                                                        Ao vivo 
                                                        
                                                        <i class="fa fa-rss" aria-hidden="true"></i>
                                                        </a> 
                                                        
                                                    </p>
                                                    <div class="fav-watch-btns catalogo-fav-watch">
                                                        <a href="#" data-watch="10601728,1" class="watch V10601728 has-tip tip-top noradius" data-width="210" title="Você receberá um email para cada lance efetuado no lote. (Exceto durante o pregão)"><i class="fa fa-eye icon"></i><span class="G10601728"></span></a>
                                                        <a href="#" class="fav F10601728" data-fav="10601728,1"><i class="fa fa-star icon"></i><span class="L10601728"></span></a>
                                                    </div>
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <div class="twelve columns">
                                                    <div id="img-Lote-1" class="img-box-wrap">
                                                        <div class="img-box-inner-search img-box-inner-desktop">
                                                        <a class="MYCLASS" title="Lote-1" href="peca.asp?ID=10601728&amp;ctd=1&amp;tot=&amp;tipo=" data-zoom-image="">
                                                            <img src="https://d1o6h00a1h5k7q.cloudfront.net/imagens/img_m/22330/10601728.jpg" value="peca.asp?ID=10601728&amp;ctd=1&amp;tot=&amp;tipo=" border="0" alt=""/>
                                                        </a>
                                                        </div>
                                                    </div>
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <div class="twelve columns">
                                                    <div class="LoteProd">
                                                    <a href="peca.asp?ID=10601728&amp;ctd=1&amp;tot=&amp;tipo="><span>Lote:1</span></a>
                                                    <div class="extra-info-lance"><span>46</span> Visita(s)</div>
                                                    </div>
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <div class="twelve columns">
                                                    <div class="prod-title">
                                                        <h3><a href="peca.asp?ID=10601728&amp;ctd=1&amp;tot=&amp;tipo=" title="">ÁLBUM DUPLO DE CAPA DUPLA: STEVIE WONDER "Songs in the Key of Life". Brilhante trabalho deste cantor e compositor americano. O exemplar ...</a></h3>
                                                    </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    
                                                    <div class="twelve columns product-price-bid-new">
                                                        
                                                        <div class="row">
                                                        <div class="twelve columns product-toolbox price-cotacao-toolbox">
                                                            <div class="price-cotacao-wrap">
                                                            </div>  
                                                        </div>
                                                        </div>
                                                    </div>
                                                    <div class="twelve columns">
                                                    <div class="product-price-bid search-login product-price-bid-new">
                                                        <p>Valor atual:<span style={{marginLeft:'5px', fontWeight:'normal'}}>( <b>7 Lance(s)</b> )</span></p>
                                                        <p class="price-bid">R$ 60.00</p>
                                                    </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="twelve columns">
                                                        <div class="vermais-btn">
                                                            <a href="peca.asp?ID=10601728&amp;ctd=1&amp;tot=&amp;tipo=" class="is-Button">Registrar lance</a>
                                                        </div>
                                                    </div>
                                                </div>
                                                </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    </Col>
                </Row>
            </Container>
            <div class="lightgray-background">
                <div className="container">
                    <div className="row">
                        <div class="hidden-info hidden-info2">
                        <div class="six columns">
                            <div class="row info-gerais-row">
                            <div class="twelve columns main-title py-3">
                                <h2>Informações gerais</h2>
                            </div>
                            <div class="twelve columns">
                                <h3>Ze leilões Leiloeiro</h3>
                                <p>
                                <b>Atendimento</b><br/>
                                Atendimento 24 horas de Seg. a Sex;
                                </p>
                                <p>
                                <b>Endereço</b><br/>
                                Rua Morada Nova, 173 loja B - Barro duro <br/> Maceió - AL, CEP: 57045-068
                                </p>
                                <p>
                                <b>Telefone</b><br/>
                                    (82) 3328-5587
                                </p>
                                <div class="vermais-btn">
                                <a href="#" class="info-btn"><FontAwesomeIcon icon={faEnvelope} className="mr-2"/> Entre em contato</a>
                                </div>
                                <div class="vermais-btn">
                                <a href="#" class="info-btn"><FontAwesomeIcon icon={faSignInAlt} className="mr-2"/>Faça seu cadastro</a>
                                </div>
                            </div>
                            </div>
                                <div class="row">  
                                <div class="twelve columns main-title">
                                    <h2>Informações do leilão</h2>
                                </div>
                                <div class="twelve columns">
                                    <div class="section-title">
                                    <h2><span><i class="fa fa-calendar" aria-hidden="true"></i> Exposição</span></h2>
                                    </div>
                                    <span>
                                        <p>ESTAMOS RESPEITANDO O ISOLAMENTO SOCIAL DEVIDO À PANDEMIA (Sugerimos que façam suas perguntas por telefone ou por e-mail para evitarmos riscos desnecessários)</p>
                                        <p>TELEFONE: (82) 3328-5587. Caio Barbosa</p>
                                        <p>E-MAIL: compraquenosquervender@gmail.com</p>
                                    </span>
                                </div>
                                <div class="twelve columns">
                                    <div class="section-title">
                                    <h2><span><i class="fa fa-calendar" aria-hidden="true"></i> Leilão</span></h2>
                                    </div>
                                    <span>
                                    <p><strong>Dias 01, 02 e 03 de setembro</strong></p>
                                    <p><strong>Quarta, quinta e sexta às 19 horas.</strong></p>
                                    <p>&nbsp;</p>
                                    <p>PRAZO PARA A RETIRADA:</p>
                                    <p>Dias 06, 08, 09 e 10 de setembro das 11 às 17 horas (podendo agendar a retirada após essa data).</p>
                                    <p>OBS: SÓ EFETUE LANCES SE ESTIVER CERTO DE PODER ARCAR COM OS CUSTOS DO ENVIO, CASO NÃO POSSA RETIRAR PESSOALMENTE A MERCADORIA. NÃO EFETUAREMOS CANCELAMENTOS DE LANCES SEM JUSTA CAUSA APÓS O LEILÃO.</p>
                                    <p>Aconselhamos aos interessados a pedir orçamento dos correios para PAC ou SEDEX (que serão acrescidas do valor do material para embalagem) .</p>
                                    <p>&nbsp;</p>
                                    <p>ATENÇÃO:REGRAS NOVAS DOS CORREIOS BRASILEIROS</p>
                                    <p>PARA TODOS OS PACOTES QUE ULTRAPASSAREM A MEDIDA DE 70 CM E TODOS E OS PACOTES EM FORMA CIRCULAR SERÁ COBRADO O ENVIO + R$ 79,00</p>
                                    <p>&nbsp;</p>
                                    <p><strong>ORGANIZAÇÃO: Caio Barbosa e Turma</strong></p>
                                    </span>
                                </div>
                                <div class="twelve columns">
                                    <div class="section-title">
                                    <h2><span><i class="fa fa-map-marker" aria-hidden="true"></i> Local</span></h2>
                                    </div>
                                    <span><p>Rua Morada Nova, 173 - Barro Duro - Rio de Janeiro</p></span>
                                </div>
                                </div>
                            </div>
                            <div class="six columns">
                                <div class="row">
                                <div class="twelve columns is-termos">
                                    <div class="section-title">
                                    <h2><span>Termos e condições</span></h2>
                                    </div>
                                    <div class="is-termoswrap">
                                    <span><p>O presente instrumento, denominado "Termos e Condições do Leilão", tem por objetivo regular a participação de usuários (arrematantes) no sistema online de leilões.</p>
                                    <p>1. As obras que compõem o presente LEILÃO foram periciadas pelos organizadores que, solidários com os proprietários das mesmas, se responsabilizam por suas descrições.</p>
                                    <p>2. Em caso eventual de engano na expertise de obras, comprovado por peritos idôneos, e mediante laudo assinado, ficará desfeita a venda, desde que a reclamação seja feitaem até 5 dias após o fim do leilão e/ou acesso à mercadoria. Findo este prazo, não mais serão admitidas quaisquer reclamação, considerando-se definitiva a venda.</p>
                                    <p>3. Obras estrangeiras serão sempre vendidas como "Atribuídas".</p>
                                    <p>4. O Leiloeiro(a) não é proprietário dos lotes, mas o faz em nome de terceiros, que são responsáveis pela licitude e desembaraço dos mesmos.</p>
                                    <p>5. Elaborou-se com esmero o catálogo, cujos lotes se acham descritos de modo objetivo.</p>
                                    <p>As obras serão vendidas "NO ESTADO" em que foram recebidas e expostas. Descrição de estado ou vícios decorrentes do uso será descrito dentro do possível, mas sem obrigação.</p>
                                    <p>Pelo que se solicita aos interessados ou seus peritos, prévio e detalhado exame até o dia do pregão.</p>
                                    <p>Depois da venda realizada não serão aceitas reclamações quanto ao estado das mesmas, nem servirá de alegação para descumprir o compromisso firmado.</p>
                                    <p>6. O leilão obedecerá rigorosamente à ordem dos lotes apresentada no catalogo. Todos os lotes poderão receber lances prévios antes da data de realização do pregão(*).</p>
                                    <p>Contudo, o lance vencedor será registrado somente durante o pregão ao vivo (data e horário divulgado no catálogo).</p>
                                    <p>É somente nesta data que o Leiloeiro(a) "baterá o martelo", formalizando cada lote como "Lote vendido".</p>
                                    <p>Os lances efetuados após a apresentação do lote no pregão, terão seu aceite ou não submetidos ao crivo do Leiloeiro(a) responsável.</p>
                                    <p>7. Ofertas por escrito podem ser feitas antes dos leilões, ou autorizar a lançar em seu nome; o que poderá ser feito por funcionário autorizado pelo Leiloeiro(a).</p>
                                    <p>8. O Leiloeiro(a) colocará, a titulo de CORTESIA, de forma gratuita e confidencial, serviço de arrematação pelo telefone e Internet, sem que isto o obrigue legalmente perante falhas de terceiros.</p>
                                    <p>8.1. LANCES PELA INTERNET: Para a participação nos leilões online faz-se necessário possuir um cadastro válido e ativo.</p>
                                    <p>Caso não possua cadastro, este poderá ser efetuado diretamente através do site do respectivo leilão, sendo certo que este deverá ser atualizado sempre que necessário.</p>
                                    <p>8.1.1 O acesso ao sistema de leilões online pelo usuário poderá ser cancelado ou suspenso a qualquer tempo e sob o exclusivo critério do Leiloeiro(a), não havendo direito a qualquer reclamação ou indenização.</p>
                                    <p>8.2. O arrematante poderá efetuar lances automáticos, de tal maneira que, se outro arrematante cobrir sua oferta, o sistema automaticamente gerará um novo lance para aquele arrematante,</p>
                                    <p>acrescido do incremento mínimo, até o limite máximo estabelecido pelo arrematante. Os lances automáticos ficarão registrados no sistema com a data em que forem efetuados.</p>
                                    <p>Os lances ofertados são IRREVOGÁVEIS e IRRETRATÁVEIS. O arrematante é responsável por todos os lances feitos em seu nome, os quais somente poderão ser anulados e/ou cancelados de acordo com autorização do leiloeiro(a) responsável.</p>
                                    <p>8.3. Em caso de empate entre arrematantes que efetivaram lances no mesmo lote e de mesmo valor, prevalecerá vencedor aquele que lançou primeiro (data e hora do registro do lance no site),devendo ser considerado inclusive que o lance automático fica registrado na data em que foi feito. Para desempate, o lance automático prevalecerá sobre o lance manual.</p>
                                    <p>9. O Leiloeiro(a) se reserva o direito de não aceitar lances de licitante com obrigações pendentes.</p>
                                    <p>10. Adquiridas as obras e assinado pelo arrematante o compromisso de compra, NÃO MAIS SERÃO ADMITIDAS DESISTÊNCIAS sob qualquer alegação.</p>
                                    <p>11. O arremate será sempre em moeda nacional. A progressão dos lances, nunca inferior a 5% do anterior, e sempre em múltiplo de dez. Outro procedimento será sempre por licença do Leiloeiro(a); o que não cria novação.</p>
                                    <p>12. As obras adquiridas deverão ser pagas e retiradas IMPRETERIVELMENTE em até 72 horas após o término do leilão, e serão acrescidas da comissão do Leiloeiro(a), (5%).</p>
                                    <p>Não sendo obedecido o prazo previsto, o Leiloeiro poderá dar por desfeita a venda e efetuar o bloqueio da respectiva cartela até respectiva quitação de taxas e multas equivalentes.</p>
                                    <p>13. As despesas com as remessas dos lotes adquiridos, caso estes não possam ser retirados, serão de inteira responsabilidade dos arrematantes.</p>
                                    <p>O cálculo de frete, serviços de embalagem e despacho das mercadorias deverão ser considerados como Cortesia e serão efetuados pelas Galerias e/ou Organizadores mediante prévia indicação pelo arrematante da empresaresponsável pelo transporte e respectivo pagamento dos custos de envio, ficando o Leiloeiro(a) e as Galerias e/ou Organizadores isentos de qualquer responsabilidade em caso de extravio, furto e/ou dano à mercadoria.</p>
                                    <p>14. O Leiloeiro(a) reserva-se ao direito de cancelar o lance, caso o arrematante adote posturas consideradas ofensivas, desrespeitosas ou inapropriadas, seja antes ou durante a realização de leilão.</p>
                                    <p>Poderá haver cancelamento de qualquer oferta de compra, sempre que não for possível comprovar a identidade do usuário ou caso este venha a descumprir quaisquer condições estabelecidas no presente contrato,dentre elas, a utilização de cadastros paralelos objetivando se eximir das responsabilidades previstas neste Termo.</p>
                                    <p>15. - O arrematante assume neste ato, expressamente, que responderá, civil e criminalmente, pelo uso de qualquer equipamento, programa ou procedimento que vise interferir no funcionamento do site.</p>
                                    </span>
                                    </div>
                                </div>
                                <div class="twelve columns">
                                    <div class="section-title">
                                    <h2><span>Condições de pagamento</span></h2>
                                    </div>
                                    <span><p>À vista, com acréscimo da taxa do leiloeiro de 5%,<br/>conforme instrução a ser enviada por e-mail após o término do leilão.<br/>Não aceitamos cartões de crédito</p></span>
                                </div>
                                <div class="twelve columns">
                                    <div class="section-title">
                                    <h2><span>Frete</span></h2>
                                    </div>
                                    <span><p>Enviamos através dos Correios para todo o Brasil.</p>
                                    <p>As despesas com retirada e remessa dos lotes, são de responsabilidade dos arrematantes.</p>
                                    <p>Em caso de envio por transportadoras, esta deverá ser providenciada pelo Arrematante.</p></span>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <AdvertisingWhite/>
        </>
    )
}


export default Catalog;
import { store } from '../redux/store';
import axios from 'axios';

const API = () => {
  return axios.create({
    baseURL: 'https://bigu.digital/zeleiloes-plataforma-backend/public/api/v1/',
    headers: {
      'Authorization': `Bearer ${store.getState().user.token}`
    }
  });
};

export default API;
import React, { useEffect, useState } from 'react';
import { FaEye } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { Product } from '../../api';
import { ProfileLeft } from '../../components';

const MyWatches = () => {
  const user = useSelector((state) => state.user.data);
  const [list, setList] = useState([])

  const loadData = async () => {
    let response = await Product.getWatchlist()
    if (response.status !== 'error') {
      setList(response)
    }
  }

  useEffect(() => {
    loadData()
  }, [])

  return (
    <div className="row pl-3 pr-3 mt-5">
      <ProfileLeft />

      <div className="col-9">
        <div className="row align-items-center">
          <FaEye className="ml-3 mt-1" size={35} />
          <h3 className="col m-0">Minhas Vigias</h3>
        </div>

        {
          list.map((item, index) => (
            <a href={`/peca?id=${item.products.id}`} key={index} className="btn text-left list-group-item mt-2">
              <div className="row align-items-center">
                <div className="col-6">
                  <img src={item.products.product_media[0] ?
                  `https://bigu.digital/zeleiloes-plataforma-backend/public/storage/products/${item.products.product_media[0].url}` :
                  "https://socialistmodernism.com/wp-content/uploads/2017/07/placeholder-image.png"}
                  style={{ width: '100%' }}
                  />
                </div>
                <div className="col-6">
                  <h5>{item.products.name}</h5>
                  {/* <p>
                    {item.products.description}
                  </p> */}
                </div>
                {/* <div className="col-1 d-flex align-items-center">
                  <button onClick={() => handleDelete(item)} className="btn btn-danger" >
                    <FaTrash />
                  </button>
                </div> */}
              </div>
            </a>
          ))
        }

        {
          list.length === 0 && (
            <div className="col-12 mt-3 mb-5 p-0">
              Não existem peças na lista de vigia.
            </div>
          )
        }

      </div>
    </div>
  )
}

export default MyWatches;
import React from 'react';

import {
  FaEnvelope,
  FaGavel,
  FaShoppingCart,
  FaThLarge
} from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { ProfileLeft } from '../../components';

const MyResume = () => {
  const user = useSelector((state) => state.user.data);

  // const uploadFile = async () => {
  //   let formdata = new FormData();
  //   formdata.append("file", files[0], "image.jpg");
  //   let response = await Store.uploadMedia(formdata)

  //   if (response.status === 'error') {
  //     alert('Erro')
  //   } else {
  //     alert('Arquivos enviados com sucesso!')
  //     setTimeout(() => {
  //       window.location.assign("home")
  //     }, 2000);
  //   }
  // }

  return (
    <div className="row pl-3 pr-3 mt-5">
      <ProfileLeft />
      
      <div className="col-9">
        <div className="row align-items-center">
          <FaThLarge className="ml-3 mt-1" size={35} />
          <h3 className="col m-0">Resumo</h3>
        </div>

        <hr />

        <div className="row align-items-center">
          <div className="btn btn-info ml-3 mt-1 pt-1">
            <FaEnvelope />
          </div>
          <h5 className="col-8 m-0">Últimas Mensagens</h5>
          <a href="#minhas-mensagens" className="col m-0 btn btn-link text-right">Ver todas as mensagens</a>
        </div>

        <div className="col-12 text-center mt-2 mb-5">
          <h6>Não há mensagens</h6>
        </div>

        <hr />

        <div className="row align-items-center">
          <div className="btn btn-info ml-3 mt-1 pt-1">
            <FaGavel />
          </div>
          <h5 className="col-8 m-0">Últimos Lances</h5>
          <a href="#meus-lances" className="col m-0 btn btn-link text-right">Ver todas os lances</a>
        </div>

        <div className="col-12 text-center mt-2 mb-5">
          <h6>Não há lances</h6>
        </div>

        <hr />

        <div className="row align-items-center">
          <div className="btn btn-info ml-3 mt-1 pt-1">
            <FaShoppingCart />
          </div>
          <h5 className="col-8 m-0">Últimas Compras</h5>
          <a href="#minhas-compras" className="col m-0 btn btn-link text-right">Ver todas as compras</a>
        </div>

        <div className="col-12 text-center mt-2 mb-5">
          <h6>Não há compras</h6>
        </div>

      </div>
    </div>
  )
}

export default MyResume;
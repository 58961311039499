import React from 'react';
import { FutureAuctionsFooter, Header, HeaderWithSearch } from '../../components';
import {
    Container, Row, Col, Button
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import InputMask from 'react-input-mask';

  
import './styles.css';


const Contact = () => {
    return (
        <div>
            <Header />
            <HeaderWithSearch />
            <Container className="mt-5 full-height-container d-flex flex-column">
                <Row>
                    <Col sm={7}>
                        <div>
                            <h3><FontAwesomeIcon icon={faEnvelope} className="mr-2" />Envie uma mensagem</h3>
                        </div>

                        <div>
                            <form className="contato-form">
                               <ul className="contato-cadastro">
                               <li class="row input-label-container py-1">
                                    <div class="col-xs-4 label-container">
                                        <label for="nome">Nome Completo:</label>
                                    </div>
                                    <div class="col-xs-8 input-container input-box">
                                        <div class="form-group has-feedback">
                                            <input required class="form-control" name="Nome" type="text" id="Nome" placeholder="Digite seu nome completo"/>
                                        </div>
                                    </div>
                                </li>

                                <li class="row input-label-container py-1">
                                    <div class="col-xs-4 label-container">
                                        <label for="nome">Email:</label>
                                    </div>
                                    <div class="col-xs-8 input-container input-box">
                                        <div class="form-group has-feedback">
                                            <input required type="email" className="form-control" placeholder="Digite seu email" />
                                        </div>
                                    </div>
                                </li>
                                <li class="row input-label-container py-1">
                                    <div class="col-xs-4 label-container">
                                    <label for="nome">Telefone de Contato:</label>
                                    </div>
                                    <div class="col-xs-8 input-container input-box">
                                        <div class="form-group has-feedback">
                                            <InputMask
                                            required
                                            placeholder="Telefone"
                                            mask="(99) 99999-9999"
                                            name="phone"
                                            className="contact form-control"
                                            />
                                        </div>
                                    </div>
                                </li>
                                <li class="row input-label-container py-1">
                                    <div class="col-xs-4 label-container">
                                        <label for="nome">Comentários:</label>
                                    </div>
                                    <div class="col-xs-8 input-container input-box">
                                        <div class="form-group has-feedback">
                                            <textarea required tabindex="6" name="coment" id="coment"></textarea>
                                        </div>
                                    </div>
                                </li>
                                <li class="row input-label-container">
                                    <div class="col-xs-4 label-container"></div>
                                    <div class="col-xs-8" style={{paddingLeft: '0px'}}>
                                        <Button className="color-blue" type="submit">Enviar</Button>
                                    </div>
                                </li>
                                </ul> 
                            </form>
                        </div>

                    </Col>

                    <Col sm={5} className="contato-right">
                        <div>
                            <h3><FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2"/>Local</h3>
                        </div>
                        <div class="address">
                            <h4>Endereço:</h4>
                            <p>Rua Cleto Marques Luz, Levada - AL - CEP 57017-168</p>
                            <h4>Telefones:</h4>
                            <p>(82) 67089-0275<br/>(82) 37090-8715</p>         
                            <h4>E-mail:</h4>
                            <a href="mailto:contato@zeleiloes.com.br">contato@zeleiloes.com.br</a>
                        </div>
                    </Col>
                </Row>
            </Container>
            <FutureAuctionsFooter/>
        </div>
    )
}

export default Contact;

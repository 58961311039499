import React, { useState, useEffect } from 'react';
import {
  Modal, Table
} from 'react-bootstrap';

/*import './style.css';*/
import {
  FaQuestionCircle, FaGavel, FaTrash,
  FaHeart, FaEyeSlash, FaEye, FaExclamationTriangle
} from 'react-icons/fa';
import Magnifier from "react-magnifier";

import './index.css';
import { Header } from '../../components';
import { Auction, Product, Store } from '../../api';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { useAlert, positions } from 'react-alert';

const ProductDetails = () => {
  const alert = useAlert()
  const user = useSelector((state) => state.user.data);
  const [store, setStore] = useState({})

  const [userDetails, setUserDetails] = useState(null);
  const [tab, setTab] = useState(0);
  const [product, setProduct] = useState({});
  const [currentImage, setCurrentImage] = useState(null);
  const [bids, setBids] = useState([]);
  const [minimumBid, setMinimumBid] = useState(null);
  const [idstore, setIdStore] = useState({});

  /* Favorite UseStates */
  const [getidFav, setGetIdFav] = useState([])
  const [idFavorite, setIdFavorite] = useState([])
  const [addFavorite, setAddFavorite] = useState(false)

  /* WatchList UseStates */
  const [getidWatch, setIdWatchlist] = useState([])
  const [idWatchList, setGetWatchList] = useState([])
  const [addWatchlist, setAddWatchList] = useState(false)
  const [auctionId, setAuctionID] = useState([])

  /* Count Visites */

  const [auctions, setAuctions] = useState([])
  const [count, setCount] = useState([])

  /* Spinner loading */
  const [load, setLoad] = useState(true)
  
  /* Spinner Erro */
  const [erro, setErro] = useState(false)

  const loadData = async (id) => {
    let response = await Product.getById(id);
    if (response.status !== 'error') {
      if (response.product_media?.length > 0) {
        setCurrentImage(`https://bigu.digital/zeleiloes-plataforma-backend/public/storage/products/${response.product_media[0].url}`)
      }
      setProduct(response)
    }
  }

  const loadStore = async (storeID) => {
    let response = await Store.getById(storeID);
    if (response.status !== 'error') {
      setIdStore(response)
    }
  }

  const loadStoreData = async () => {
    let response = await Store.getData();
    if (response.status !== 'error') {
      setStore(response[0])
      if (product.auction && product.store_id === response[0].id) {
        loadBidsFromAuction(product.id)
      } else {
        loadMinimumBid(product.id)
      }
    }
  }

  const loadAuctions = async () => {
    let response = await Auction.getAll();
    if (response.status !== 'error') {
      response.map(function(item) {
        if(item.product_id === product.id) {
          setAuctionID(item)
          setVisits(item.id)
        }
      })
      setAuctions(response);
    }
  }

  /* Count visits try catch */
  const setVisits = async (id) => {
    console.log(id)
    try {
      let response = await fetch('https://bigu.digital/zeleiloes-plataforma-backend/public/api/auctions/visits',
      {
        method: 'PUT',
        headers: {
          'Access-Control-Allow-Origin':'*',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({id})
      }) 
      if (response.status !== 'error') {
        response = await response.json()
        setCount(response)
        setLoad(false)
      }
    } catch (e) {
      setErro(true)
    }
  }



  const loadMinimumBid = async (id) => {
    let response = await Auction.getBidsFromAuction(id);
    if (response.status !== 'error') {
      setMinimumBid(response[response.length - 1]?.bid)
    }
  }

  const loadBidsFromAuction = async (id) => {
    let response = await Auction.getBidsFromAuction(id);
    if (response.status !== 'error') {
      setBids(response)
    }
  }

  const getFavorite = async () => {
    let response = await Product.getFavorites();
    if(response.status !== 'error') {
      response.map(function(item) {
        if(item.product_id === product.id) {
          setAddFavorite(true)
          setIdFavorite(item)
        }
      })
      setGetIdFav(response)
    }
  }

  const setFavorite = async () => {
    let response = await Product.addToFavorites({
      product_id: product.id,
      store_id: product.store_id
    })
    if (response.status !== 'error') {
      setAddFavorite(true)
      setGetIdFav(response)
      alert.success('Item adicionado aos favoritos!')
    } else {
      alert.error('Entre para poder favoritar o leilão')
      window.location.assign("/autenticacao#login")
    }
  }

  const deleteFavorite = async (id) => {
    let response = await Product.deleteFavorites(id);
    if (response.status !== 'error') {
      setAddFavorite(false)
      alert.success("Item removido dos favoritos")
    }
  }

  const getWatchslist = async () => {
    let response = await Product.getWatchlist();
    if(response.status !== 'error') {
      response.map(function(item) {
        if(item.product_id === product.id) {
          setAddWatchList(true)
          setGetWatchList(item)
        }
      })
      setIdWatchlist(response)
    }
  }

  const setWatchslist = async () => {
    let response = await Product.addToWatchlist({
      product_id: product.id,
      store_id: product.store_id,
      auction_id: auctionId.id
    })
    if (response.status !== 'error') {
      setAddWatchList(true)
      setIdWatchlist(response)
      alert.success('Item adicionado aos vigias!')
    } else {
      alert.error('Entre para poder vigiar o leilão')
      window.location.assign("/autenticacao#login")
    }
  }

  const deleteWatchlist = async (id) => {
    let response = await Product.deleteWatchlist(id);
    if (response.status !== 'error') {
      setAddWatchList(false)
      alert.success("Item removido dos favoritos")
    }
  }

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const prodId = urlParams.get('id');
    loadData(prodId)  
  }, [])

  useEffect(() => {
    const id = product.store_id
    if (product.id) {
      loadStoreData()
      loadStore(id)
      getFavorite()
      getWatchslist()
      loadAuctions()
    }
  }, [product.id])

  const requestNewBid = async (event) => {
    event.preventDefault();

    if (!parseFloat(event.target[0].value)) {
      alert.error('Valor inválido', { position: positions.TOP_RIGHT })
      return false;
    }

    if (parseFloat(event.target[0].value) < minimumBid || parseFloat(event.target[0].value) < product.auction.initial_price) {
      alert.error('Valor abaixo do lance mínimo', { position: positions.TOP_RIGHT })
      return false;
    }

    let obj = {
      product_id: product.id,
      auction_id: product.auction.id,
      bid: parseFloat(event.target[0].value)
    }

    let response = await Auction.createBid(obj);
    if (response.status !== 'error') {
      alert.success('Success');
      window.location.assign("/perfil#meus-lances")
    } else {
      alert.error('Usuário não logado, redirecionando para efetuar login', { position: positions.TOP_RIGHT })
      setTimeout(function(){
        window.location.assign("/autenticacao#login")
      }, 4000)
    }
  }

  const directSell = async (e) => {
    e.preventDefault();

    window.location.assign(`/compra?id=${product.id}`)
  }

  return (
    <div className="wrapper">
      <Header />

      <Modal show={userDetails ? true : false} onHide={() => setUserDetails(null)}>
        {
          userDetails && (
            <>
              <Modal.Header closeButton>
                <Modal.Title>{userDetails.first_name}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>
                  Nome: {userDetails.first_name}
                </div>
                <div>
                  Sobrenome: {userDetails.last_name}
                </div>
                <div>
                  Email: {userDetails.email}
                </div>
                <div>
                  CPF: {userDetails.cpf}
                </div>
                <div>
                  RG: {userDetails.rg}
                </div>
                <div>
                  Telefone: {userDetails.phone}
                </div>
              </Modal.Body>
              <Modal.Footer>
                <div className="btn btn-secondary" onClick={() => setUserDetails(null)}>
                  Fechar
                </div>
              </Modal.Footer>
            </>
          )
        }
      </Modal>

      <div className="container">
        <div className="row mt-5">
          <div className="col">
            {
              product.product_media ? product.product_media.map((item, index) => (
                <button onClick={() => setCurrentImage(`https://bigu.digital/zeleiloes-plataforma-backend/public/storage/products/${item.url}`)} className="btn border rounded p-1 mr-2 mb-2">
                  <img
                    className="product-image-btn"
                    src={`https://bigu.digital/zeleiloes-plataforma-backend/public/storage/products/${item.url}`} />
                </button>
              )) : null
            }
            <div className="media border product-media-card">
              <Magnifier
                className="product-media"
                src={currentImage || "https://www.ggotuzzo.com.br/wp-content/uploads/woocommerce-placeholder.png"}
                width={400}
                height={400}
              />
            </div>
          </div>
          <div className="col">
            <h3 className="mb-3">{product.name}</h3>

            <div className="row">
              <h5 className="col-2">Peça</h5>
              <hr className="col" />    

              {
                !addFavorite === true ? (
                  <button className="col-1 btn" onClick={setFavorite}> <FaHeart size={25} /></button>
                ) : (
                  <button className="col-1 btn" onClick={() => deleteFavorite(idFavorite.id)}> <FaHeart size={25} style={{color: 'red'}} /></button>
                )
              }

              {
                !addWatchlist === true ? (
                  <button className="col-1 btn" onClick={setWatchslist}><FaEyeSlash size={25} /></button>
                ) : (
                  <button className="col-1 btn" onClick={() => deleteWatchlist(idWatchList.id)} style={{color: '#1d4d74'}}><FaEye size={25} /></button>
                )
              }

              
            </div>
            {
              count.visits > 0 ? (
                <p><strong>Visitas: </strong>{count.visits}</p>
              ) : (
                <p><strong>Visitas: </strong>0</p>
              )
            }
            <p><strong>Tipo: </strong>{product.product_categories ? product.product_categories.name : ''}</p>
            <p className="mb-5">
              {product.description}
            </p>

            {
              product.auction && (
                <>
                  <div className="row">
                    <h5 className="col-3">Informações</h5>
                    <hr className="col" />
                  </div>
                  {/* <p>Local: Maceió</p> */}
                  <p>Dia do leilão: {dayjs(product.auction.auction_date).format('DD/MM/YYYY HH:mm')}</p>
                  <p className="mb-5">Final do leilão: {dayjs(product.auction.final_date).format('DD/MM/YYYY HH:mm')}</p>
                </>
              )
            }


            {
              product.auction && product?.store_id !== store?.id &&
              dayjs(product.auction.auction_date).isBefore(dayjs()) &&
              dayjs(product.auction.final_date).isAfter(dayjs()) && (
                <form onSubmit={requestNewBid}>
                  <div className="row mt-3">
                    <h5 className="col-2">Lance</h5>
                    <hr className="col" />
                  </div>
                  {/* <p>Histórico de lances: {0}</p> */}
                  <p>Valor Inicial: R$<span className="bid-value-strong">{parseFloat(product.auction.initial_price).toFixed(2).replace('.', ',')}</span></p>
                  {
                    minimumBid && (
                      <p>Lance mínimo: R$<span className="bid-value-strong">{parseFloat(minimumBid).toFixed(2).replace('.', ',')}</span></p>
                    )
                  }

                  <div className="bg-light p-2">
                    <div className="row justify-content-between px-3 mb-2">
                      <span>Lance:</span>
                      <span>Tem que lançar R$ {parseFloat(minimumBid || product.auction.min_bid).toFixed(2).replace('.', ',')} ou mais</span>
                    </div>
                    <div className="d-flex bid-bg-dark px-2 m-auto">
                      <div className="text-center col-2 p-0">R$</div>
                      <input required className="col-8 px-1" placeholder="0.000" />
                      <div className="text-left col-1 p-0">,00</div>
                    </div>
                    <div className="bid-bg-dark pl-2 m-auto row justify-content-between align-items-center">
                      <input disabled={!user ? true : false} classNam="col-1" type="checkbox" name="automatic-bid" id="automatic-bid" />
                      <label className="pt-1 font-10 col-9" for="automatic-bid">Lance Automático</label>
                      <FaQuestionCircle className="col bid-question-mark" />
                    </div>
                  </div>
                  <div className="row mx-0">
                    <button disabled={!user ? true : false} className="col p-3 btn btn-warning border-0"><FaGavel /> Dar Lance</button>
                    <button className="col p-3 btn bid-btn" disabled><FaTrash /> Remover Automático</button>
                  </div>
                </form>
              )
            }

            {
              product.auction && product?.store_id !== store?.id &&
              dayjs(product.auction.final_date).isBefore(dayjs()) && !minimumBid && (
                <div>

                  <div className="row mt-3">
                    <h5 className="col-4">Leilão encerrado</h5>
                    <hr className="col" />
                  </div>

                  <form onSubmit={directSell}>
                    <div className="bg-light p-2">
                      <div className="row justify-content-between px-3 mb-2">
                        <span>Compra direta:</span>
                        <span style={{ fontSize: 20, fontWeight: 'bold' }}>R$ {parseFloat(product.auction.min_bid).toFixed(2).replace('.', ',')}</span>
                      </div>
                      <button className="col p-3 btn btn-warning border-0">
                        Comprar
                      </button>
                    </div>
                  </form>

                </div>
              )}

            {
              product.auction && product?.store_id === store?.id && (
                <div>
                  Histórico de lances

                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Nome</th>
                        <th>Valor</th>
                        <th>Data e hora</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        bids.map((item, index) => (
                          <tr onClick={() => setUserDetails(item.user)} style={{ cursor: 'pointer' }} key={index}>
                            <td>{item.user.first_name} {item.user.last_name}</td>
                            <td>R$ {(parseFloat(item.bid).toFixed(2)).replace('.', ',')}</td>
                            <td>{dayjs(item.bid_date).format('DD/MM/YYYY H:m')}</td>
                          </tr>
                        ))
                      }
                    </tbody>
                  </Table>
                </div>
              )
            }

          </div>
        </div>

        <ul className="nav nav-tabs mt-5">
          <li className="nav-item">
            <a className={`nav-link  ${tab === 0 ? 'active' : ''}`} href="#" onClick={() => setTab(0)}>Termos e Condições</a>
          </li>
          <li className="nav-item">
            <a className={`nav-link  ${tab === 1 ? 'active' : ''}`} href="#" onClick={() => setTab(1)}>Condições de Pagamento</a>
          </li>
          <li className="nav-item">
            <a className={`nav-link  ${tab === 2 ? 'active' : ''}`} href="#" onClick={() => setTab(2)}>Frete e Envio</a>
          </li>
        </ul>

        {
          tab === 0 ? (
            <div className="minheigth">
              {idstore.terms_n_conditions}
            </div>
          ) : null
        }
        {
          tab === 1 ? (
            <div className="minheigth">
              {idstore.conditions_of_payment}
            </div>
          ) : null
        }
        {
          tab === 2 ? (
            <div className="minheigth">
              {idstore.shipping_termos}
            </div>
          ) : null
        }
      </div>

        {
          load && (
            <div id="spinner"></div>
          )
        }

        {
          erro && (
            <div id="spinnerErro">
              <FaExclamationTriangle size={200}/>
            </div>
          )
        }
    </div>
  )
}

export default ProductDetails;
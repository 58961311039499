
import React, { useEffect, useState } from 'react';
import {
  Container, Row, Col, Table
} from 'react-bootstrap';

import { Link } from 'react-router-dom';
import { faRss, faCog } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './style.css';
import { Header } from '../../../components';
import themeContext from '../../../utils/theme';
import { Product, Store, User } from '../../../api';
import { FaPen, FaSearch, FaTrash } from 'react-icons/fa';
import { Modal } from 'react-bootstrap';
import { useAlert } from "react-alert";

const AdminProducts = () => {
  const alert = useAlert();

  const [categories, setCategories] = useState([]);
  const [types, setTypes] = useState([]);
  const [auctioneers, setAuctioneers] = useState([]);
  const [products, setProducts] = useState([]);
  const [productToUpdate, setProductToUpdate] = useState(null);
  const [load, setLoad] = useState(true)

  const loadAuctioneers = async () => {
    let response = await Store.getAll();
    if (response.status !== 'error') {
      setAuctioneers(response);
    }
  }

  const loadCategories = async () => {
    let response = await Product.getCategories();
    if (response.status !== 'error') {
      setCategories(response);
    }
  }

  const loadTypes = async () => {
    let response = await Product.getTypes();
    if (response.status !== 'error') {
      setTypes(response);
    }
  }

  const loadData = async () => {
    let response = await Product.getAll();
    if (response.status !== 'error') {
      setProducts(response);
      setLoad(false)
    }
  }

  const handleEdit = (item) => {
    window.history.pushState('Editar Produtos', 'Editar Produtos', "/admin/produtos/" + item.id);
    setProductToUpdate(item)
  }

  const handleEditCancel = () => {
    window.history.pushState('Editar Produtos', 'Editar Produtos', "/admin/produtos/");
    setProductToUpdate(null)
  }

  const handleDelete = async (id) => {
    let response = await Product.delete(id)
    if (response.status !== 'error') {
      alert.success('Produto excluído com sucesso!')
      loadData()
    } else {
      alert.error('Não foi possível excluir o produto!')
    }
  }

  useEffect(() => {
    loadCategories();
    loadTypes();
    loadAuctioneers();
    loadData();
  }, [])


  const handleSubmit = async (e) => {
    e.preventDefault()
    var idProduct = window.location.pathname.split("/")[3];
    let obj = {
      id: idProduct,
      name: e.target[0].value,
      description: e.target[1].value,
      store_id: e.target[2].value,
      category_id: e.target[3].value,
      type_id: e.target[4].value,
      free_shipping: e.target[5].checked || 0,
    }
    let response = await Product.update(obj);
    if(response.status !== 'error') {
      alert.success('Sucesso! O usuário foi atualizado')
      setProductToUpdate(null)
      loadData()
      window.history.pushState('Editar Produtos', 'Editar Produtos', "/admin/produtos/");
    } else {
      alert.error('Erro! Não foi possível atualizar os dados do usuário')
    }
  }

  const getAuctioneerName = (id) => {
    let auctioneer = auctioneers.find((a) => a.id === id)
    return auctioneer?.company_name || ""
  }

  return (
    <div>
      <Header />

      <Modal show={productToUpdate ? true : false} onHide={() => setProductToUpdate(null)}>
        {
          productToUpdate && (
            <>
              <Modal.Header closeButton>
                <Modal.Title>Editar {productToUpdate.name}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <form onSubmit={handleSubmit} id="edit-form">
                  <input
                    className="form-control mb-2"
                    placeholder="Nome"
                    value={productToUpdate.name}
                    onChange={(e) => setProductToUpdate(Object.assign({}, { ...productToUpdate, name: e.target.value }))}
                    required
                  />
                  <input
                    className="form-control mb-2"
                    placeholder="Descrição"
                    value={productToUpdate.description}
                    onChange={(e) => setProductToUpdate(Object.assign({}, { ...productToUpdate, description: e.target.value }))}
                    required
                  />
                  <select
                    className="form-control mb-2"
                    onChange={(e) => setProductToUpdate(Object.assign({}, { ...productToUpdate, store_id: e.target.value }))}
                    required
                  >
                    {
                      auctioneers.map((item, index) => (
                        <option key={index} value={item.id} selected={productToUpdate.store_id === item.id}>{item.company_name}</option>
                      ))
                    }
                  </select>
                  <select
                    className="form-control mb-2"
                    onChange={(e) => setProductToUpdate(Object.assign({}, { ...productToUpdate, category_id: e.target.value }))}
                    required
                  >
                    {
                      categories.map((item, index) => (
                        <option key={index} value={item.id} selected={productToUpdate.category_id === item.id}>{item.name}</option>
                      ))
                    }
                  </select>
                  <select
                    className="form-control mb-2"
                    onChange={(e) => setProductToUpdate(Object.assign({}, { ...productToUpdate, type_id: e.target.value }))}
                    required
                  >
                    {
                      types.map((item, index) => (
                        <option key={index} value={item.id} selected={productToUpdate.type_id === item.id}>{item.name}</option>
                      ))
                    }
                  </select>
                  <div className="custom-control custom-checkbox mb-3">
                    <input
                        type="checkbox" 
                        className="custom-control-input" 
                        id="freeShipping"
                        value={productToUpdate.free_shipping}
                        checked={productToUpdate.free_shipping == 1 ? true : false}
                        onChange={(e) => setProductToUpdate(Object.assign({}, { ...productToUpdate, free_shipping: e.target.checked }))}
                    />
                    <label className="custom-control-label" for="freeShipping">Entrega grátis</label>
                  </div>
                </form>
              </Modal.Body>
              <Modal.Footer>
                <div className="btn btn-secondary" onClick={() => handleEditCancel(null)}>
                  Cancelar
                </div>
                <div className="btn btn-primary" onClick={() => document.getElementById('edit-form').requestSubmit()}>
                  Salvar
                </div>

              </Modal.Footer>
            </>
          )
        }
      </Modal>

      <Container fluid="md" className="mt-5 full-height-container">
        <h4>Produtos</h4>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Nome</th>
              <th>Descrição</th>
              <th>Leiloeiro</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {
              products.map((item, index) => (
                <tr key={index}>
                  <td>{item.id}</td>
                  <td>{item.name}</td>
                  <td>{item.description}</td>
                  <td>{getAuctioneerName(item.store_id)}</td>
                  <td>
                    <div className="flex">
                      <button className="btn btn-primary mx-1" onClick={() => window.location = `/peca?id=${item.id}`}>
                        <FaSearch />
                      </button>
                      <button className="btn btn-warning mx-1" onClick={() => handleEdit(item)}>
                        <FaPen />
                      </button>
                      <button className="btn btn-danger mx-1" onClick={() => handleDelete(item.id)}>
                        <FaTrash />
                      </button>
                    </div>
                  </td>
                </tr>
              ))
            }
          </tbody>
        </Table>
        {
          products.length === 0 && !load ? (
            <div style={{ textAlign: 'center' }}>
              Não há produtos cadastrados
            </div>
          ) : null
        }

        {
          load && (
            <div id="spinner"></div>
          )
        }
      </Container>
    </div>
  )
}

export default AdminProducts;
import React, {useEffect} from 'react';
import './style.css';

import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Button} from 'react-bootstrap';

const Top = () => {

    function handleScrollToTop () {
        window.scrollTo(0, 0)
        document.getElementById("top").style.display ="none";
    }
    return (
    <div class="smoothscroll-top" id="top">
        <Button className="btn coloracao" onClick={handleScrollToTop}>
            <FontAwesomeIcon icon={faArrowUp} size="2x" />
        </Button>
    </div>
    )
}

export default Top;

import React from 'react';
import {
  Container, Row, Col, Media, Dropdown, InputGroup, FormControl,
  Navbar, Carousel, Button, Card, Badge, Form, Input
} from 'react-bootstrap';
import InputMask from 'react-input-mask';
import { useAlert, positions } from "react-alert";

import { Switch, Route, Link } from 'react-router-dom';
import { faSearch, faRss, faCog } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Header, Footer } from '../../../components/';
import themeContext from '../../../../src/utils/theme';
import { Store } from '../../../api';

import '../style.css';

const CreateAccount2 = () => {
  const alert = useAlert();

  const register = async (event) => {
    event.preventDefault();

    let obj = {
      fantasy_name: event.target[0].value,
      company_name: event.target[1].value,
      cnpj: event.target[2].value,
      state: event.target[3].value,
      city: event.target[4].value,
      address: event.target[5].value,
      number: event.target[6].value,
      terms_n_conditions: event.target[7].value,
      conditions_of_payment: event.target[8].value,
      shipping_termos: event.target[9].value
    }

    let response = await Store.completeSignUp(obj);
    if (!response.status !== 'error') {
      alert.success("Cadastro confirmado!");
      setTimeout(() => {
        window.location.assign("home")
      }, 2000);
    } else {
      alert.error("Erro! Tente novamente.", { position: positions.TOP_RIGHT });
    }
  }

  return (
    <>
      <Header />

      {/* Inicio Escopo */}
      <div className="content-wrap">
        <Col>
          <div className="logcad-wrap">
            <Col className="article-title">
              <h3 style={{ fontSize: '20px', fontWeight: '400' }}>
                Complete seu cadastro
              </h3>
            </Col>
            <form className="login-form" onSubmit={register}>
              <Col>
                <div className="form-group has-feedback">
                  <label>Nome Fantasia:</label>
                  <input
                    type="text"
                    required
                    className="contact form-control" />
                </div>
              </Col>

              <Col>
                <div className="form-group has-feedback">
                  <label>Nome da Empresa:</label>
                  <input
                    type="text"
                    required
                    className="contact form-control" />
                </div>
              </Col>

              <Col>
                <div className="form-group has-feedback">
                  <label>CNPJ:</label>
                  <InputMask
                    required
                    mask="99.999.999/9999-99"
                    className="contact form-control"
                  />
                </div>
              </Col>

              <Col>
              <div className="form-group has-feedback">
                <label for="Estado">Estado:</label>
                <select id="Estado" className="inputB form-control" name="UF" required="">
                  <option value=""></option>
                  <option value="AC">Acre</option>
                  <option value="AL" selected="">Alagoas</option>
                  <option value="AM">Amazonas</option>
                  <option value="AP">Amapá</option>
                  <option value="BA">Bahia</option>
                  <option value="CE">Ceará</option>
                  <option value="DF">Distrito Federal</option>
                  <option value="ES">Espírito Santo</option>
                  <option value="GO">Goiás</option>
                  <option value="MA">Maranhão</option>
                  <option value="MG">Minas Gerais</option>
                  <option value="MS">Mato Grosso do Sul</option>
                  <option value="MT">Mato Grosso</option>
                  <option value="PA">Pará</option>
                  <option value="PB">Paraíba</option>
                  <option value="PE">Pernambuco</option>
                  <option value="PI">Piauí</option>
                  <option value="PR">Paraná</option>
                  <option value="RJ">Rio de Janeiro</option>
                  <option value="RN">Rio Grande do Norte</option>
                  <option value="RO">Rondônia</option>
                  <option value="RR">Roraima</option>
                  <option value="RS">Rio Grande do Sul</option>
                  <option value="SC">Santa Catarina</option>
                  <option value="SP">São Paulo</option>
                  <option value="SE">Sergipe</option>
                  <option value="TO">Tocantins</option>
                </select>
              </div>
              </Col>

              <Col>
                <div className="form-group has-feedback">
                  <label>Cidade:</label>
                  <input
                    type="text"
                    required
                    className="contact form-control" />
                </div>
              </Col>

              

              <Col>
                <div className="form-group has-feedback">
                  <label>Endereço:</label>
                  <input
                    required
                    className="contact form-control" />
                </div>
              </Col>

              <Col>
                <div className="form-group has-feedback">
                  <label>Número:</label>
                  <input
                    required
                    type="number"
                    className="contact form-control" />
                </div>
              </Col>
              <Col>
                <div className="form-group has-feedback">
                  <label>Condições de Pagamento:</label>
                  <textarea class="form-control" id="payment" rows="5"></textarea>
                </div>
              </Col>

              <Col>
                <div className="form-group has-feedback">
                  <label>Termos e condições:</label>
                  <textarea class="form-control" id="terms" rows="5"></textarea>
                </div>
              </Col>

              <Col>
                <div className="form-group has-feedback">
                  <label>Condições de envio:</label>
                  <textarea class="form-control" id="frete" rows="5" minlength="14"></textarea>
                </div>
              </Col>

              <div className="col py-3">
                <Button
                  tyle={{
                    backgroundColor: themeContext.AZUL_DARK,
                    border: 'none',
                    width: '144px',
                    marginTop: '20px'
                  }}
                  size="md sm"
                  block
                  type="submit"
                >
                  Finalizar
                </Button>
              </div>
            </form>
          </div>
        </Col>
      </div>
    </>
  )

}


export default CreateAccount2;